import {Component, Input} from '@angular/core';
import {IComponentType, ITableConfig} from "../table.component";
import {NgIf, NgOptimizedImage, NgSwitch, NgSwitchCase} from "@angular/common";
import {translateType} from "../../../../interface/service.interface";
import {StatusComponent} from "../../status/status.component";
import {DatePipe} from "../../../../util/pipe/date.pipe";

@Component({
  selector: 'app-table-cell',
  standalone: true,
    imports: [
        NgIf,
        NgSwitch,
        NgSwitchCase,
        NgOptimizedImage,
        StatusComponent,
        DatePipe
    ],
  templateUrl: './table-cell.component.html',
  styleUrl: './table-cell.component.scss'
})
export class TableCellComponent {

    @Input() config: ITableConfig = {};
    @Input() rowData: any = {};

    getValueByField(): any {
        if (!this.rowData || !this.config) {
            return ''
        }
        if (this.config.translateKey) {
            if (!this.config.field) {
                throw new Error('filed definition missing!')
            }
            return this.config.translateKey(this.rowData[this.config.field!]);
        }
        if (this.config.field) {
            return this.rowData[this.config.field];
        }

        return '<unknown>';

    }

    onClick(event: Event): void {
        if (this.config.specialComponent?.onClick){
            event.preventDefault();
            event.stopPropagation();
            this.config.specialComponent.onClick(this.rowData);
        }
    }

    getType(): IComponentType {
        return this.config.specialComponent?.type || 'standard';
    }

    hideComponent(): boolean {
        if(this.config?.conditionallyHide){
            return this.config.conditionallyHide(this.rowData);
        }
        return false;
    }

    protected readonly translateType = translateType;
}
