/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ServiceGroupRightResponse } from './serviceGroupRightResponse';


export interface GetUserRightsResponse { 
    role?: GetUserRightsResponse.RoleEnum;
    rightsToServiceGroups?: Array<ServiceGroupRightResponse>;
}
export namespace GetUserRightsResponse {
    export type RoleEnum = 'AdminPartner' | 'AdminOE' | 'UserPartner';
    export const RoleEnum = {
        AdminPartner: 'AdminPartner' as RoleEnum,
        AdminOe: 'AdminOE' as RoleEnum,
        UserPartner: 'UserPartner' as RoleEnum
    };
}


