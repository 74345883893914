import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgIf} from "@angular/common";
import {Subscription} from 'rxjs';
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {LoaderService} from "../../../service/loader/loader.service";

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinner
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent implements OnInit, OnDestroy {

  isLoading: boolean = false;
  private subscription?: Subscription;

  constructor(private loaderService: LoaderService) {
  }

  ngOnInit() {
    this.subscription = this.loaderService.isLoading.subscribe(
      (loading: boolean) => {
        this.isLoading = loading;
      }
    );
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

}
