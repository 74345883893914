import {Routes} from '@angular/router';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {ServiceGroupComponent} from './component/service-group/service-group.component';
import {admin, adminAsUser, auth as auth, authOrAdmin, partnerAdmin} from "./auth-guard.service";
import {UserProfileComponent} from "./component/user-profile/user-profile.component";
import {NotAdminComponent} from "./component/general/not-admin/not-admin.component";
import {IsAdminComponent} from "./component/general/is-admin/is-admin.component";
import {HomeComponent} from "./component/home/home.component";
import {AdminServiceGroupDetailPageComponent} from "./component/page/admin-service-group-detail-page/admin-service-group-detail-page.component";
import {AdminUsersPageComponent} from "./component/page/admin-users-page/admin-users-page.component";
import {LoginPageComponent} from "./component/page/login-page/login-page.component";
import {AdminUserDetailPageComponent} from "./component/page/admin-user-detail-page/admin-user-detail-page.component";
import {AdminDevicePageComponent} from "./component/page/admin-device-page/admin-device-page.component";
import {AdminDeviceDetailPageComponent} from "./component/page/admin-device-detail-page/admin-device-detail-page.component";
import {ServiceDetailComponent} from "./component/service-detail/service-detail.component";
import {GlobalWgSettingsComponent} from "./component/page/global-wg-settings/global-wg-settings.component";
import {AdminServiceDetailPageComponent} from "./component/page/admin-service-detail-page/admin-service-detail-page.component";
import {CalculationComponent} from "./component/page/global-calculation/calculation.component";
import {GlobalCompaniesPageComponent} from "./component/page/global-companies/global-companies-page.component";
import {AdminCompanyDetailPageComponent} from "./component/page/admin-company-detail-page/admin-company-detail-page.component";
import {AdminServiceGroupsComponent} from "./component/page/admin-service-groups/admin-service-groups.component";
import {AdminConsumptionPointsComponent} from "./component/page/admin-consumption-points/admin-consumption-points.component";
import {AdminConsumptionPointDetailPageComponent} from "./component/page/admin-consumption-point-detail/admin-consumption-point-detail-page.component";
import {ServiceValveHeatingBranchDetailSettingsComponent} from "./component/services/service-valve-heating-branch/settings/service-valve-heating-branch-detail-settings.component";
import {AdminConsumptionPeriodDetailPageComponent} from "./component/page/admin-consumption-period-detail/admin-consumption-period-detail-page.component";

const appRoutes: Routes = [
    {path: 'login', component: LoginPageComponent},
    {path: 'home', component: HomeComponent},
    {path: 'not-admin', component: NotAdminComponent},
    {path: 'is-admin', component: IsAdminComponent},

    // shared for admin and user
    {path: '', component: HomeComponent, canActivate: [authOrAdmin]},
    {path: 'my-profile', component: UserProfileComponent, canActivate: [authOrAdmin]},

    // __________________ CLIENT __________________

    // dashboard
    {path: 'dashboard', component: DashboardComponent, canActivate: [auth]},
    {path: 'company/:companyId/dashboard', component: DashboardComponent, canActivate: [adminAsUser]},

    // service groups
    {path: 'service-group/:serviceGroupId', component: ServiceGroupComponent, canActivate: [auth]},
    {path: 'service-group/:serviceGroupId/service/:serviceId', component: ServiceDetailComponent, canActivate: [auth]},

    {path: 'company/:companyId/service-group/:serviceGroupId', component: ServiceGroupComponent, canActivate: [adminAsUser]},
    {path: 'company/:companyId/service-group/:serviceGroupId/service/:serviceId', component: ServiceDetailComponent, canActivate: [adminAsUser]},

    // SETTINGS
    // valve heating branch
    {path: 'service-group/:serviceGroupId/service-vhb/:serviceId/settings', component: ServiceValveHeatingBranchDetailSettingsComponent, canActivate: [auth]},
    {path: 'company/:companyId/service-group/:serviceGroupId/service-vhb/:serviceId/settings', component: ServiceValveHeatingBranchDetailSettingsComponent, canActivate: [adminAsUser]},

    // __________________ ADMIN __________________

    // EM boxes
    {path: 'admin/company/:companyId/devices', component: AdminDevicePageComponent, canActivate: [adminAsUser]},
    {path: 'admin/company/:companyId/device/:deviceId', component: AdminDeviceDetailPageComponent, canActivate: [adminAsUser]},

    // service-groups
    {path: 'admin/company/:companyId/service-groups/:serviceGroupId', component: AdminServiceGroupDetailPageComponent, canActivate: [adminAsUser]},
    {path: 'admin/company/:companyId/service-groups', component: AdminServiceGroupsComponent, canActivate: [adminAsUser]},

    // services
    {path: 'admin/company/:companyId/service/:serviceId', component: AdminServiceDetailPageComponent, canActivate: [adminAsUser]},

    // users
    {path: 'admin/company/:companyId/users', component: AdminUsersPageComponent, canActivate: [adminAsUser]},
    {path: 'users', component: AdminUsersPageComponent, canActivate: [partnerAdmin]},
    {path: 'admin/company/:companyId/users/:userId', component: AdminUserDetailPageComponent, canActivate: [adminAsUser]},
    {path: 'users/:userId', component: AdminUserDetailPageComponent, canActivate: [partnerAdmin]},

    // consumption points
    {path: 'admin/company/:companyId/consumption-points', component: AdminConsumptionPointsComponent, canActivate: [adminAsUser]},
    {path: 'admin/company/:companyId/consumption-points/:consumptionPointId', component: AdminConsumptionPointDetailPageComponent, canActivate: [adminAsUser]},
    {path: 'admin/company/:companyId/consumption-points/:consumptionPointId/period/:consumptionPeriodId', component: AdminConsumptionPeriodDetailPageComponent, canActivate: [adminAsUser]},

    // company detail
    {path: 'admin/company/:companyId/detail', component: AdminCompanyDetailPageComponent, canActivate: [adminAsUser]},

    // __________________ GLOBAL __________________

    // calculation
    {path: 'global/calculation', component: CalculationComponent, canActivate: [admin]},
    // companies
    {path: 'global/companies', component: GlobalCompaniesPageComponent, canActivate: [admin]},
    // wg settings
    {path: 'global/wg-settings', component: GlobalWgSettingsComponent, canActivate: [admin]},


];

export default appRoutes;
