import {GetServiceGroupsResponse, ServiceGroupResponse, SuggestServiceGroupsForAdditionResponse} from "../api";
import {IServiceGroup, IServiceGroupForSidebar} from "../interface/service.group.interface";
import {root} from "postcss";

export class ServiceGroupMapper {

    static mapServiceGroup(res: ServiceGroupResponse): IServiceGroup {
        return {
            name: res.name,
            id: res.serviceGroupId,
            note: res.note,
            serviceIds: res.serviceIds,
            orderNum: res.orderNum,
            parentServiceGroupId: res.parentServiceGroupId,
        } as IServiceGroup;
    }

    static mapServiceGroups(res: GetServiceGroupsResponse): IServiceGroup[] {
        if (!res || !res.list) {
            return [];
        }

        return res.list.map((group: ServiceGroupResponse) => {
            return ServiceGroupMapper.mapServiceGroup(group);
        })
    }

    static mapSuggestedServiceGroupsForAddition(res: SuggestServiceGroupsForAdditionResponse): IServiceGroup[] {
        if (!res || !res.serviceGroups) {
            return [];
        }

        return res.serviceGroups.map((group: ServiceGroupResponse) => {
            return ServiceGroupMapper.mapServiceGroup(group);
        })
    }

    private static mapServiceGroupForSidebar(group: IServiceGroup, lvl: number): IServiceGroupForSidebar {
        return {
            name: group.name,
            id: group.id,
            parentServiceGroupId: group.parentServiceGroupId,
            children: [],
            orderNum: group.orderNum,
            lvl: lvl,
            visible: lvl === 0,
            active: false,
        } as IServiceGroupForSidebar;

    }

    static mapServiceGroupsForSidebar(groups: IServiceGroup[]): IServiceGroupForSidebar[] {
        const rootGroups: IServiceGroupForSidebar[] = this.findRootGroupsAndRemove(groups);

        // search till all groups found and removed from the array
        while (groups.length > 0) {
            groups.forEach((group: IServiceGroup, index: number) => {
                let parent: IServiceGroupForSidebar | null = null;

                try {
                    parent = this.findParentRecursive(group.parentServiceGroupId!, rootGroups);
                } catch (error) {
                    console.error('Error finding parent service group:', error);
                    // just remove from the list to not end up in never ending loop
                    groups.splice(index, 1)[0];
                }
                if (parent) {
                    // remove element from the list and put as children to the parent elements
                    const removedElement: IServiceGroup = groups.splice(index, 1)[0];
                    parent.children.push(this.mapServiceGroupForSidebar(removedElement, parent.lvl + 1));
                }
            })
        }

        return rootGroups;
    }

    private static findParentRecursive(parentId: string, groups: IServiceGroupForSidebar[], callTimes: number = 0): IServiceGroupForSidebar | null {

        if (callTimes > 100) {
            throw new Error('Neverending recirsion. Parent ' + parentId + ' not found');
        }

        for (const group of groups) {
            // Check if the current group is the parent
            if (group.id === parentId) {
                return group;
            }

            // If the group has children, search recursively within them
            if (group.children && group.children.length > 0) {
                const parent = this.findParentRecursive(parentId, group.children, callTimes + 1);
                if (parent) {
                    return parent;
                }
            }
        }

        // If parent is not found, return null
        return null;
    }

    private static findRootGroupsAndRemove(groups: IServiceGroup[]): IServiceGroupForSidebar[] {
        const rootGroups: IServiceGroupForSidebar[] = [];

        for (let i = groups.length - 1; i >=0 ; i--) {
            if (!groups[i].parentServiceGroupId) {
                // remove the root elements from the groups
                const removedElement: IServiceGroup = groups.splice(i, 1)[0];
                rootGroups.push(this.mapServiceGroupForSidebar(removedElement, 0));
            }
        }

        return rootGroups;
    }
}