import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {Breadcrumb} from "./Breadcrumb";
import {CompanyService} from "../company.service";
import {LinkService} from "../link.service";
import {IServiceGroup} from "../../interface/service.group.interface";
import {ServiceGroupService} from "../service-group.service";
import {IService} from "../../interface/service.interface";

@Injectable({
    providedIn: 'root'
})
export class ClientBreadcrumbService {

    constructor(private serviceGroupService: ServiceGroupService, private companyService: CompanyService, private linkService: LinkService) {}

    private clientBreadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
    clientBreadcrumbs$ = this.clientBreadcrumbsSubject.asObservable();

    private set breadcrumbs(breadcrumbs: Breadcrumb[]) {
        this.clientBreadcrumbsSubject.next(breadcrumbs);
    }

    async ofClientServiceGroupDetail(groupId: string) {
        const group: IServiceGroup = await firstValueFrom(this.serviceGroupService.getCachedServiceGroup(groupId));

        const serviceGroups = this.serviceGroupsBreadcrumb();
        const parentServiceGroupDetails = await this.parentServiceGroupDetailsBreadcrumbs(group);
        const serviceGroupDetail = this.serviceGroupDetailBreadcrumb(group);

        this.breadcrumbs = [serviceGroups, ...parentServiceGroupDetails, serviceGroupDetail];
    }

    async ofClientServiceDetail(groupId: string, service: IService) {
        const group: IServiceGroup = await firstValueFrom(this.serviceGroupService.getCachedServiceGroup(groupId))

        const serviceGroups = this.serviceGroupsBreadcrumb();
        const parentServiceGroupDetails = await this.parentServiceGroupDetailsBreadcrumbs(group);
        const serviceGroupDetail = this.serviceGroupDetailBreadcrumb(group);
        const serviceDetail = this.serviceDetailBreadcrumb(groupId, service);

        this.breadcrumbs = [serviceGroups, ...parentServiceGroupDetails, serviceGroupDetail, serviceDetail];
    }

    async ofClientServiceSettings(groupId: string, service: IService) {
        const group: IServiceGroup = await firstValueFrom(this.serviceGroupService.getCachedServiceGroup(groupId))

        const serviceGroups = this.serviceGroupsBreadcrumb();
        const parentServiceGroupDetails = await this.parentServiceGroupDetailsBreadcrumbs(group);
        const serviceGroupDetail = this.serviceGroupDetailBreadcrumb(group);
        const serviceDetail = this.serviceDetailBreadcrumb(groupId, service);
        const serviceSettings = this.serviceSettingsBreadcrumb(groupId, service);

        this.breadcrumbs = [serviceGroups, ...parentServiceGroupDetails, serviceGroupDetail, serviceDetail, serviceSettings];
    }

    private serviceGroupsBreadcrumb(): Breadcrumb {
        return {label: 'Servisní skupiny'};
    }

    private serviceSettingsBreadcrumb(groupId: string, service: IService): Breadcrumb {
        const serviceId = service.serviceId;
        const type = service.type;
        return {label: 'Nastavení', url: this.linkService.clientServiceSettings(serviceId, type, serviceId)};
    }

    private serviceGroupDetailBreadcrumb(group: IServiceGroup) {
        return {'label': group.name, url: this.linkService.clientServiceGroupDetail(group.id)};
    }

    private serviceDetailBreadcrumb(groupId: string, service: IService): Breadcrumb {
        return {'label': service.name, url: this.linkService.clientServiceDetail(groupId, service)};
    }

    private async companyDetailBreadcrumb(): Promise<Breadcrumb> {
        const companyName = await firstValueFrom(this.companyService.getCachedCurrentCompanyName());
        return {
            label: companyName,
            url: this.linkService.adminCompanyDetail()
        };
    }

    private clientServiceGroupDetailBreadcrumb(group: IServiceGroup) {
        return {'label': group.name};
    }

    private async parentServiceGroupDetailsBreadcrumbs(group: IServiceGroup): Promise<Breadcrumb[]> {
        const list: Breadcrumb[] = [];
        while (group.parentServiceGroupId) {
            const parentGroup = await firstValueFrom(this.serviceGroupService.getCachedServiceGroup(group.parentServiceGroupId));
            list.unshift(this.clientServiceGroupDetailBreadcrumb(parentGroup))
            group = parentGroup;
        }

        return list;
    }
}
