import {Component, OnInit} from '@angular/core';
import {Location, NgClass, NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatToolbar, MatToolbarRow} from "@angular/material/toolbar";
import {ActivatedRoute} from "@angular/router";
import {DropdownItemDirective} from "@coreui/angular-pro";
import {BoxServiceService} from "../../service/box-service.service";
import {IService} from "../../interface/service.interface";
import {
  ServiceHotWaterTankDetailComponent
} from "../services/service-hot-water-tank/detail/service-hot-water-tank-detail.component";
import {IBoxServiceType} from "../../interface/box-service/box-service-type";
import {
  ServiceHeatingBranchDetailComponent
} from "../services/service-heating-branch/detail/service-heating-branch-detail.component";
import {
  ServiceCalorimeterDetailComponent
} from "../services/service-calorimeter/detail/service-calorimeter-detail.component";
import {
  ServiceAssociatedPowerDetailComponent
} from "../services/service-associated-power-meter/detail/service-associated-power-detail.component";
import {ServicePowerMeterComponent} from "../services/service-power-meter/tile/service-power-meter.component";
import {
  ServicePumpHeatingBranchComponent
} from "../services/service-pump-heating-branch/tile/service-pump-heating-branch.component";
import {
  ServicePumpHeatingBranchDetailComponent
} from "../services/service-pump-heating-branch/detail/service-pump-heating-branch-detail.component";
import {
  ServicePowerMeterDetailComponent
} from "../services/service-power-meter/detail/service-power-meter-detail.component";
import {UserService} from "../../service/user.service";
import {BreadcrumbComponent} from "../shared/breadcrumb/breadcrumb.component";
import {ServiceGroupService} from "../../service/service-group.service";
import {
  ServiceValveHeatingBranchDetailComponent
} from '../services/service-valve-heating-branch/detail/service-valve-heating-branch-detail.component';
import {ServiceColdWaterMeterDetailComponent} from "../services/service-cold-water-meter/detail/service-cold-water-meter-detail.component";
import {ServiceHotWaterMeterDetailComponent} from "../services/service-hot-water-meter/detail/service-hot-water-meter-detail.component";
import {NavigationService} from "../../service/navigation.service";
import {ClientBreadcrumbComponent} from "../shared/client-breadcrumb/client-breadcrumb.component";
import {ClientBreadcrumbService} from "../../service/breadcrumb/client-breadcrumb.service";

@Component({
  selector: 'app-service-detail',
  standalone: true,
    imports: [MatIconModule, MatToolbarRow, MatToolbar, DropdownItemDirective, ServiceHotWaterTankDetailComponent, NgIf, ServiceHeatingBranchDetailComponent, ServiceCalorimeterDetailComponent, ServiceAssociatedPowerDetailComponent, ServicePowerMeterComponent, ServicePumpHeatingBranchComponent, ServicePumpHeatingBranchDetailComponent, ServicePowerMeterDetailComponent, NgClass, BreadcrumbComponent, ServiceValveHeatingBranchDetailComponent, ServiceColdWaterMeterDetailComponent, ServiceHotWaterMeterDetailComponent, ClientBreadcrumbComponent],
  templateUrl: './service-detail.component.html',
  styleUrl: './service-detail.component.scss',
})
export class ServiceDetailComponent implements OnInit{

  companyId? : string | null;
  serviceGroupId?: string | null;
  serviceId?: string | null;
  settingsUrl: string;

  service?: IService;

  constructor(private location: Location,
              private navigationService: NavigationService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private boxServiceService: BoxServiceService,
              private serviceGroupService: ServiceGroupService,
              private clientBreadcrumbService: ClientBreadcrumbService) {
    this.settingsUrl = this.getSettingsUrl();
  }

  ngOnInit(): void {
    this.companyId = this.userService.getCompanyId();

    this.activatedRoute.paramMap.subscribe(params => {
      this.serviceGroupId = params.get('serviceGroupId');
      this.serviceId = params.get('serviceId');

        if (this.serviceId) {
            this.boxServiceService.getService(this.serviceId).subscribe((data: IService) => {
                this.service = data;
                if (this.serviceGroupId) {
                    this.clientBreadcrumbService.ofClientServiceDetail(this.serviceGroupId, this.service)
                }
            });
        }
    });
  }

  redirectBack(): void {
    this.location.back();
  }

  redirectToSettings() {
    this.navigationService.toClientServiceSettings(this.serviceId!, this.service!.type, this.serviceGroupId!);
  }

  getSettingsUrl(): string {
    const currentUrl = this.activatedRoute.snapshot.url.map(segment => segment.path).join('/');
    return `${currentUrl}/settings`;
  }

  protected readonly IBoxServiceType = IBoxServiceType;
}

