import {booleanAttribute, Component, EventEmitter, Input, OnChanges, Output, TemplateRef} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";

@Component({
  selector: 'app-text-select',
  standalone: true,
    imports: [
        NgForOf,
        NgIf,
        ReactiveFormsModule
    ],
  templateUrl: './text-select.component.html',
  styleUrl: './text-select.component.scss'
})
export class TextSelectComponent implements OnChanges{
    @Input() form!: FormGroup;
    @Input() label!: string;
    @Input() field!: string;
    @Input() readonly: boolean = false;
    @Input() options: ISelectOption[] = [];
    @Input({transform: booleanAttribute}) required: boolean = false;

    ngOnChanges(){
        if(this.readonly) {
            this.form.get(this.field)?.disable();
        } else {
            this.form.get(this.field)?.enable();
        }
    }

    formControl(): FormControl {
        return this.form.get(this.field) as FormControl
    }

    getOptions(): ISelectOption[] {
        return [{key: '', name: ''}, ...this.options];
    }
}

export interface ISelectOption {
    name: string,
    key: string;
}
