import {Component, OnInit} from '@angular/core';
import {ServiceGroupService} from "../../../service/service-group.service";
import {IServiceGroup} from "../../../interface/service.group.interface";
import {ActivatedRoute} from "@angular/router";
import {DateReadonlyInputComponent} from "../../shared/date-readonly-input/date-readonly-input.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {NgForOf, NgIf} from "@angular/common";
import {UiService} from "../../../service/ui.service";
import {ActionService} from "../../../service/action.service";
import {MODAL_NAME_DELETE_SERVICE_GROUP, MODAL_NAME_EDIT_SERVICE_GROUP} from "../../../interface/modal-type.interface";
import {EditServiceGroupComponent} from "../admin-service-groups/edit-service-group/edit-service-group.component";
import {AdminEditServiceComponent} from "../admin-device-detail-page/admin-edit-service/admin-edit-service.component";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {AddServiceGroupComponent} from "../admin-service-groups/add-service-group/add-service-group.component";
import {ServiceListOfServiceGroupComponent} from "./service-list-of-service-group/service-list-of-service-group.component";
import {SubServiceGroupListComponent} from "./sub-service-group-list/sub-service-group-list.component";
import {LinkService} from "../../../service/link.service";
import {DeleteServiceGroupComponent} from "../admin-service-groups/delete-service-group/delete-service-group.component";
import {NavigationService} from "../../../service/navigation.service";

@Component({
    selector: 'app-admin-service-group-detail-page',
    standalone: true,
    imports: [
        DateReadonlyInputComponent,
        TextInputReadonlyComponent,
        NgIf,
        NgForOf,
        EditServiceGroupComponent,
        AdminEditServiceComponent,
        AddServiceGroupComponent,
        ServiceListOfServiceGroupComponent,
        SubServiceGroupListComponent,
        DeleteServiceGroupComponent
    ],
    templateUrl: './admin-service-group-detail-page.component.html',
    styleUrl: './admin-service-group-detail-page.component.scss'
})
export class AdminServiceGroupDetailPageComponent implements OnInit {

    // data
    groupId!: string; // id from the route
    group?: IServiceGroup; // selected service group

    parentServiceGroupName?: string;
    parentServiceGroupLink?: string;

    constructor(
        private serviceGroupService: ServiceGroupService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private uiService: UiService,
        private linkService: LinkService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.groupId = params['serviceGroupId'];
            if (this.groupId) {
                this.loadDetail();
            }
        });
    }

    onServiceGroupEdit(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_EDIT_SERVICE_GROUP);
    }

    onServiceGroupEditConfirmed(group: IServiceGroup): void {
        this.loadDetail();
        this.actionService.reloadServiceGroups();
    }

    onServiceGroupDelete(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_DELETE_SERVICE_GROUP);
    }

    onServiceGroupDeleteConfirmed(group: IServiceGroup) {
        this.actionService.reloadServiceGroups();
        this.navigationService.toAdminServiceGroups();
    }

    private loadDetail(): void {
        this.serviceGroupService.getServiceGroup(this.groupId).subscribe((data: IServiceGroup) => {
            this.group = data;
            if (this.group.parentServiceGroupId) {
                this.parentServiceGroupLink = this.linkService.adminServiceGroupDetail(this.group.parentServiceGroupId);
                this.serviceGroupService.getCachedServiceGroup(this.group.parentServiceGroupId).subscribe((serviceGroup: IServiceGroup) => {
                    this.parentServiceGroupName = serviceGroup.name;
                });
            } else {
                this.parentServiceGroupLink = '';
                this.parentServiceGroupName = '';
            }
            this.adminBreadcrumbService.ofServiceGroupDetail(this.group);
        })
    }
}
