import { format } from 'date-fns';

export class DateUtils {

  static formatDateString(dateString: string): string {
    const date = new Date(dateString);
    return format(date, 'yyyy-MM-dd');
  }

  static getStartOfDay(date?: Date): string | undefined {
    const startOfDay = date ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0) : undefined;
    return startOfDay ? this.formatDate(startOfDay) : undefined;
  }

  static getEndOfDay(date?: Date): string | undefined {
    const endOfDay = date ? new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999) : undefined;
    return endOfDay ? this.formatDate(endOfDay) : undefined;
  }

  static formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);
    const milliseconds = ('00' + date.getMilliseconds()).slice(-3);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  }

  static formatToYear(date: Date): string {
    return `${date.getFullYear()}`;
  }

  static formatToMonth(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    return `${year}-${month}`;
  }

  static formatToDay(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

}
