import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location, NgClass, NgIf} from '@angular/common';
import {MatIcon} from "@angular/material/icon";
import {IService} from "../../interface/service.interface";
import {BreadcrumbComponent} from "../shared/breadcrumb/breadcrumb.component";
import {FormGroup} from "@angular/forms";
import {BoxServiceService} from "../../service/box-service.service";
import {ActivatedRoute} from "@angular/router";
import {ClientBreadcrumbService} from "../../service/breadcrumb/client-breadcrumb.service";
import {ClientBreadcrumbComponent} from "../shared/client-breadcrumb/client-breadcrumb.component";

@Component({
  selector: 'app-service-detail-settings',
  standalone: true,
    imports: [
        MatIcon,
        NgClass,
        BreadcrumbComponent,
        NgIf,
        ClientBreadcrumbComponent,
    ],
  templateUrl: './service-detail-settings.component.html',
  styleUrl: './service-detail-settings.component.scss',
})
export class ServiceDetailSettingsComponent implements OnInit {

  @Input() form?: FormGroup;
  @Output() confirmed = new EventEmitter<void>();
  service?: IService;

  constructor(private location: Location, private activatedRoute: ActivatedRoute, private boxServiceService: BoxServiceService, private clientBreadcrumbService: ClientBreadcrumbService) {
  }

  redirectBack(): void {
    this.location.back();
  }

  useSettings(): void {
    this.confirmed.emit();
  }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe(params => {
            const serviceId = params.get('serviceId');
            const serviceGroupId = params.get('serviceGroupId');
            if (serviceId) {
                this.boxServiceService.getService(serviceId).subscribe((data: IService) => {
                    this.service = data;
                    if (serviceGroupId) {
                        this.clientBreadcrumbService.ofClientServiceSettings(serviceGroupId, this.service).then();
                    }
                })
            }
        });
    }
}
