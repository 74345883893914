import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import { ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {DeleteModalComponent} from "../../../shared/delete-modal/delete-modal.component";
import {IUser} from "../../../../interface/user.interface";
import {UserService} from "../../../../service/user.service";
import {UiService} from "../../../../service/ui.service";
import {MODAL_NAME_DELETE_USER} from "../../../../interface/modal-type.interface";

@Component({
  selector: 'app-admin-delete-user',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        DeleteModalComponent
    ],
  templateUrl: './admin-delete-user.component.html',
  styleUrl: './admin-delete-user.component.scss'
})
export class AdminDeleteUserComponent {

    @Input() user?: IUser;
    @Output() userDeleted: EventEmitter<IUser> = new EventEmitter<IUser>();

    modalName = MODAL_NAME_DELETE_USER; // should be unique
    modalTitle = 'Smazat uživatele?';

    constructor(private userService: UserService, private uiService: UiService) {
    }

    onConfirm() {
        this.userService.deleteUser(this.user!.userId).subscribe(() => {
            this.userDeleted.emit(this.user);
            this.uiService.toggleModal(this.modalName);
            this.uiService.setSuccessAlert(`Uživatel ${this.user?.username} úspěšně smazán.`);
        })
    }

}
