import {booleanAttribute, Component, Input, OnChanges, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NgIf} from '@angular/common';
import {DatePickerComponent} from "@coreui/angular-pro";

@Component({
  selector: 'app-custom-year-picker-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    DatePickerComponent
  ],
  templateUrl: './custom-year-picker-input.component.html',
  styleUrl: './custom-year-picker-input.component.scss'
})
export class CustomYearPickerInputComponent implements OnInit, OnChanges {
  @Input() form!: FormGroup;
  @Input() label!: string;
  @Input() field!: string;
  @Input() readonly: boolean = false;
  @Input({transform: booleanAttribute}) required: boolean = false;
  @Input({transform: booleanAttribute}) hideLabel: boolean = false;

  selectedValue?: string;

  formControl(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  ngOnInit() {
    this.selectedValue = this.formControl().value;
  }

  ngOnChanges() {
    if (this.readonly) {
      this.form.get(this.field)?.disable();
    } else {
      this.form.get(this.field)?.enable();
    }
    this.form.get(this.field)?.valueChanges
      .subscribe(value => {
        if (value) {
          this.selectedValue = this.formControl().value;
        }
      });
  }

  onValueChange(): void {
    this.formControl().patchValue(this.selectedValue);
  }

  formatToYear(date: Date): string {
    return `${date.getFullYear()}`;
  }
}
