<app-service-detail-settings [form]="form" (confirmed)="useSettings()">
    <ng-container *ngIf="form">
        <div class="mt-1">
            <app-rotary-switch
                    [options]="options"
                    [selectedOption]="controlTypeControl?.value"
                    (selectionChanged)="onRotarySwitchSelectionChange($event)"
            ></app-rotary-switch>
        </div>
        <ng-container
                *ngIf="isConstTemp() || isReducedTemp() || isTimeControl()"> <!--isConstTemp || isReducedTemp || isTimeControl-->
            <div class="slider-div">
                <ng-container *ngIf="isConstTemp() || isTimeControl()">
                    <div class="center-title">
                        Nastavit teplotu pro Topit:
                    </div>
                    <div class="slider-container">
                        <app-custom-slider
                                [min]="5"
                                [max]="29"
                                [step]="0.1"
                                [form]="constTempControl"
                        ></app-custom-slider>
                    </div>
                </ng-container>
                <ng-container *ngIf="isReducedTemp() || isTimeControl()">
                    <div class="center-title">
                        Nastavit teplotu pro Útlum:
                    </div>
                    <div class="slider-container">
                        <app-custom-slider
                                [min]="5"
                                [max]="29"
                                [step]="0.1"
                                [form]="reducedTempControl"
                        ></app-custom-slider>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <ng-container *ngIf="isTimeControl()">
            <div class="schedule-grid-container">
                <app-schedule-grid [inputSchedule]="timeControlValue"
                                   (scheduleEmitter)="updateSchedule($event)"></app-schedule-grid>
            </div>
        </ng-container>
    </ng-container>
</app-service-detail-settings>