<c-modal [visible]="modalVisible" (visibleChange)="handleModalChange($event)">
    <c-modal-header>
        <h5 cModalTitle>{{ title }}</h5>
        <button (click)="toggleModal()" cButtonClose></button>
    </c-modal-header>
    <c-modal-body>
        <ng-content></ng-content>
    </c-modal-body>
    <c-modal-footer class="d-flex justify-content-start">
        <button (click)="toggleModal()" cButton color="primary">
            Rozumim
        </button>
    </c-modal-footer>
</c-modal>