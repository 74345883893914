import { Component, Input } from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IPumpHeatingBranchDataDetail} from "../../../../interface/pump-heating-branch.interface";
import {IPumpHeatingBranchDetail} from "../../../../interface/box-service/pump-heating-branch-detail";

@Component({
  selector: 'app-service-pump-heating-branch',
  templateUrl: './service-pump-heating-branch.component.html',
  styleUrl: 'service-pump-heating-branch.component.scss',
  imports: [],
  standalone: true
})
export class ServicePumpHeatingBranchComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

  getDetail(): IPumpHeatingBranchDetail | undefined {
     return this.service?.detail as IPumpHeatingBranchDetail;
  }

  getTempRoom(): number {
    return this.getDetail()?.tempRoom || 0;
  }

}
