import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import { ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {DeleteModalComponent} from "../../../shared/delete-modal/delete-modal.component";
import {UiService} from "../../../../service/ui.service";
import { MODAL_NAME_DELETE_SERVICE_GROUP} from "../../../../interface/modal-type.interface";
import {IServiceGroup} from "../../../../interface/service.group.interface";
import {ServiceGroupService} from "../../../../service/service-group.service";

@Component({
  selector: 'app-delete-service-group',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        DeleteModalComponent
    ],
  templateUrl: './delete-service-group.component.html',
  styleUrl: './delete-service-group.component.scss'
})
export class DeleteServiceGroupComponent {

    @Input() group?: IServiceGroup;
    @Output() groupDeleted: EventEmitter<IServiceGroup> = new EventEmitter<IServiceGroup>();

    modalName = MODAL_NAME_DELETE_SERVICE_GROUP; // should be unique
    modalTitle = 'Smazat skupinu?';

    constructor(private serviceGroupService: ServiceGroupService, private uiService: UiService) {
    }

    onConfirm() {
        this.serviceGroupService.deleteServiceGroup(this.group!.id).subscribe(() => {
            this.groupDeleted.emit(this.group);
            this.uiService.toggleModal(this.modalName);
            this.uiService.setSuccessAlert(`Servisní skupina ${this.group?.name} úspěšně smazána.`);
        })
    }

}
