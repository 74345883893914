import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {UserService} from "../../service/user.service";
import {UiService} from "../../service/ui.service";

@Component({
    selector: 'app-password-change',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf
    ],
    templateUrl: './password-change.component.html',
    styleUrl: './password-change.component.scss'
})
export class PasswordChangeComponent implements OnInit {
    passwordChangeForm!: FormGroup;
    passwordsMismatch: boolean = false;
    submitting = false;
    passwordChangedSuccess = false;

    @Output() passwordChanged: EventEmitter<void> = new EventEmitter<void>();
    @Output() passwordChangeCancelled: EventEmitter<void> = new EventEmitter<void>();

    constructor(private fb: FormBuilder, private userService: UserService, private uiService: UiService) {
    }

    ngOnInit(): void {
        this.passwordChangeForm = this.fb.group({
            oldPassword: ['', Validators.required],
            newPassword1: ['', [Validators.required, Validators.minLength(5)]],
            newPassword2: ['', Validators.required]
        });
    }

    onSubmit(): void {
        if (this.submitting) {
            return;
        }
        this.passwordChangedSuccess = false;
        this.passwordsMismatch = false
        const newPassword1 = this.passwordChangeForm.get('newPassword1')?.value;
        const newPassword2 = this.passwordChangeForm.get('newPassword2')?.value;
        const oldPassword = this.passwordChangeForm.get('oldPassword')?.value;

        if (newPassword1 !== newPassword2) {
            this.passwordsMismatch = true;
            return;
        }

        if (this.passwordChangeForm.valid) {
            this.submitting = true;
            this.userService.changePassword(oldPassword, newPassword1).subscribe(
                () => {
                    this.submitting = false;
                    this.passwordChangedSuccess = true;
                    this.passwordChanged.emit();
                    this.uiService.setSuccessAlert('Heslo úspěšně změněno');
                },
                (err) => {
                    this.submitting = false;
                    this.passwordChangedSuccess = false;
                }
            )
        }
    }

    cancelPasswordChange(event: Event) {
        event.preventDefault();
        this.passwordChangeCancelled.emit();
    }
}
