import { Component, Input } from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IColdWaterMeterDetail} from "../../../../interface/box-service/cold-water-meter-detail";

@Component({
  selector: 'app-service-cold-water-meter',
  templateUrl: './service-cold-water-meter.component.html',
  styleUrl: 'service-cold-water-meter.component.scss',
  imports: [],
  standalone: true
})
export class ServiceColdWaterMeterComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

    getDetail(): IColdWaterMeterDetail | undefined {
        return this.service?.detail as IColdWaterMeterDetail;
    }

  getFlowRate(): number {
      return this.getDetail()?.flowRate || 0;
  }

  getXX(): number {
    return this.service && this.service.detail ? 0 : 0;
  }

  getYY(): number {
    return this.service && this.service.detail ? 0 : 0;
  }
}
