import {Component, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {UiService} from "../../../service/ui.service";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {DeleteModalComponent} from "../../shared/delete-modal/delete-modal.component";
import {MODAL_NAME_ADD_COMPANY, MODAL_NAME_COMPANY_CONTEXT_CHANGED, MODAL_NAME_DELETE_COMPANY, MODAL_NAME_EDIT_COMPANY} from "../../../interface/modal-type.interface";
import {NavigationService} from "../../../service/navigation.service";
import {ICompany} from "../../../interface/company.interface";
import {CompanyService} from "../../../service/company.service";
import {AddCompanyComponent} from "./add-company/add-company.component";
import {DeleteCompanyComponent} from "./delete-company/delete-company.component";
import {EditCompanyComponent} from "./edit-company/edit-company.component";
import {UserService} from "../../../service/user.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";

@Component({
  selector: 'app-global-companies-page',
  standalone: true,
    imports: [
        NgForOf,
        TableComponent,
        DeleteModalComponent,
        AddCompanyComponent,
        DeleteCompanyComponent,
        EditCompanyComponent
    ],
  templateUrl: './global-companies-page.component.html',
  styleUrl: './global-companies-page.component.scss'
})
export class GlobalCompaniesPageComponent implements OnInit{

    companies: ICompany[] = [];
    companyToBeDeleted?: ICompany;
    companyToBeEdited?: ICompany;

    config: ITableConfig[] = [
        {field: 'name', label: 'Jméno'},
        {field: 'address', label: 'Adresa'},
        {field: 'ic', label: 'IČ'},
        {field: 'dic', label: 'DIČ'},
        {field: 'email', label: 'Email'},
        {field: 'phone', label: 'Telefon'},
        {
            specialComponent: {
                type: 'edit',
                onClick: this.onUserEdited.bind(this)
            }
        },
        {
            specialComponent: {
                type: 'delete',
                onClick: this.onCompanyDelete.bind(this)
            }
        },
    ];

    constructor(private companyService: CompanyService, private uiService: UiService, private navigationService: NavigationService, private userService: UserService, private adminBreadcrumbService: AdminBreadcrumbService) {
    }

    ngOnInit(): void {
        this.loadData();
        this.navigationService.rememberLastTabVisited();
        this.adminBreadcrumbService.ofCompanies();
    }

    private loadData(): void {
        this.companyService.getAllCompanies().subscribe((data: ICompany[]) => {
            this.companies = data;
        })
    }

    onRowClicked(company: ICompany): void {
        this.userService.changeCompanyId(company.companyId);
        this.navigationService.toAdminCompanyDetail(company.companyId);
    }

    onCompanyAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_ADD_COMPANY);
    }

    onCompanyAddSubmitted() {
        this.loadData();
    }

    onCompanyDelete(company: ICompany): void {
        this.companyToBeDeleted = company;
        this.uiService.toggleModal(MODAL_NAME_DELETE_COMPANY);
    }

    onCompanyDeleteConfirm(company: ICompany) {
        if (company.companyId === this.userService.getCompanyId()) {
            // user deleted company which is in his context
            this.userService.changeCompanyId(this.userService.getMyRealCompanyId());
            setTimeout(() => {
                this.uiService.toggleModal(MODAL_NAME_COMPANY_CONTEXT_CHANGED);
            }, 100);
        }
        this.loadData();
    }

    onUserEdited(company: ICompany): void {
        this.companyToBeEdited = company;
        this.uiService.toggleModal(MODAL_NAME_EDIT_COMPANY);
    }

    onCompanyEditConfirmed(company: ICompany): void {
        this.loadData();
    }
}
