import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {IService} from "../../../../interface/service.interface";
import {ServiceService} from "../../../../service/service.service";
import {NgForOf, NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {DropdownComponent} from "../../../shared/dropdown/dropdown.component";
import {IDropdownItem} from "../../../../interface/dropdown.item.interface";
import {BoxServiceService} from "../../../../service/box-service.service";
import {MODAL_NAME_EDIT_SERVICE} from "../../../../interface/modal-type.interface";
import {EditServiceGroupComponent} from "../../admin-service-groups/edit-service-group/edit-service-group.component";
import {ITableConfig, TableComponent} from "../../../shared/table/table.component";
import {AdminEditServiceComponent} from "../../admin-device-detail-page/admin-edit-service/admin-edit-service.component";
import {NavigationService} from "../../../../service/navigation.service";

@Component({
    selector: 'app-service-list-of-service-group',
    standalone: true,
    imports: [
        NgIf,
        NgForOf,
        DropdownComponent,
        EditServiceGroupComponent,
        TableComponent,
        AdminEditServiceComponent,
    ],
    templateUrl: './service-list-of-service-group.component.html',
    styleUrl: './service-list-of-service-group.component.scss'
})
export class ServiceListOfServiceGroupComponent implements OnInit {

    // data
    serviceGroupId!: string; // id from the route
    services: IService[] = []; // services inside the serviceGroup
    allServices: IService[] = []; // all services belong to the company

    serviceToBeEdited?: IService;

    config: ITableConfig[] = [
        {field: 'status', specialComponent: {
                type: 'status'
        }},
        {field: 'name', label: 'Název'},
        {field: 'serviceName', label: 'Servisní jméno'},
        {field: 'note', label: 'Poznámka'},
        {field: 'orderNum', label: 'Pořadí'},
        {field: 'type', label: 'Typ', specialComponent: {
                type: 'serviceType',
        }},
        {
            specialComponent: {
                type: 'edit',
                onClick: this.onEditService.bind(this)
            }
        },
        {
            specialComponent: {
                type: 'minus',
                onClick: this.onServiceRemovedFromGroup.bind(this)
            }
        },
    ]

    constructor(
        private serviceService: ServiceService,
        private boxService: BoxServiceService,
        private uiService: UiService,
        private navigationService: NavigationService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.serviceGroupId = params['serviceGroupId'];
            if (this.serviceGroupId) {
                this.loadServicesOfServiceGroup();
                this.loadAllServices();
            }
        });
    }
    getServicePossibilities(): IDropdownItem[] {
        // do not suggest services which already are inside this group
        const ids = new Set(
            this.services.map(item => item.serviceId)
        );

        return this.allServices
            .filter(item => !ids.has(item.serviceId))
            .map((service: IService) => {
                return {id: service.serviceId, name: service.name} as IDropdownItem;
            })
    }

    onServiceAddedToGroup(item: IDropdownItem): void {
        this.boxService.addToServiceGroup(item.id, this.serviceGroupId)
            .subscribe(() => {
                this.uiService.setSuccessAlert(`Služba ${item.name} úspěšně přidána do skupiny.`);
                this.loadServicesOfServiceGroup();
            }
        )
    }

    onEditService(service: IService): void {
        this.serviceToBeEdited = service;
        this.uiService.toggleModal(MODAL_NAME_EDIT_SERVICE);
    }

    onEditServiceConfirmed(service: IService): void {
        this.loadServicesOfServiceGroup();
    }

    onServiceRowClicked(service: IService): void {
        this.navigationService.toAdminService(service.serviceId)
    }

    onServiceRemovedFromGroup(service: IService): void {
        this.boxService.removeFromServiceGroup(service.serviceId, this.serviceGroupId)
            .subscribe(() => {
                    this.uiService.setSuccessAlert(`Služba ${service.name} úspěšně odebrána ze skupiny.`);
                    this.loadServicesOfServiceGroup();
                }
            )
    }

    private loadServicesOfServiceGroup(): void {
        this.serviceService.getServicesByServiceGroup(this.serviceGroupId).subscribe((data: IService[]) => {
            this.services = data;
        })
    }

    private loadAllServices(): void {
        this.serviceService.getMyServices().subscribe(
            (data: IService[]): void => {
                this.allServices = data;
            }
        )
    }
}
