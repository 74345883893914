<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <fieldset>
        <div class="row">
            <div class="col-lg-6">
                <app-custom-date-picker-input [form]="form" [label]="'Od'" [field]="'startPeriod'" required></app-custom-date-picker-input>
            </div>
            <div class="col-lg-6">
                <app-text-input-readonly [label]="'Tarif'" [value]="translateTariffKey(period?.tariff)"></app-text-input-readonly>
            </div>
        </div>
    </fieldset>

    <ng-container *ngIf="period?.tariff === 'FIX1'">
        <!-- Distribuce -->
        <fieldset class="pb-3">
            <h5>Distribuce</h5>
            <div class="row">
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Paušál (Kč)'" [field]="'flatRateDistribution'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Distribuční sazba (Kč/MWh)'" [field]="'rate1VTDistributionMWh'" required></app-text-input>
                </div>
                <ng-container *ngIf="point?.voltageLevel === 'NN'">
                    <div class="col-lg-6">
                        <app-text-select [form]="form" [label]="'Distribuční sazba'" [field]="'distributionTariff'" [options]="distributionTariffs" required></app-text-select>
                    </div>
                    <div class="col-lg-6">
                        <app-text-input [form]="form" [label]="'Jistič'" [field]="'breaker'" required></app-text-input>
                    </div>
                </ng-container>
            </div>
        </fieldset>

        <!-- Silová -->
        <fieldset class="pb-3">
            <h5>Silová</h5>
            <div class="row">
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'V1/T1 (Kč/MWh)'" [field]="'rate1VTPowerMWh'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Paušál (Kč)'" [field]="'flatRatePower'" required></app-text-input>
                </div>
            </div>
        </fieldset>

    </ng-container>

    <ng-container *ngIf="period?.tariff === 'FIX2'">
        <!-- Distribuce -->
        <fieldset class="pb-3">
            <h5>Distribuce</h5>
            <div class="row">
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Paušál (Kč)'" [field]="'flatRateDistribution'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Distribuční sazba VT (Kč/MWh)'" [field]="'rate1VTDistributionMWh'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Distribuční sazba NT (Kč/MWh)'" [field]="'rate2NTDistributionMWh'" required></app-text-input>
                </div>
                <ng-container *ngIf="point?.voltageLevel === 'NN'">
                    <div class="col-lg-6">
                        <app-text-select [form]="form" [label]="'Distribuční sazba'" [field]="'distributionTariff'" [options]="distributionTariffs" required></app-text-select>
                    </div>
                    <div class="col-lg-6">
                        <app-text-input [form]="form" [label]="'Jistič'" [field]="'breaker'" required></app-text-input>
                    </div>
                </ng-container>
            </div>
        </fieldset>

        <!-- Silová -->
        <fieldset class="pb-3">
            <h5>Silová</h5>
            <div class="row">
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'V1/T1 (Kč/MWh)'" [field]="'rate1VTPowerMWh'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'V2/T2 (Kč/MWh)'" [field]="'rate2NTPowerMWh'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Paušál (Kč)'" [field]="'flatRatePower'" required></app-text-input>
                </div>
            </div>
        </fieldset>

    </ng-container>

    <ng-container *ngIf="period?.tariff === 'SPOT'">

        <!-- Distribuce -->
        <fieldset class="pb-3">
            <h5>Distribuce</h5>
            <div class="row">
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Paušál (Kč)'" [field]="'flatRateDistribution'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Distribuční sazba (Kč/MWh)'" [field]="'spotDistributionMwh'" required></app-text-input>
                </div>
                <ng-container *ngIf="this.point?.voltageLevel === 'NN'">
                    <div class="col-lg-6">
                        <app-text-select [form]="form" [label]="'Distribuční sazba'" [field]="'distributionTariff'" [options]="distributionTariffs" required></app-text-select>
                    </div>
                    <div class="col-lg-6">
                        <app-text-input [form]="form" [label]="'Jistič'" [field]="'breaker'" required></app-text-input>
                    </div>
                </ng-container>
            </div>
        </fieldset>

        <!-- Silová -->
        <fieldset class="pb-3">
            <h5>Silová</h5>
            <div class="row">
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Přírážka obchodníka (Kč)'" [field]="'spotPowerMWh'" required></app-text-input>
                </div>
                <div class="col-lg-6">
                    <app-text-input [form]="form" [label]="'Paušál (Kč)'" [field]="'flatRatePower'" required></app-text-input>
                </div>
            </div>
        </fieldset>
    </ng-container>

</form>