<li *ngIf="this.group?.visible" class="nav-item nav-group {{'lvl-' + group?.lvl}} " [ngClass]="group?.opened ? 'show' : ''">

    <a class="nav-link px-lg-0" [ngClass]="{'active': isActive, 'nav-group-toggle': hasChildren()}" href="#" (click)="onGroupClick($event)">
        <i class="nav-icon ml-2 " [ngClass]="{'cil-puzzle': this.group?.lvl === 0}"></i>
        {{ group?.name }}
    </a>
    <ng-container *ngIf="hasChildren()">
        <app-side-bar-groups [serviceGroups]="getChildren()"></app-side-bar-groups>
    </ng-container>

</li>