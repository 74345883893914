import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'date',
  standalone: true
})
export class DatePipe implements PipeTransform {

    transform(value?: string): string {
        if (!value) return '';

        const date = new Date(value);

        const day = date.getDate();
        const month = date.getMonth() + 1; // months are zero-based
        const year = date.getFullYear();

        return `${day}. ${month}. ${year}`;
    }
}
