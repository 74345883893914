<form [formGroup]="passwordChangeForm" (ngSubmit)="onSubmit()">
    <div class="form-group mb-2">
        <label for="oldPassword"><strong>Staré heslo</strong><span class="required">*</span></label>
        <input id="oldPassword" formControlName="oldPassword" type="password" class="form-control">
        <div class="error" *ngIf="passwordChangeForm.get('oldPassword')?.invalid && passwordChangeForm.get('oldPassword')?.touched">
            Staré heslo je povinné
        </div>
    </div>


    <div class="form-group mb-2">
        <label for="newPassword1"><strong>Nové heslo<span class="required">*</span></strong></label>
        <input id="newPassword1" formControlName="newPassword1" type="password" class="form-control">
        <div class="error" *ngIf="passwordChangeForm.get('newPassword1')?.invalid && passwordChangeForm.get('newPassword1')?.touched">
            <div *ngIf="passwordChangeForm.get('newPassword1')?.errors?.['required']">Nové heslo je povinné</div>
            <div *ngIf="passwordChangeForm.get('newPassword1')?.errors?.['minlength']">Nové heslo musí mít alespoň 5 znaků</div>
        </div>
    </div>

    <div class="form-group mb-2">
        <label for="newPassword2"><strong>Potvrďte heslo</strong><span class="required">*</span></label>
        <input id="newPassword2" formControlName="newPassword2" type="password" class="form-control">
        <div class="error" *ngIf="passwordChangeForm.get('newPassword2')?.invalid && passwordChangeForm.get('newPassword2')?.touched">
            Nové heslo je povinné
        </div>
    </div>

    <div class="error" *ngIf="passwordsMismatch">
        Hesla nejsou shodná
    </div>
    <div class="d-flex my-2">
        <button class="btn btn-dark" type="submit" [disabled]="passwordChangeForm.invalid">Změnit heslo</button>
        <button class="btn btn-light ml-1" (click)="cancelPasswordChange($event)">Zrušit</button>
    </div>
</form>