<div class="container">
    <div class="box-shadow my-3 min-h-44 pb-3">
        <h3>Přehled uživatelů</h3>
        <app-table [config]="config" [data]="users" (rowClicked)="onRowClicked($event)"></app-table>
        <button type="button" class="btn btn-primary" (click)="onUserAdd($event)">Přidat uživatele</button>
    </div>

    <!-- modal dialogs    -->
    <app-admin-add-user (userCreated)="onUserAddSubmitted()"></app-admin-add-user>
    <app-admin-delete-user [user]="userToBeDeleted" (userDeleted)="onUserDeleteConfirmed($event)"></app-admin-delete-user>
    <app-admin-edit-user [user]="userToBeEdited" (userEdited)="onUserEditConfirmed($event)"></app-admin-edit-user>
</div>
