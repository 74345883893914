<div class="hot-water-tank-wrapper" [style.min-height.px]="569">
    <div class="branch-header" [ngClass]="data.freshData ? 'fresh-data' : 'old-data'">
        <div class="d-flex justify-content-between">
            <div>
                {{
                    data.name ? data.name : '&nbsp;'
                }}
            </div>
            <div class="font-italic" *ngIf="!data.freshData" style="font-style: italic">
                stará data
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <img
                (dblclick)="toggleTextData()"
                ngSrc="../../../../assets/img/hot-water-tank.png"
                alt="TUV"
                class="background-image"
                [style.width.px]="320"
                height="339"
                width="229"
        />
    </div>
</div>

<div class="temp-tank">
    {{
        data.serviceDataDetail ? data.serviceDataDetail.tempTank + " °C" : "-"
    }}
</div>

<div class="header-item header-value">
    teplota v zásobníku
</div>

<div *ngIf="showTextData" class="card" [style.width.px]="350">
    <div class="card-body">
        <div>
            status: {{ data.serviceDataDetail ? data.serviceDataDetail.status : "-" }}
        </div>
        <div>
            circulationPump:
            {{
                data.serviceDataDetail ? data.serviceDataDetail.circulationPump : "-"
            }}
        </div>
        <div>
            heatingC:
            {{ data.serviceDataDetail ? data.serviceDataDetail.heatingC : "-" }}
        </div>
        <div>
            heatingE:
            {{ data.serviceDataDetail ? data.serviceDataDetail.heatingE : "-" }}
        </div>
        <div>
            tempTank:
            {{
                data.serviceDataDetail ? data.serviceDataDetail.tempTank + " °C" : "-"
            }}
        </div>
        <div>
            tempCirculation:
            {{
                data.serviceDataDetail ? data.serviceDataDetail.tempCirculation + " °C" : "-"
            }}
        </div>
    </div>
</div>
