import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import {
  GetPowerMeterGraphRequest,
  GetPowerMeterGraphResponse,
  GetPowerMeterDataRequest,
  GetPowerMeterDataResponse,
} from '../api';
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root',
})
export class PowerMeterService {
  constructor(private apiService: ApiService, private userService: UserService) {}

  public getPowerMeterGraphData(
    getPowerMeterGraphRequest: GetPowerMeterGraphRequest
  ): Observable<GetPowerMeterGraphResponse> {
    return this.apiService.post(
      '/box/service/get-power-graph-data',
      getPowerMeterGraphRequest
    );
  }

    public getMyPowerMeterData(): Observable<GetPowerMeterDataResponse> {
      const body: GetPowerMeterDataRequest = {companyId: this.userService.getCompanyId()}
        return this.apiService.post(
            '/box/service/get-power-meter-data',
            body
        );
    }
}
