import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import { ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {DeleteModalComponent} from "../../../shared/delete-modal/delete-modal.component";
import {UiService} from "../../../../service/ui.service";
import {MODAL_NAME_DELETE_SERVICE} from "../../../../interface/modal-type.interface";
import {IService} from "../../../../interface/service.interface";
import {BoxServiceService} from "../../../../service/box-service.service";

@Component({
  selector: 'app-admin-device-delete-service',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        DeleteModalComponent
    ],
  templateUrl: './admin-device-delete-service.component.html',
  styleUrl: './admin-device-delete-service.component.scss'
})
export class AdminDeviceDeleteServiceComponent {

    @Input() service?: IService;
    @Output() serviceDeleted: EventEmitter<IService> = new EventEmitter<IService>();

    modalName = MODAL_NAME_DELETE_SERVICE; // should be unique
    modalTitle = 'Smazat uživatele?';

    constructor(private boxServiceService: BoxServiceService, private uiService: UiService) {
    }

    onConfirm() {
        this.boxServiceService.deleteService(this.service!.serviceId).subscribe(() => {
            this.serviceDeleted.emit(this.service);
            this.uiService.toggleModal(this.modalName);
            this.uiService.setSuccessAlert(`Služba ${this.service!.name} úspěšně odebrána.`);
        });
    }

}
