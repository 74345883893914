<div class="container p-3">
  <h3>Upravit kalkulaci</h3>
  <div class="text-end">
    <button (click)="addPeriod()" [disabled]="!isAddable()" class="btn btn-primary">Přidat období</button>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="table-container">
      <table class="table">
        <thead>
        <tr>
          <th [style.width.%]="20" scope="col">
            Období
          </th>
          <th [style.width.%]="20" scope="col">
            Od
          </th>
          <th [style.width.%]="20" scope="col">
            Do
          </th>
          <th [style.width.%]="11" class="cell-horizontal-middle" scope="col">
            Do (Hod)
          </th>
          <th [style.width.%]="11" class="cell-horizontal-middle" scope="col">
            Procenta (%)
          </th>
          <th [style.width.%]="11" class="cell-horizontal-middle" scope="col">
            Max
          </th>
          <th [style.width.%]="7" class="cell-horizontal-middle" scope="col">
            Akce
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let periodControl of periodsForm.controls; let i = index;" [attr.data-index]="i">
          <td [style.width.%]="20">
            <app-text-input [form]="getFormGroup(periodControl)" [label]="'Období'" [field]="'name'" [hideLabel]="true"></app-text-input>
          </td>
          <td [style.width.%]="20">
            <app-custom-date-picker-input class="fixed-position-date-picker" [form]="getFormGroup(periodControl)" [label]="'Od'" [field]="'startDate'" [hideLabel]="true"></app-custom-date-picker-input>
          </td>
          <td [style.width.%]="20">
            <app-custom-date-picker-input class="fixed-position-date-picker" [form]="getFormGroup(periodControl)" [label]="'Do'" [field]="'endDate'" [hideLabel]="true"></app-custom-date-picker-input>
          </td>
          <td [style.width.%]="11">
            <app-text-input [form]="getFormGroup(periodControl)" [label]="'Do (Hod)'" [field]="'hour'" [class]="'cell-horizontal-middle'" [hideLabel]="true"></app-text-input>
          </td>
          <td [style.width.%]="11">
            <app-text-input [form]="getFormGroup(periodControl)" [label]="'Procenta (%)'" [field]="'percent'" [class]="'cell-horizontal-middle'" [hideLabel]="true"></app-text-input>
          </td>
          <td [style.width.%]="11">
            <app-text-input [form]="getFormGroup(periodControl)" [label]="'Max'" [field]="'maximum'" [class]="'cell-horizontal-middle'" [hideLabel]="true"></app-text-input>
          </td>
          <td [style.width.%]="7" class="cell-horizontal-middle">
            <button (click)="removePeriod(i)" [disabled]="!isRemovable()" type="button"
                    class="btn btn-primary rounded-pill">X
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </form>
  <div class="text-center">
    <button (click)="onSubmit()" class="btn btn-primary mt-4">Odeslat</button>
  </div>
  <!-- modal dialogs    -->
  <app-upload-file-modal [taskId]="taskId" (uploaded)="onUploadedFile()"></app-upload-file-modal>
</div>
