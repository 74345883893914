import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_ADD_CONSUMPTION_POINT} from "../../../../interface/modal-type.interface";
import {ConsumptionPointService} from "../../../../service/consumption-point.service";
import {INewConsumptionPoint} from "../../../../interface/consumption-point.interface";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {CustomValidators} from "../../../../util/validator/custom.validators";
import {getVoltageOptions} from "../../../../util/translate/translate-key";

@Component({
  selector: 'app-add-consumption-point',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        FormModalComponent,
        TextSelectComponent
    ],
  templateUrl: './add-consumption-point.component.html',
  styleUrl: './add-consumption-point.component.scss'
})
export class AddConsumptionPointComponent implements OnInit{
    @Output() created: EventEmitter<void> = new EventEmitter<void>();

    form!: FormGroup;

    modalName = MODAL_NAME_ADD_CONSUMPTION_POINT // should be unique
    modalTitle = 'Přidat nové odběrné místo';

    @Input() electricityDistributors: ISelectOption[] = [];
    // @Input() gasDistributors: ISelectOption[] = [];

    voltageOptions: ISelectOption[] = getVoltageOptions();

    constructor(
        private fb: FormBuilder,
        private consumptionPointService: ConsumptionPointService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            ean: ['', [CustomValidators.isNumber]],
            eic: ['', []],
            address: ['', ],
            electricityDistributor: ['', []] ,
            voltageLevel: ['', []] ,
            gps: ['', []] ,
        })
    }

    onClose(): void {
        this.initForm();
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {ean, eic, address, electricityDistributor, voltageLevel, gps } = this.form.value;
            const newConsumptionPoint: INewConsumptionPoint = {ean, eic, address, electricityDistributor, voltageLevel, gps};
            this.consumptionPointService.createConsumptionPoint(newConsumptionPoint)
                .subscribe(() => {
                    this.created.emit();
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Odběrné místo ${ean} úspěšně vytvořeno.`);
                    this.initForm();
                });
        }
    }
}
