import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../service/user.service";
import {CompanyService} from "../../service/company.service";
import {ICompany} from "../../interface/company.interface";
import {ScreenSize, UiService} from "../../service/ui.service";
import {NgClass, NgForOf} from "@angular/common";
import {CompanySelectOptionComponent} from "../company-select-option/company-select-option.component";
import {FormsModule} from "@angular/forms";

@Component({
    selector: 'app-company-select',
    standalone: true,
    imports: [
        NgClass,
        NgForOf,
        CompanySelectOptionComponent,
        FormsModule
    ],
    templateUrl: './company-select.component.html',
    styleUrl: './company-select.component.scss'
})
export class CompanySelectComponent implements OnInit {

    @ViewChild('filterInput', {static: false}) filterInput!: ElementRef;

    companies: ICompany[] = [];
    selectedCompany?: ICompany;
    show: boolean = false;
    filterText: string = '';

    screenSize: ScreenSize = 'sm';

    constructor(private userService: UserService,
                private companyService: CompanyService,
                private uiService: UiService,
                private elementRef: ElementRef) {
    }

    ngOnInit() {
        this.uiService.screenSize$
            .subscribe((el: ScreenSize) => {
                this.screenSize = el;
            });

        this.userService.companyChanged$.subscribe((companyId: string) => {
            if (companyId) {
                this.companyService.getCompany(companyId).subscribe((company: ICompany) => {
                    this.selectedCompany = company;
                })
            }
        });

        this.companyService.getAllCompanies().subscribe(
            (companies) => {
                this.companies = companies;
                this.selectedCompany = this.findCompany();
            }
        )
    }

    screenBiggerThanMd(): boolean {
        return this.screenSize === "lg" || this.screenSize === "xl";
    }

    onCompanySelect(company: ICompany) {
        this.show = false;
    }

    onClick(event: Event) {
        event.preventDefault();
        this.show = !this.show;
        if (this.show) {
            setTimeout(() => {
                this.filterInput.nativeElement.focus();
            }, 150); // element is not visible imidietaly
        }
    }

    getCompaniesByFilter(): ICompany[] {
        if (!this.filterText) {
            return this.companies.slice(0, 20);
        }
        return this.companies
            .filter((company: ICompany) => company.name.toLowerCase().includes(this.filterText.toLowerCase()))
            .slice(0, 20);
    }

    @HostListener('document:click', ['$event']) @HostListener('document:keydown.escape', ['$event'])
    onClickOutsideTheElement(event: Event): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside && this.show) {
            this.show = false;
        }
    }

    findCompany(): ICompany | undefined {
        return this.companies.find(item => item.companyId === this.userService.getCompanyId())
    }

}
