<c-modal [visible]="modalVisible" (visibleChange)="handleModalChange($event)">
    <c-modal-header>
        <h5 cModalTitle>{{title}}</h5>
        <button (click)="toggleModal()" cButtonClose></button>
    </c-modal-header>
    <c-modal-body>
        <ng-content *ngIf="modalVisible"></ng-content>
        <div *ngIf="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
    </c-modal-body>
    <c-modal-footer>
        <button (click)="toggleModal()" cButton color="secondary">
            Zavřít
        </button>
        <button cButton color="primary" (click)="onSubmit()">Uložit</button>
    </c-modal-footer>
</c-modal>