import {Component, Input} from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {ICalorimeterDetail} from "../../../../interface/box-service/calorimeter-detail";
import {IHeatingBranchDataDetail} from "../../../../interface/heating-branch.interface";

@Component({
  selector: 'app-service-heating-branch',
  templateUrl: './service-heating-branch.component.html',
  styleUrl: 'service-heating-branch.component.scss',
  imports: [],
  standalone: true
})
export class ServiceHeatingBranchComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

    getDetail(): IHeatingBranchDataDetail | undefined {
        return this.service?.detail as IHeatingBranchDataDetail;
    }

    getTempOut(): number {
        return this.getDetail()?.tempOut || 0;
    }

    getTempRequired(): number {
        return this.getDetail()?.tempRequired || 0;
    }

}
