import { Component, Input, OnInit } from '@angular/core';
import { ChartjsComponent } from '@coreui/angular-chartjs';
import { NgIf } from '@angular/common';
import { UiService } from '../../../service/ui.service';
import type { ChartData, ChartOptions } from 'chart.js';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  ButtonDirective,
  ButtonGroupComponent,
  CardBodyComponent,
  CardComponent,
  ColComponent, FormCheckLabelDirective,
  RowComponent
} from '@coreui/angular-pro';
import { CustomDatePickerInputComponent } from '../custom-date-picker-input/custom-date-picker-input.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
  selector: 'app-custom-chart',
  standalone: true,
  imports: [
    ChartjsComponent,
    NgIf,
    ButtonGroupComponent,
    ColComponent,
    CardBodyComponent,
    RowComponent,
    CardComponent,
    ReactiveFormsModule,
    FormCheckLabelDirective,
    ButtonDirective,
    CustomDatePickerInputComponent,
    MatIcon,
    MatIconButton
  ],
  templateUrl: './custom-chart.component.html',
  styleUrl: './custom-chart.component.scss'
})
export class CustomChartComponent implements OnInit {

  @Input() serviceName?: string;
  @Input() graphData?: ChartData;
  @Input() chartOptions!: ChartOptions;

  temporaryHideGraphHack: boolean = false;

  formGroup: FormGroup;

  constructor(private fb: FormBuilder, private uiService: UiService) {
    this.formGroup = this.formGroup = this.fb.group({
      day: new Date()
    });
  }

  ngOnInit(): void {
    this.uiService.resized$.subscribe(() => {
      this.temporaryHideGraphHack = true;
      setTimeout(() => {
        this.temporaryHideGraphHack = false;
      }, 10);
    })
  }

  get dayControl(): FormControl {
    return this.formGroup.get('day') as FormControl;
  }

  goToPreviousDay() {
    const currentDate = this.formGroup.get('day')?.value as Date;
    if (currentDate) {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      this.formGroup.patchValue({ day: yesterday });
    }
  }

  goToNextDay() {
    const currentDate = this.formGroup.get('day')?.value as Date;
    if (currentDate) {
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(currentDate.getDate() + 1);
      this.formGroup.patchValue({ day: tomorrow });
    }
  }
}
