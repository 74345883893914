import {Component, Input, OnInit} from '@angular/core';
import {NgForOf} from "@angular/common";
import {UiService} from "../../../service/ui.service";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {DeleteModalComponent} from "../../shared/delete-modal/delete-modal.component";
import {MODAL_NAME_ADD_SERVICE_GROUP, MODAL_NAME_DELETE_SERVICE_GROUP, MODAL_NAME_EDIT_SERVICE_GROUP, MODAL_NAME_EDIT_USER} from "../../../interface/modal-type.interface";
import {NavigationService} from "../../../service/navigation.service";
import {IServiceGroup} from "../../../interface/service.group.interface";
import {ServiceGroupService} from "../../../service/service-group.service";
import {ActionService} from "../../../service/action.service";
import {AddServiceGroupComponent} from "./add-service-group/add-service-group.component";
import {DeleteServiceGroupComponent} from "./delete-service-group/delete-service-group.component";
import {EditServiceGroupComponent} from "./edit-service-group/edit-service-group.component";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'app-admin-service-groups',
  standalone: true,
    imports: [
        NgForOf,
        TableComponent,
        DeleteModalComponent,
        AddServiceGroupComponent,
        DeleteServiceGroupComponent,
        EditServiceGroupComponent,
        FormsModule,
    ],
  templateUrl: './admin-service-groups.component.html',
  styleUrl: './admin-service-groups.component.scss'
})
export class AdminServiceGroupsComponent implements OnInit{

    @Input() isPartOfMainPage = false;

    serviceGroups: IServiceGroup[] = [];
    serviceGroupToBeDeleted?: IServiceGroup;
    serviceGroupToBeEdited?: IServiceGroup;

    onlyRootGroups: boolean = false;

    config: ITableConfig[] = [
        {field: 'name', label: 'Jméno'},
        {field: 'orderNum', label: 'Pořadí'},
        {field: 'note', label: 'Poznámka'},
        {
            specialComponent: {
                type: 'edit',
                onClick: this.onServiceGroupEdit.bind(this)
            }
        },
        {
            specialComponent: {
                type: 'delete',
                onClick: this.onServiceGroupDelete.bind(this)
            }
        },
    ];

    constructor(
        private serviceGroupService: ServiceGroupService,
        private actionService: ActionService,
        private navigationService: NavigationService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private uiService: UiService) {
    }

    ngOnInit(): void {
        this.loadServiceGroups();
        if (!this.isPartOfMainPage) {
            this.adminBreadcrumbService.ofServiceGroups();
        }

        this.navigationService.rememberLastTabVisited();
    }

    onRowClicked(group: IServiceGroup): void {
        this.navigationService.toAdminServiceGroup(group.id);
    }

    onServiceGroupAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_ADD_SERVICE_GROUP);
    }

    onServiceGroupAddSubmitted() {
        this.loadServiceGroups();
        this.actionService.reloadServiceGroups();
    }

    onServiceGroupDelete(group: IServiceGroup): void {
        this.serviceGroupToBeDeleted = group;
        this.uiService.toggleModal(MODAL_NAME_DELETE_SERVICE_GROUP);
    }

    onServiceGroupDeleteConfirmed(group: IServiceGroup) {
        this.loadServiceGroups();
        this.actionService.reloadServiceGroups();
    }

    onServiceGroupEdit(group: IServiceGroup): void {
        this.serviceGroupToBeEdited = group;
        this.uiService.toggleModal(MODAL_NAME_EDIT_SERVICE_GROUP);
    }

    onServiceGroupEditConfirmed(group: IServiceGroup): void {
        this.loadServiceGroups();
        this.actionService.reloadServiceGroups();
    }

    get filteredServiceGroups(): IServiceGroup[] {
        return this.onlyRootGroups ? this.filterOnlyRootGroups() : this.serviceGroups;
    }

    private filterOnlyRootGroups(): IServiceGroup[] {
        return this.serviceGroups.filter(group => {
            return !group.parentServiceGroupId;
        });
    }

    private loadServiceGroups() {
        this.serviceGroupService.getMyServiceGroups().subscribe(
            (data: IServiceGroup[]): void => {
                this.serviceGroups = data;
            }
        );
    }


}
