import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {UserService} from "./service/user.service";
import {UiService} from "./service/ui.service";
import {MODAL_NAME_COMPANY_CONTEXT_CHANGED} from "./interface/modal-type.interface";

/**
 * Is used for the client administrator to manage his own company
 */
export const auth: CanActivateFn = (): boolean => {
    const user = inject(UserService);
    const router = inject(Router);

    if (!user.isAuthenticated()) {
        router.navigate(['login']);
        return false;
    }
    if (user.isAdmin()) {
        router.navigate(['is-admin']);
        return false;
    }

    return true;
};

/**
 * Use this one for the shared pages that uses both admin and client
 */
export const authOrAdmin: CanActivateFn = (): boolean => {
    const user = inject(UserService);
    const router = inject(Router);

    if (!user.isAuthenticated()) {
        router.navigate(['login']);
        return false;
    }

    return true;
};

/**
 * this is used for the obzor admin to see the setup like as he is the client
 */
export const adminAsUser: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): boolean => {
    const userService = inject(UserService);
    const uiService = inject(UiService);
    const router = inject(Router);

    // must be authentiacated
    if (!userService.isAuthenticated()) {
        router.navigate(['login']);
        return false;
    }

    // must be admin
    if (!userService.isAdmin()) {
        router.navigate(['not-admin']);
        return false;
    }

    // this part of code handles situation if link is shared and companyId inside the link is different than companyId setup in userContext
    const companyId = route.params['companyId'];
    const activeCompanyId = userService.getCompanyId();

    if (companyId && activeCompanyId !== companyId) {
        userService.changeCompanyId(companyId);
        setTimeout(() => {
            if (userService.isAdmin()) {
                uiService.toggleModal(MODAL_NAME_COMPANY_CONTEXT_CHANGED);
            }
        }, 100);
    }

    // ok can proceed
    return true;
};

export const partnerAdmin: CanActivateFn = (): boolean => {
    const auth = inject(UserService);
    const router = inject(Router);

    if (!auth.isAuthenticated()) {
        router.navigate(['login']);
        return false;
    }

    if (!auth.isAdminPartner()) {
        router.navigate(['not-admin']);
        return false;
    }
    return true;
};


export const admin: CanActivateFn = (): boolean => {
    const auth = inject(UserService);
    const router = inject(Router);

    if (!auth.isAuthenticated()) {
        router.navigate(['login']);
        return false;
    }

    if (!auth.isAdmin()) {
        router.navigate(['not-admin']);
        return false;
    }
    return true;
};
