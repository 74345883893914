import {Injectable} from '@angular/core';
import {UserService} from "./user.service";
import {NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject, filter, Observable} from "rxjs";
import {AdminBreadcrumbService} from "./breadcrumb/admin-breadcrumb.service";
import {LinkService} from "./link.service";

export type NAV_LINK = 'none' | 'dashboard' | 'companies' | 'companyDetail' | 'spotboxes' | 'calculation' | 'wgSettings' | 'service-groups' | 'service-group' | 'users' | 'consumption-points'

export interface INavItem {
    name: NAV_LINK,
    id?: string;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private userService: UserService, private router: Router, private adminBreadcrumbService: AdminBreadcrumbService, private linkService: LinkService) {
        this.listenToUrlChanges();
    }

    // --------------- CLIENT ---------------

    toClientServiceGroup(groupId: string) {
        Promise.resolve().then(() => this.router.navigate([this.linkService.clientServiceGroup(groupId)]));
    }

    toClientServiceSettings(serviceId: string, serviceType: string, groupId: string) {
        this.router.navigate([this.linkService.clientServiceSettings(serviceId, serviceType, groupId)]);
    }

    // --------------- GLOBAL ---------------
    toCompanies(): void {
        this.router.navigate([this.linkService.companies()]);
    }

    toCalculation() {
        this.router.navigate([this.linkService.calculation()]);
    }

    toWgSettings() {
        this.router.navigate([this.linkService.wgSettings()]);
    }

    // --------------- ADMIN ---------------

    // COMPANY
    adminCompanyDetail(companyId?: string): string {
        return `admin/company/${companyId}/detail`;
    }

    toAdminCompanyDetail(companyId?: string) {
        if (!companyId) {
            companyId = this.userService.getCompanyId();
        }
        this.router.navigate([this.adminCompanyDetail(companyId)]);
    }

    // DEVICES
    adminDevicesLink(): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/devices`;
    }

    toAdminDevices(): void {
        this.router.navigate([this.adminDevicesLink()]);
    }

    adminDeviceDetailLink(deviceId: string, companyId? : string) {
        if (!companyId) {
            companyId = this.userService.getCompanyId();
        }
        return `admin/company/${companyId}/device/${deviceId}`;
    }

    toAdminDeviceDetail(deviceId: string, companyId?: string) {
        this.router.navigate([this.adminDeviceDetailLink(deviceId, companyId)])
    }

    // SERVICE
    adminServiceLink(serviceId: string): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/service/${serviceId}`;
    }

    toAdminService(serviceId: string) {
        this.router.navigate([this.adminServiceLink(serviceId)])
    }

    // USERS
    toAdminUsers() {
        if(this.userService.isAdmin()) {
            this.router.navigate([this.linkService.adminUsers()]);
        } else {
            this.router.navigate([this.linkService.adminPartnerUsers()]);
        }
    }

    toAdminUserDetail(userId: string) {
        if (this.userService.isAdmin()) {
            this.router.navigate([this.linkService.adminUserDetail(userId)]);
        } else {
            this.router.navigate([this.linkService.adminPartnerDetail(userId)]);
        }
    }

    // CONSUMPTION POINTS
    toAdminConsumptionPoints() {
        this.router.navigate([this.linkService.adminConsumptionPoints()]);
    }

    toAdminConsumptionPointDetail(consumptionPointId: string) {
        this.router.navigate([this.linkService.adminConsumptionPointDetail(consumptionPointId)]);
    }

    toAdminConsumptionPeriodDetail(consumptionPointId: string, consumptionPeriodId: string) {
        this.router.navigate([this.linkService.adminConsumptionPeriodDetail(consumptionPointId, consumptionPeriodId)]);
    }

    // --------------- TO BE refactored ---------------
    toAdminServiceGroup(serviceGroupId: string): void {
        const companyId = this.userService.getCompanyId();
        this.router.navigate([`admin/company/${companyId}/service-groups/${serviceGroupId}`]);
    }

    toAdminServiceGroups(): void {
        const companyId = this.userService.getCompanyId();
        this.router.navigate([`admin/company/${companyId}/service-groups`]);
    }


    getLastTabVisitedForProperCompany(): string | null {
        if (!this.userService.isAdmin()) {
            // this feature is for admin only
            return null;
        }

        const url = sessionStorage.getItem('lastTabVisited');
        if (!url) {
            // no previous tab setup
            return null;
        }
        const array = url.split('/');
        const index = array.indexOf('company');
        if (index >= 0) {
            // there is a pattern /company/:companyId => replace :companyId
            const replaced = array[index + 1];
            return url.replace(replaced, this.userService.getCompanyId());
        }

        // no companyId to be changed => return just the url
        return url;
    }

    rememberLastTabVisited(): void {
        if (this.userService.isAdmin()) {
            sessionStorage.setItem('lastTabVisited', this.router.url);
        }
    }

    forgetLastTabVisited(): void {
        if (this.userService.isAdmin()) {
            sessionStorage.removeItem('lastTabVisited');
        }
    }


    private listenToUrlChanges(): void {
        this.router.events
            .pipe(
                filter((event): event is NavigationEnd => event instanceof NavigationEnd)
            )
            .subscribe((event: NavigationEnd) => {
                const url = event.urlAfterRedirects;
                this.setupActiveSidebarItem(event.urlAfterRedirects);
                if (!url?.startsWith('admin') && !url?.startsWith('global')) {
                    this.adminBreadcrumbService.clearBreadcrumbs();
                }
            });
    }

    private setupActiveSidebarItem(url: string): void {
        if (url.endsWith('/dashboard')) {
            this.navItemSubject.next({name: 'dashboard'});
        } else if (url.endsWith('/devices')) {
            this.navItemSubject.next({name: 'spotboxes'});
        } else if (url.endsWith('/service-groups')) {
            this.navItemSubject.next({name: 'service-groups'});
        } else if (url.endsWith('/users')) {
            this.navItemSubject.next({name: 'users'});
        } else if (url.endsWith('/calculation')) {
            this.navItemSubject.next({name: 'calculation'});
        } else if (url.endsWith('/companies')) {
            this.navItemSubject.next({name: 'companies'});
        } else if (url.endsWith('/consumption-points')) {
                this.navItemSubject.next({name: 'consumption-points'});
        } else if (url.endsWith('/detail')) {
            this.navItemSubject.next({name: 'companyDetail'});
        } else if (url.endsWith('/wg-settings')) {
            this.navItemSubject.next({name: 'wgSettings'});
        } else if (this.isServiceGroupSelected(url)) {
            // empty handles inside if :(
        } else {
            this.navItemSubject.next({name: 'none'});
        }

    }

    resendCurrentNavItem(): void {
        this.setupActiveSidebarItem(this.router.url);
    }

    private isServiceGroupSelected(url: string): boolean {
        if (url.length >= 51) {
            const tested = url.slice(-51);
            if (tested.startsWith('/service-group')) {
                const id = tested.slice(-36);
                this.navItemSubject.next({name: "service-group", id})
                return true;
            }
        }

        return false;
    }

    private navItemSubject: BehaviorSubject<INavItem> = new BehaviorSubject<INavItem>({name: 'none'});
    navItem$: Observable<INavItem> = this.navItemSubject.asObservable();

    toHome() {
        this.router.navigate(['/home'])
    }
}
