import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChartData, ChartDataset, ChartOptions} from 'chart.js';
import {UiService} from '../../../service/ui.service';
import {BoxGraphService} from '../../../service/box-graph.service';
import {
  ButtonDirective,
  ButtonGroupComponent,
  CardBodyComponent,
  CardComponent, ColComponent,
  FormCheckLabelDirective
} from '@coreui/angular-pro';
import {MatIcon} from '@angular/material/icon';
import {MatIconButton} from '@angular/material/button';
import {CustomDatePickerInputComponent} from '../custom-date-picker-input/custom-date-picker-input.component';
import {NgIf} from '@angular/common';
import {ChartjsComponent} from '@coreui/angular-chartjs';
import {MatFormField} from '@angular/material/form-field';
import {MatDatepicker, MatDatepickerInput, MatDatepickerToggle} from '@angular/material/datepicker';
import {MatInput} from '@angular/material/input';
import {debounceTime, distinctUntilChanged} from 'rxjs';
import {DateUtils} from '../../../util/date/date-utils';
import {IBoxGraphData} from '../../../interface/box-graph-data.interface';
import {CustomMonthPickerInputComponent} from '../custom-month-picker-input/custom-month-picker-input.component';
import {CustomYearPickerInputComponent} from '../custom-year-picker-input/custom-year-picker-input.component';

@Component({
  selector: 'app-custom-bar-chart',
  standalone: true,
  imports: [
    CardComponent,
    CardBodyComponent,
    MatIcon,
    MatIconButton,
    CustomDatePickerInputComponent,
    FormsModule,
    NgIf,
    ChartjsComponent,
    ReactiveFormsModule,
    ButtonGroupComponent,
    ButtonDirective,
    FormCheckLabelDirective,
    ColComponent,
    MatFormField,
    MatDatepickerInput,
    MatInput,
    MatDatepickerToggle,
    MatDatepicker,
    CustomMonthPickerInputComponent,
    CustomYearPickerInputComponent
  ],
  templateUrl: './custom-bar-chart.component.html',
  styleUrl: './custom-bar-chart.component.scss'
})
export class CustomBarChartComponent implements OnInit {

  @Input() serviceId?: string | null;
  @Input() title?: string;
  @Input() graphType?: string;

  formGroup: FormGroup;

  temporaryHideGraphHack: boolean = false;
  isCompareEnabled: boolean = false;

  graphData?: ChartData;
  chartOptions?: ChartOptions;

  modeGroup: FormGroup;

  constructor(private fb: FormBuilder, private uiService: UiService, private boxGraphService: BoxGraphService) {
    this.modeGroup = new FormGroup({
      mode: new FormControl('day')
    });

    this.formGroup = this.formGroup = this.fb.group({
      dateFromTo: this.fb.group({
        from: new Date(),
        to: new Date(),
      }),
      compareDateFromTo: this.fb.group({
        from: new Date(),
        to: new Date(),
      }),
      date: new Date(),
      compareDate: new Date()
    });
  }

  ngOnInit(): void {
    this.chartOptions = {
      scales: {
        first: {
          type: 'linear',
          position: 'left',
          beginAtZero: false,
          title: {
            display: true,
            text: this.title
          }
        }
      }
    };

    this.uiService.resized$.subscribe(() => {
      this.temporaryHideGraphHack = true;
      setTimeout(() => {
        this.temporaryHideGraphHack = false;
      }, 10);
    });

    this.dateControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.getGraph();
      });

    this.compareDateControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(() => {
        if (this.isCompareEnabled) {
          this.getCompareGraph();
        }
      });
  }

  get dateControl(): FormControl {
    return this.formGroup.get('date') as FormControl;
  }

  get compareDateControl(): FormControl {
    return this.formGroup.get('compareDate') as FormControl;
  }

  goToPreviousDate() {
    const currentDate = this.dateControl.value as Date;
    if (currentDate) {
      const previousDate = new Date(currentDate);
      if (this.getMode() == 'year') {
        previousDate.setFullYear(previousDate.getFullYear() - 1);
      } else if (this.getMode() == 'month') {
        previousDate.setMonth(previousDate.getMonth() - 1);
      } else {
        previousDate.setDate(currentDate.getDate() - 1);
      }
      this.dateControl.patchValue(previousDate);
    }
  }

  goToNextDate() {
    const currentDate = this.dateControl.value as Date;
    if (currentDate) {
      const nextDate = new Date(currentDate);
      if (this.getMode() == 'year') {
        nextDate.setFullYear(nextDate.getFullYear() + 1);
      } else if (this.getMode() == 'month') {
        nextDate.setMonth(nextDate.getMonth() + 1);
      } else {
        nextDate.setDate(currentDate.getDate() + 1);
      }
      this.dateControl.patchValue(nextDate);
    }
  }

  getGraph() {
    if (this.serviceId) {
      const currentDay: Date = this.dateControl.value;
      this.getServiceDetailGraph(currentDay, false);
    }
  }

  getCompareGraph() {
    if (this.serviceId) {
      const compareDataControl = this.formGroup.get('compareDate');
      if (compareDataControl) {
        const compareDate: Date = compareDataControl.value;
        this.getServiceDetailGraph(compareDate, true);
      }
    }
  }

  getServiceDetailGraph(date: Date, isCompareDate: boolean) {
    if (this.serviceId) {
      if (this.graphType == 'power') {
        this.boxGraphService.getServiceDetailPowerGraph(this.serviceId, DateUtils.getStartOfDay(date), DateUtils.getEndOfDay(date), this.getMode())
          .subscribe(data => {
            if (data) {
              this.processData(data, isCompareDate);
            }
          });
      } else if (this.graphType == 'volume') {
        this.boxGraphService.getServiceDetailVolumeGraph(this.serviceId, DateUtils.getStartOfDay(date), DateUtils.getEndOfDay(date), this.getMode())
          .subscribe(data => {
            if (data) {
              this.processData(data, isCompareDate);
            }
          });
      }  else if (this.graphType == 'energy') {
        this.boxGraphService.getServiceEnergyVolumeGraph(this.serviceId, DateUtils.getStartOfDay(date), DateUtils.getEndOfDay(date), this.getMode())
          .subscribe(data => {
            if (data) {
              this.processData(data, isCompareDate);
            }
          });
      }
    }
  }

  processData(iboxGraphData: IBoxGraphData[], isCompareDate: boolean) {
    if (isCompareDate) {
      this.graphData = this.addCompareGraphData(iboxGraphData);
    } else {
      this.graphData = this.transformGraphData(iboxGraphData);
    }
  }

  changeIsCompareEnabled() {
    const currentDate = this.dateControl.value as Date;
    if (this.isCompareEnabled) {
      if (currentDate) {
        const DateBeforeCurrentDate = this.getDateBeforeByMode(currentDate);
        this.formGroup.patchValue({date: currentDate, compareDate: DateBeforeCurrentDate});
      }
    } else {
      this.formGroup.patchValue({date: currentDate, compareDate: undefined});
      this.graphData = this.removeCompareDateGraphData();
    }
  }

  getDateBeforeByMode(currentDate: Date): Date {
    const newDate = new Date(currentDate);
    if (this.getMode() === 'year') {
      newDate.setFullYear(newDate.getFullYear() - 1);
    } else if (this.getMode() === 'month') {
      newDate.setMonth(newDate.getMonth() - 1);
    } else {
      newDate.setDate(newDate.getDate() - 1);
    }
    return newDate;
  }

  setMode(value: string): void {
    this.graphData = undefined;
    this.modeGroup.setValue({mode: value});
    this.isCompareEnabled = false;
    this.setDateByMode(value, this.dateControl);
    if (this.isCompareEnabled) {
      this.setDateByMode(value, this.compareDateControl, true);
    }
  }

  setDateByMode(mode: string, form: FormControl, isCompareDate?: boolean) {
    if (form) {
      const processingDate = isCompareDate ? this.getDateBeforeByMode(new Date()) : new Date();
      let date;
      if (mode === 'year') {
        date = DateUtils.formatToYear(processingDate);
      } else if (mode === 'month') {
        date = DateUtils.formatToMonth(processingDate);
      }

      form.patchValue(date ? new Date(date) : processingDate);
    }
    this.removeCompareDateGraphData();
  }

  getMode(): string {
    return this.modeGroup.get('mode')?.value;
  }

  transformGraphData(data: IBoxGraphData[]): ChartData {
    if (this.graphData && this.isCompareEnabled && this.graphData.datasets[1]) {
      const compareDataSet = this.graphData.datasets[1];
      return {
        ...this.graphData,
        datasets: [this.createGraphData(data, this.getGraphTitle(this.dateControl.value), 'rgba(0, 0, 255, 0.4)', 'blue'), compareDataSet]
      };
    } else {
      return {
        labels: data.map((date, index) => this.mapAxisX(date, index)),
        datasets: [this.createGraphData(data, this.getGraphTitle(this.dateControl.value), 'rgba(0, 0, 255, 0.4)', 'blue')]
      };
    }
  }

  addCompareGraphData(data: IBoxGraphData[]): ChartData {
    if (this.graphData) {
      const newData: ChartData = {
        ...this.graphData,
        datasets: [this.graphData.datasets[0]]
      };
      newData.datasets.push(this.createGraphData(data, this.getGraphTitle(this.compareDateControl.value), 'rgba(255, 0, 0, 0.4)', 'darkred'));
      return newData;
    } else {
      return this.transformGraphData(data);
    }
  }

  mapAxisX(data: IBoxGraphData, index: number): string {
    const mode = this.getMode();
    if (mode === 'year') {
      return this.formatToMonthForAxisX(new Date(data.dateTime));
    } else if (mode === 'month') {
      return this.formatToDayForAxisX(new Date(data.dateTime));
    } else {
      return `${index}:00 - ${(index + 1) % 24}:00`;
    }
  }

  getGraphTitle(date: Date): string {
    if (this.getMode() === 'year') {
      return DateUtils.formatToYear(date);
    } else if (this.getMode() === 'month') {
      return DateUtils.formatToMonth(date);
    } else {
      return DateUtils.formatToDay(date);
    }
  }

  formatToMonthForAxisX(date: Date): string {
    const month = (date.getMonth() + 1).toString().slice(-2);
    return `${month}`;
  }

  formatToDayForAxisX(date: Date): string {
    const day = date.getDate().toString().slice(-2);
    return `${day}`;
  }

  createGraphData(data: IBoxGraphData[], label: string, bgColor: string, borderColor: string): ChartDataset {
    return {
      yAxisID: 'first',
      label: label,
      data: data.map((item) => item.value),
      borderWidth: 1,
      backgroundColor: bgColor,
      borderColor: borderColor,
      type: 'bar'
    };
  }

  removeCompareDateGraphData(): ChartData | undefined {
    if (this.graphData) {
      return {
        ...this.graphData,
        datasets: [this.graphData.datasets[0]]
      };
    } else {
      return undefined;
    }
  }
}
