<div class="container">
    <h3>Odběrné období od: {{ period?.startPeriod | date }}</h3>

    <!-- user detail -->
    <div class="box-shadow">

        <fieldset class="pb-3">
            <h5>Obecné</h5>
            <div class="row">
                <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                    <app-text-input-readonly [label]="'Od'" [value]="period?.startPeriod"></app-text-input-readonly>
                </div>
                <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                    <app-text-input-readonly [label]="'Tarif'" [value]="period?.tariff"></app-text-input-readonly>
                </div>
            </div>
        </fieldset>

        <!-- FIX 1 -->
        <ng-container *ngIf="period?.tariff === 'FIX1'">
            <fieldset class="pb-3">

                <!-- Distribuce -->
                <h5>Distribuce</h5>
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                        <app-text-input-readonly [label]="'Paušál (Kč)'" [value]="period?.flatRateDistribution"></app-text-input-readonly>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                        <app-text-input-readonly [label]="'Distribuční sazba (Kč/MWh)'" [value]="period?.rate1VTDistributionMWh"></app-text-input-readonly>
                    </div>
                    <ng-container *ngIf="point?.voltageLevel === 'NN'">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Distribuční sazba'" [value]="period?.distributionTariff"></app-text-input-readonly>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Jistič'" [value]="period?.breaker"></app-text-input-readonly>
                        </div>
                    </ng-container>
                </div>

                <!-- Silová -->
                <fieldset class="pb-3">
                    <div class="row">
                        <h5>Silová</h5>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'V1/T1 (Kč/MWh)'" [value]="period?.rate1VTPowerMWh"></app-text-input-readonly>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Paušál (Kč)'" [value]="period?.flatRatePower"></app-text-input-readonly>
                        </div>
                    </div>
                </fieldset>
            </fieldset>
        </ng-container>


        <!-- FIX 2 -->
        <ng-container *ngIf="period?.tariff === 'FIX2'">
            <fieldset class="pb-3">

                <!-- Distribuce -->
                <h5>Distribuce</h5>
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                        <app-text-input-readonly [label]="'Paušál (Kč)'" [value]="period?.flatRateDistribution"></app-text-input-readonly>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                        <app-text-input-readonly [label]="'Distribuční sazba VT (Kč/MWh)'" [value]="period?.rate1VTDistributionMWh"></app-text-input-readonly>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                        <app-text-input-readonly [label]="'Distribuční sazba NT (Kč/MWh)'" [value]="period?.rate2NTDistributionMWh"></app-text-input-readonly>
                    </div>
                    <ng-container *ngIf="point?.voltageLevel === 'NN'">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Distribuční sazba'" [value]="period?.distributionTariff"></app-text-input-readonly>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Jistič'" [value]="period?.breaker"></app-text-input-readonly>
                        </div>
                    </ng-container>
                </div>

                <!-- Silová -->
                <fieldset class="pb-3">
                    <div class="row">
                        <h5>Silová</h5>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'V1/T1 (Kč/MWh)'" [value]="period?.rate1VTPowerMWh"></app-text-input-readonly>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'V2/T2 (Kč/MWh)'" [value]="period?.rate2NTPowerMWh"></app-text-input-readonly>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Paušál (Kč)'" [value]="period?.flatRatePower"></app-text-input-readonly>
                        </div>
                    </div>
                </fieldset>
            </fieldset>
        </ng-container>

        <!-- SPOT -->
        <ng-container *ngIf="period?.tariff === 'SPOT'">
            <fieldset class="pb-3">

                <!-- Distribuce -->
                <h5>Distribuce</h5>
                <div class="row">
                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                        <app-text-input-readonly [label]="'Paušál (Kč)'" [value]="period?.flatRateDistribution"></app-text-input-readonly>
                    </div>
                    <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                        <app-text-input-readonly [label]="'Distribuční sazba (Kč/MWh)'" [value]="period?.spotDistributionMwh"></app-text-input-readonly>
                    </div>
                    <ng-container *ngIf="point?.voltageLevel === 'NN'">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Distribuční sazba'" [value]="period?.distributionTariff"></app-text-input-readonly>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Jistič'" [value]="period?.breaker"></app-text-input-readonly>
                        </div>
                    </ng-container>
                </div>

                <!-- Silová -->
                <fieldset class="pb-3">
                    <div class="row">
                        <h5>Silová</h5>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Přírážka obchodníka (Kč)'" [value]="period?.spotPowerMWh"></app-text-input-readonly>
                        </div>
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                            <app-text-input-readonly [label]="'Paušál (Kč)'" [value]="period?.flatRatePower"></app-text-input-readonly>
                        </div>
                    </div>
                </fieldset>
            </fieldset>
        </ng-container>



        <button type="button" class="btn btn-primary mr-2" (click)="onConsumptionPeriodEdit($event)">Upravit</button>
        <button type="button" class="btn btn-danger" (click)="onConsumptionPeriodDelete($event)">Smazat</button>
    </div>

    <!-- modals   -->
    <app-delete-consumption-period
            [period]="period"
            (deleted)="onPeriodDeleteConfirmed($event)">
    </app-delete-consumption-period>
</div>