import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {
    GetGraphDataResponse,
    GetActualPriceResponse, OTEHourResponse, GetGraphDataWithPowerRequest,
} from '../api';
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";
import {IPowerData} from "../interface/power-data.interface";
import {IActualPrice} from "../interface/actual-price.interface";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root',
})
export class temporaryOteApiService {
  constructor(private apiService: ApiService, private userService: UserService) {}

  public getTGraphData(date: string): Observable<IPowerData[]> {
      const body: GetGraphDataWithPowerRequest = {date, companyId: this.userService.getCompanyId() };

      return this.apiService
          .post("/ote/get-graph-data-with-power", body)
          .pipe(
              map((res: GetGraphDataResponse) => {
                  return res.list!.map((item: OTEHourResponse) => {
                      return {
                          hour: item.hour,
                          priceCZK: item.priceCZK,
                          priceLevel: item.priceLevel,
                          controlledPower: item.controlledPower,
                          allPower: item.allPower,
                          forDate: res.dateStr,
                      } as IPowerData;
                  });
              })
          );
  }

    public getActualPrice(): Observable<IActualPrice> {
        return this.apiService
            .post("/ote/get-actual-price", {})
            .pipe(
                map((res: GetActualPriceResponse) => {
                    return {
                        actualPriceCZK: res.actualPriceCZK,
                        level: res.level
                    } as IActualPrice;
                })
            );
    }
}
