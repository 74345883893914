<ng-container [ngSwitch]="getType()">
    <th *ngSwitchCase="'delete'" style="width: 32px" class="align-bottom"></th>
    <th *ngSwitchCase="'edit'" style="width: 32px" class="align-bottom"></th>
    <th *ngSwitchCase="'minus'" style="width: 32px" class="align-bottom"></th>
    <th *ngSwitchCase="'status'" style="width: 10px" class="align-bottom"></th>
    <th *ngSwitchCase="'standard'" class="align-bottom">{{ config.label }}
        <app-table-header-sort [config]="config" [sortByField]="sortByField" (sortByFieldSelected)="onSortByFieldSelected($event)"></app-table-header-sort>
    </th>
    <th *ngSwitchCase="'date'" class="align-bottom">{{ config.label }}
        <app-table-header-sort [config]="config" [sortByField]="sortByField" (sortByFieldSelected)="onSortByFieldSelected($event)"></app-table-header-sort>
    </th>
    <th *ngSwitchCase="'serviceType'" class="align-bottom">{{ config.label }}</th>
</ng-container>
