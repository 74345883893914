import {Component, Input} from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IAssociatedPowerMeterDetail} from "../../../../interface/box-service/associated-power-meter-detail";

@Component({
  selector: 'app-service-associated-power-meter',
  templateUrl: './service-associated-power-meter.component.html',
  styleUrl: 'service-associated-power-meter.component.scss',
  imports: [],
  standalone: true
})
export class ServiceAssociatedPowerMeterComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

  getDetail(): IAssociatedPowerMeterDetail | undefined {
      return this.service?.detail as IAssociatedPowerMeterDetail;
  }

  getCurrentActPower(): number {
    return this.getDetail()?.currentPower || 0;
  }

  getConsumptionLastMonth(): number {
      return this.getDetail()?.sumPowerLo || 0;
  }
}
