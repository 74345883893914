<div class="form-group mb-2">
  <!-- label-->
  <label *ngIf="!hideLabel" [for]="field">
    <strong>{{ label }}</strong>
    <span *ngIf="required" style="color: red">*</span>
  </label>

  <!-- File Input -->
  <form [formGroup]="form">
    <input type="file" (change)="onFileChange($event)" accept=".xlsx, .xls"/>
  </form>

  <!-- errors -->
  <ng-container *ngIf="formControl().invalid && formControl().touched">
    <div *ngIf="formControl().errors?.['required']" class="error">Pole {{ label.toLowerCase() }} je povinné.</div>
  </ng-container>
</div>
