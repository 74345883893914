import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_EDIT_BOX_DEVICE} from "../../../../interface/modal-type.interface";
import {IBoxDevice} from "../../../../interface/box-device.interface";
import {BoxDeviceService} from "../../../../service/box-device.service";

@Component({
  selector: 'app-admin-edit-device',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent
    ],
  templateUrl: './admin-edit-device.component.html',
  styleUrl: './admin-edit-device.component.scss'
})
export class AdminEditDeviceComponent implements OnInit, OnChanges {
    @Input() device?: IBoxDevice;
    @Output() deviceEdited: EventEmitter<IBoxDevice> = new EventEmitter<IBoxDevice>();

    form!: FormGroup;

    modalName = MODAL_NAME_EDIT_BOX_DEVICE // should be unique
    modalTitle = 'Upravit EM box';

    constructor(
        private fb: FormBuilder,
        private boxDeviceService: BoxDeviceService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['device']) {
            const device: IBoxDevice = changes['device'].currentValue as IBoxDevice;
            if (device) {
                this.form = this.fb.group({
                    name: [device.name, Validators.required],
                    note: [device.note],
                    wgIpAddress: [device.wgIpAddress],
                    wgApiKey: [device.wgApiKey],
                })
            }
        }
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            note: [''],
            wgIpAddress: [''],
            wgApiKey: [''],
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            const boxDevice: IBoxDevice = {...this.device, ...this.form.value} as IBoxDevice;

            this.boxDeviceService.editDevice(boxDevice)
                .subscribe(() => {
                    this.deviceEdited.emit(boxDevice);
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`EM box ${boxDevice.name} úspěšně upraven.`)
                });
        }
    }
}
