<div class="dropdown pb-3">
    <button class="btn btn-dark dropdown-toggle" (click)="onClick($event)" type="submit">{{buttonText}}</button>
    <ul class="dropdown-menu" [ngClass]="{ 'show': showDropDown }">
        <li class="px-1">
            <input type="text" class="form-control" placeholder="Filtrovat..." [(ngModel)]="filterText" #myFilterInput>
        </li>
        <li>
            <hr class="dropdown-divider">
        </li>
        <li *ngFor='let item of getItems()'>
            <app-dropdown-item [item]="item" (itemSelected)="onItemSelected($event)"></app-dropdown-item>
        </li>
    </ul>
</div>