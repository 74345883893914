/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AggregatorResponse { 
    aggregatorId: string;
    name: string;
    status: AggregatorResponse.StatusEnum;
    actualPower: number;
}
export namespace AggregatorResponse {
    export type StatusEnum = 'running' | 'error' | 'disabled';
    export const StatusEnum = {
        Running: 'running' as StatusEnum,
        Error: 'error' as StatusEnum,
        Disabled: 'disabled' as StatusEnum
    };
}


