import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_EDIT_COMPANY} from "../../../../interface/modal-type.interface";
import {ICompany} from "../../../../interface/company.interface";
import {CompanyService} from "../../../../service/company.service";

@Component({
  selector: 'app-edit-company',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        FormModalComponent
    ],
  templateUrl: './edit-company.component.html',
  styleUrl: './edit-company.component.scss'
})
export class EditCompanyComponent implements OnInit, OnChanges {
    @Input() company?: ICompany;
    @Output() companyEdited: EventEmitter<ICompany> = new EventEmitter<ICompany>();

    form!: FormGroup;

    modalName = MODAL_NAME_EDIT_COMPANY // should be unique
    modalTitle = 'Upravit firmu';

    constructor(
        private fb: FormBuilder,
        private companyService: CompanyService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['company']) {
            const company: ICompany = changes['company'].currentValue as ICompany;
            if (company) {
                this.form = this.fb.group({
                    name: [company.name, Validators.required],
                    address: [company.address],
                    ic: [company.ic],
                    dic: [company.dic],
                    email: [company.email, Validators.email],
                    phone: [company.phone],
                })
            }
        }
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            address: [''],
            ic: [''],
            dic: [''],
            email: ['', Validators.email],
            phone: [''],
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const company: ICompany = {...this.company, ...this.form.value} as ICompany;

            this.companyService.editCompany(company)
                .subscribe(() => {
                    this.companyEdited.emit(company);
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Firma ${company.name} úspěšně upravena.`)
                });
        }
    }
}
