import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {IServiceGroupForSidebar} from "../../../interface/service.group.interface";
import {NgClass, NgIf} from "@angular/common";
import {SideBarGroupsComponent} from "../side-bar-groups/side-bar-groups.component";
import {UiService} from "../../../service/ui.service";
import {INavItem, NavigationService} from "../../../service/navigation.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-side-bar-group',
  standalone: true,
    imports: [
        NgIf,
        SideBarGroupsComponent,
        NgClass,
    ],
  templateUrl: './side-bar-group.component.html',
  styleUrl: './side-bar-group.component.scss'
})
export class SideBarGroupComponent implements OnInit, OnDestroy{

    @Input() group?: IServiceGroupForSidebar;
    isActive: boolean = false;

    private navItemSubscription?: Subscription;

    constructor(private uiService: UiService, private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.navItemSubscription = this.navigationService.navItem$.subscribe((item: INavItem) => {
            this.isActive = item.name === 'service-group' && item.id === this.group?.id;
        });
    }

    ngOnDestroy(): void {
        if (this.navItemSubscription) {
            this.navItemSubscription.unsubscribe();
        }
    }

    hasChildren(): boolean {
        if (!this.group) {
            return false;
        }

        return this.group?.children?.length > 0;
    }

    getChildren(): IServiceGroupForSidebar[] {
        if (this.hasChildren()) {
            return this.group!.children;
        }

        return [];
    }


    onGroupClick(event: Event) {
        event.preventDefault();
        if (this.hasChildren()) {
            if (!this.group?.opened) {
                Promise.resolve().then(() => this.uiService.setSidebarServiceGroupOpened(this.group!.id));
            } else {
                this.uiService.setSidebarServiceGroupClosed(this.group!.id);
            }
        } else {
            this.uiService.closeBurger();
            this.navigationService.toClientServiceGroup(this.group!.id);
        }
    }
}
