import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {ServiceGroupService} from "../../service/service-group.service";
import {IServiceGroupForSidebar} from "../../interface/service.group.interface";
import {UiService} from "../../service/ui.service";
import {NavigationService} from "../../service/navigation.service";
import {ExpiredKeyService} from "../../service/expired-key.service";

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [],
    templateUrl: './home.component.html',
    styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {

    constructor(private router: Router, private userService: UserService, private serviceGroupService: ServiceGroupService, private uiService: UiService, private navigationService: NavigationService, private expiredKeyService: ExpiredKeyService) {
    }

    ngOnInit(): void {
        const redirectAfterExpiredApiKey = this.expiredKeyService.getRedirectAfterExpiredApiKey()

        //I remember specific url where the user can be redirected after company switch
        const previousTabRemembered = this.navigationService.getLastTabVisitedForProperCompany();
        const isAdmin = this.userService.isAdmin();

        if (redirectAfterExpiredApiKey) {
            this.expiredKeyService.forgetRedirectAfterExpiredApiKey();
            this.router.navigate([redirectAfterExpiredApiKey]);
        } else if(previousTabRemembered && isAdmin) {
            this.router.navigate([previousTabRemembered]);
        } else if (isAdmin) {
            this.navigationService.toCompanies();
        } else if (this.userService.isAdminPartner()) {
            this.router.navigate(['/dashboard']);
        } else if (this.userService.isPartnerUser()) {
            this.serviceGroupService.getServiceGroupsForSidebar().subscribe((data: IServiceGroupForSidebar[]) => {
                const group = this.findFirstGroupWithoutChildren(data)
                if (group) {
                    this.navigationService.toClientServiceGroup(group.id);
                } else {
                    this.router.navigate([`/my-profile`]);
                }
            })
        }
    }

    private findFirstGroupWithoutChildren(groups: IServiceGroupForSidebar[]): IServiceGroupForSidebar | null {
        if (!groups || groups.length === 0) {
            return null;
        }
        try {
           return this.findFirstGroupWithoutChildrenRecursive(groups);
        } catch (error) {
            console.error(error);
            return null;
        }
    }

    private findFirstGroupWithoutChildrenRecursive(groups: IServiceGroupForSidebar[], callTimes: number = 0): IServiceGroupForSidebar | null {

        if (callTimes > 100) {
            throw new Error('Never ending recursion. Group without children not found.');
        }
        for (const group of groups) {
            if (!group.children || group.children.length === 0) {
                return group;
            }

            // Recursively check the children of the current group
            const childWithoutChildren = this.findFirstGroupWithoutChildrenRecursive(group.children, callTimes + 1);
            if (childWithoutChildren) {
                return childWithoutChildren; // Return the first found group without children in the recursive call
            }
        }

        return null; // Return undefined if all groups and their children have children
    }

}
