import {Injectable} from '@angular/core';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ExpiredKeyService {

    constructor(private router: Router) {
    }

    setupRedirectAfterExpiredApiKey() {
        sessionStorage.setItem('redirect', this.router.url);
    }

    getRedirectAfterExpiredApiKey(): string | null {
        if (sessionStorage.getItem('redirect')) {
            return sessionStorage.getItem('redirect');
        }
        return null;
    }

    forgetRedirectAfterExpiredApiKey() {
        sessionStorage.removeItem('redirect');
    }


}
