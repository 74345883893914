import {Component, HostListener, OnInit} from '@angular/core';
import {ScreenSize, UiService} from './service/ui.service';
import {UserService} from "./service/user.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    authenticated: boolean = false;
    currentScreenSize: ScreenSize = 'sm';

    @HostListener('window:resize', ['$event'])
    onResize(event?: Event): void {
        this.uiService.updateScreenSize(window.innerWidth);
    }

    constructor(private userService: UserService, private uiService: UiService) {
    }

    ngOnInit(): void {
        this.uiService.screenSize$.subscribe((size) => {
            this.currentScreenSize = size;
        });
        this.onResize();

        this.userService.authenticated$.subscribe((authenticated) => {
            this.authenticated = authenticated;
        });

    }
}
