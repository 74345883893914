import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import { ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {DeleteModalComponent} from "../../../shared/delete-modal/delete-modal.component";
import {UiService} from "../../../../service/ui.service";
import {MODAL_NAME_DELETE_CONSUMPTION_PERIOD} from "../../../../interface/modal-type.interface";
import {IConsumptionPeriod} from "../../../../interface/consumption-period.interface";
import {ConsumptionPointService} from "../../../../service/consumption-point.service";
import {DatePipe} from "../../../../util/pipe/date.pipe";

@Component({
  selector: 'app-delete-consumption-period',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        DeleteModalComponent,
        DatePipe
    ],
  templateUrl: './delete-consumption-period.component.html',
  styleUrl: './delete-consumption-period.component.scss'
})
export class DeleteConsumptionPeriodComponent {

    @Input() period?: IConsumptionPeriod;
    @Output() deleted: EventEmitter<IConsumptionPeriod> = new EventEmitter<IConsumptionPeriod>();

    modalName = MODAL_NAME_DELETE_CONSUMPTION_PERIOD; // should be unique
    modalTitle = 'Smazat období?';

    constructor(private consumptionPointService: ConsumptionPointService, private uiService: UiService) {
    }

    onConfirm() {
        this.consumptionPointService.deletePeriod(this.period?.consumptionPointPeriodId!).subscribe(() => {
            this.deleted.emit(this.period);
            this.uiService.toggleModal(this.modalName);
            const from = new DatePipe().transform(this.period?.startPeriod);
            this.uiService.setSuccessAlert(`Období ${from} úspěšně smazáno.`);
        })
    }

}
