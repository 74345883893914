// users
export const MODAL_NAME_EDIT_USER = 'edit-user';
export const MODAL_NAME_ADD_USER = 'add-user';
export const MODAL_NAME_DELETE_USER = 'delete-user';
export const MODAL_NAME_CHANGE_PASSWORD = 'change-password';

// company
export const MODAL_NAME_EDIT_COMPANY = 'edit-company';
export const MODAL_NAME_ADD_COMPANY = 'add-company';
export const MODAL_NAME_DELETE_COMPANY = 'delete-company';

// box device
export const MODAL_NAME_ADD_BOX_DEVICE = 'add-box-device';
export const MODAL_NAME_EDIT_BOX_DEVICE = 'edit-box-device';
export const MODAL_NAME_DELETE_BOX_DEVICE = 'delete-box-device';

// service group
export const MODAL_NAME_DELETE_SERVICE_GROUP = 'delete-service-group';
export const MODAL_NAME_ADD_SERVICE_GROUP = 'add-service-group';
export const MODAL_NAME_EDIT_SERVICE_GROUP = 'edit-service-group';
export const MODAL_NAME_EDIT_SERVICE_GROUP_2 = 'edit-service-group-2';

// service
export const MODAL_NAME_DELETE_SERVICE = 'delete-service';
export const MODAL_NAME_ADD_SERVICE = 'add-service';
export const MODAL_NAME_EDIT_SERVICE = 'edit-service';

// consumption-points
export const MODAL_NAME_EDIT_CONSUMPTION_POINT = 'edit-consumption-point';
export const MODAL_NAME_ADD_CONSUMPTION_POINT = 'add-consumption-point';
export const MODAL_NAME_DELETE_CONSUMPTION_POINT = 'delete-consumption-point';

// consumption-periods
export const MODAL_NAME_EDIT_CONSUMPTION_PERIOD = 'edit-consumption-period';
export const MODAL_NAME_ADD_CONSUMPTION_PERIOD = 'add-consumption-period';
export const MODAL_NAME_DELETE_CONSUMPTION_PERIOD = 'delete-consumption-period';

// other
export const MODAL_NAME_UPLOAD_FILE = 'upload-file';
export const MODAL_NAME_COMPANY_CONTEXT_CHANGED = 'company-context-changed';
