import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {IUser} from "../../../../interface/user.interface";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_EDIT_USER} from "../../../../interface/modal-type.interface";
import {getUserRoleOptions} from "../../../../util/translate/translate-key";

@Component({
  selector: 'app-admin-edit-user',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent
    ],
  templateUrl: './admin-edit-user.component.html',
  styleUrl: './admin-edit-user.component.scss'
})
export class AdminEditUserComponent implements OnInit, OnChanges {
    @Input() user?: IUser;
    @Output() userEdited: EventEmitter<IUser> = new EventEmitter<IUser>();

    form!: FormGroup;

    modalName = MODAL_NAME_EDIT_USER // should be unique
    modalTitle = 'Upravit uživatele';

    roleOptions: ISelectOption[] = getUserRoleOptions(this.userService.getUserContext().role)

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['user']) {
            const user: IUser = changes['user'].currentValue as IUser;
            if (user) {
                this.form = this.fb.group({
                    username: [user.username, Validators.required],
                    email: [user.email, [Validators.required, Validators.email]],
                    role: [user.role, [Validators.required]],
                })
            }
        }
    }

    private initForm(): void {
        this.form = this.fb.group({
            username: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            role: ['', [Validators.required]] ,
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            // when userName not changed and not sent to backend with same value it failed with 400
            const userNameChanged = this.form.value.username !== this.user?.username;
            const user: IUser = {...this.user, ...this.form.value} as IUser;

            this.userService.editUser(user, userNameChanged)
                .subscribe(() => {
                    this.userEdited.emit(user);
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Uživatel ${user.username} úspěšně upraven.`)
                });
        }
    }
}
