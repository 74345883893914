<app-client-breadcrumb></app-client-breadcrumb>
<div class="container">
  <div class="container-auto">
    <div class="text-center row1">
      <h3>Nastavení služby {{service?.name}}</h3>
    </div>
    <div class="row2">
        <ng-content></ng-content>
    </div>
    <div class="row d-flex justify-content-center row3">
      <button (click)="redirectBack()" class="btn btn-primary btn-back mr-2">Zpět</button>
      <button (click)="useSettings()" [disabled]="!form?.touched" class="btn btn-primary btn-use">Použít
      </button>
    </div>
  </div>
</div>
