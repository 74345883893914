import {Component, OnInit} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {DatePickerComponent} from "@coreui/angular-pro";
import {BreadcrumbService} from "../../../service/breadcrumb/breadcrumb.service";
import {RouterLink} from "@angular/router";
import {Breadcrumb} from "../../../service/breadcrumb/Breadcrumb";

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    DatePickerComponent,
    RouterLink,
    NgForOf
  ],
  templateUrl: './breadcrumb.component.html',
  styleUrl: './breadcrumb.component.scss'
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: Breadcrumb[] = [];

  constructor(private breadcrumbService: BreadcrumbService) {
  }

  ngOnInit() {
    this.breadcrumbService.breadcrumbs$.subscribe(breadcrumbs => {
      this.breadcrumbs = breadcrumbs;
    });
  }
}
