<c-card class="container-width">
  <c-card-body>
    <h4 class="card-title mb-0" id="service">{{serviceName}}</h4>
    <div class="row mt-3">
      <button class="custom-arrow-button" mat-icon-button (click)="goToPreviousDay()" aria-label="Day Before">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <app-custom-date-picker-input class="col-md-2" [form]="formGroup" [label]="'Den'" [field]="'day'" [hideLabel]="true"></app-custom-date-picker-input>
      <button class="custom-arrow-button" mat-icon-button (click)="goToNextDay()" aria-label="Day After">
        <mat-icon>arrow_forward</mat-icon>
      </button>
    </div>
    <c-chart *ngIf="!temporaryHideGraphHack" [data]="graphData" [options]="chartOptions"></c-chart>
  </c-card-body>
</c-card>
