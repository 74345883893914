import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IComponentType, ISortByField, ITableConfig} from "../table.component";
import {NgSwitch, NgSwitchCase} from "@angular/common";
import {TableHeaderSortComponent} from "../table-header-sort/table-header-sort.component";

@Component({
  selector: 'app-table-header',
  standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
        TableHeaderSortComponent
    ],
  templateUrl: './table-header.component.html',
  styleUrl: './table-header.component.scss'
})
export class TableHeaderComponent {

    @Input() config: ITableConfig = {};
    @Input() sortByField: ISortByField | null = null;
    @Output() sortByFieldSelected = new EventEmitter<ISortByField | null>();

    getType(): IComponentType {
        return this.config.specialComponent?.type || 'standard';
    }

    onSortByFieldSelected(event: ISortByField | null) {
        this.sortByFieldSelected.emit(event);
    }
}
