<div class="pump-heating-branch-wrapper" [style.min-height.px]="569">
    <div class="branch-header" [ngClass]="data.freshData ? 'fresh-data' : 'old-data'">
        <div class="d-flex justify-content-between">
            <div>
            {{
                data.name ? data.name : '&nbsp;'
            }}
            </div>
            <div class="font-italic" *ngIf="!data.freshData" style="font-style: italic">
                stará data
            </div>
        </div>
    </div>
    <div class="d-flex justify-content-center">
        <img
                (dblclick)="toggleTextData()"
                ngSrc="../../../../assets/img/pump-heating-branch.png"
                alt="TUV"
                class="background-image"
                [style.width.px]="240"
                height="303"
                width="219"
        />
    </div>
</div>

<div class="temp-room">
    {{
        data.serviceDataDetail ? data.serviceDataDetail.tempRoom + " °C" : "-"
    }}
</div>

<div class="temp-room-header">
    teplota v místnosti
</div>

<div *ngIf="showTextData" class="card" [style.width.px]="350">
    <div class="card-body">
        <div>
            status: {{ data.serviceDataDetail ? data.serviceDataDetail.status : "-" }}
        </div>
        <div>
            tempRoom:
            {{
                data.serviceDataDetail ? data.serviceDataDetail.tempRoom + " °C" : "-"
            }}
        </div>
        <div>
            circulationPump:
            {{
                data.serviceDataDetail ? data.serviceDataDetail.circulationPump : "-"
            }}
        </div>
    </div>
</div>


