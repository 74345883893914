import { Component, Input } from '@angular/core';
import { IHeatingBranchData } from '../../../interface/heating-branch.interface';
import { IPumpHeatingBranchData } from '../../../interface/pump-heating-branch.interface';
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-pump-heating-branch',
  standalone: true,
    imports: [
        NgOptimizedImage,
        NgIf,
        NgClass
    ],
  templateUrl: './pump-heating-branch.component.html',
  styleUrl: './pump-heating-branch.component.scss',
})
export class PumpHeatingBranchComponent {
  @Input() data!: IPumpHeatingBranchData;
    showTextData: boolean = false;

    toggleTextData(): void {
        this.showTextData = !this.showTextData;
    }
}
