<div class="container">
    <div class="d-flex flex-column align-items-center mt-4">
        <div class="user-container pt-3 my-md-2 my-1">
            <div class="px-3">
                <h3>Uživatel</h3>
                <p><strong>Jméno: </strong> {{ this.userContext?.username }}</p>
                <p><strong>Role: </strong> {{ this.userContext?.role }}</p>
                <p *ngIf="!pwdChangeInProgress" class="pwd-change"><a (click)="onPwdChange($event)" href="#" class="link-underline-dark">Změnit heslo</a></p>
                <app-password-change *ngIf="pwdChangeInProgress"
                                     (passwordChanged)="onPwdChangeSuccess()"
                                     (passwordChangeCancelled)="onPwdChangeCancel()"
                                     >
                </app-password-change>
                <div class="alert alert-success" role="alert" *ngIf="passwordChangedSuccess">
                    Heslo bylo změněno
                </div>
            </div>
        </div>


        <div class="company-container pt-3 my-md-2 my-1">
            <div class="px-3">
                <h3>Firma</h3>
                <p><strong>Jméno: </strong> {{ this.company?.name }}</p>
                <p><strong>Adresa:</strong> {{ this.company?.address }}</p>
                <p><strong>Email:</strong> {{ this.company?.email }}</p>
                <p><strong>Telefon:</strong> {{ this.company?.phone }}</p>
                <p><strong>DIČ:</strong> {{ this.company?.dic }}</p>
                <p><strong>IČ:</strong> {{ this.company?.ic }}</p>
            </div>
        </div>

    </div>
</div>
