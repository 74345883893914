import {GetUserResponse, GetUsersResponse, LoginResponse, UserResponse} from "../api";
import {IUserContext} from "../interface/user.context.interface";
import {IUser} from "../interface/user.interface";

export class UserMapper {

    static mapLoginResponse(res: LoginResponse, userName: string): IUserContext {
        return {
            apikey: res.apiKey,
            username: userName,
            myRealCompanyId: res.companyId,
            companyId: res.companyId,
            role: res.role,
        } as IUserContext
    }

    static mapUsers(res: GetUsersResponse): IUser[] {
        if (!res || !res.list) {
            return [];
        }

        return res.list.map((user: UserResponse) => {
            return UserMapper.mapUser(user);
        })
    }

    static mapUserDetail(res: GetUserResponse): IUser{
        return {
            userId: res.userId,
            email: res.email,
            companyId: res.companyId,
            username: res.username,
            role: res.role,
        } as IUser;
    }

    static mapUser(res: UserResponse): IUser{
        return {
            userId: res.userId,
            email: res.email,
            companyId: res.companyId,
            username: res.username,
            role: res.role,
        } as IUser;
    }
}