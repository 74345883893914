import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ApiService} from "./api.service";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HealthService {

  constructor(private apiService: ApiService) {
  }

  getBackendVersion(): Observable<string> {
    return this.apiService
      .get("/health/get-version", {}, new HttpParams(), 'string')
      .pipe(map((res: string) => res));
  }

}
