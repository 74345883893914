export enum IBoxServiceType {
  HeatingBranch = 'heatingBranch',
  PumpHeatingBranch = 'pumpHeatingBranch',
  ValveHeatingBranch = 'valveHeatingBranch',
  HotWaterTank = 'hotWaterTank',
  PowerMeter = 'powerMeter',
  AssociatedPowerMeter = 'associatedPowerMeter',
  WaterMeterHot = 'waterMeterHot',
  WaterMeterCold = 'waterMeterCold',
  Calorimeter = 'calorimeter',
}

