<ng-container *ngIf="authenticated">
    <app-side-bar></app-side-bar>
</ng-container>
<div class="my-md-content-wrapper">
    <app-loader></app-loader>
    <app-admin-breadcrumb></app-admin-breadcrumb>
    <router-outlet/>
    <app-alert-container></app-alert-container>
</div>
<app-error-modal></app-error-modal>
<app-change-company-context-modal></app-change-company-context-modal>


