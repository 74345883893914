import {CompanyResponse, GetCompaniesResponse} from "../api";
import {ICompany} from "../interface/company.interface";

export class CompanyMapper {

    static mapCompany(res: CompanyResponse): ICompany {
        return {
            companyId: res.companyId!,
            dic: res.dic,
            name: res.name,
            address: res.address,
            email: res.email,
            phone: res.phone,
            ic: res.ic
        } as ICompany;
    }

    static mapCompanies(res: GetCompaniesResponse) {
        if (!res || !res.list) {
            return [];
        }

        return res.list.map((company: CompanyResponse) => {
            return CompanyMapper.mapCompany(company);
        })
    }
}