import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_CHANGE_PASSWORD} from "../../../../interface/modal-type.interface";
import {IUser} from "../../../../interface/user.interface";
import {PasswordInputComponent} from "../../../shared/password-input/password-input.component";

@Component({
  selector: 'app-admin-change-password',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        PasswordInputComponent
    ],
  templateUrl: './admin-change-password.component.html',
  styleUrl: './admin-change-password.component.scss'
})
export class AdminChangePasswordComponent implements OnInit{
    @Output() userCreated: EventEmitter<void> = new EventEmitter<void>();
    @Input() user?: IUser;

    form!: FormGroup;

    modalName = MODAL_NAME_CHANGE_PASSWORD // should be unique
    modalTitle = 'Změna hesla';

    passwordsMismatch: boolean = false;

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            password1: ['', [Validators.required, Validators.minLength(5)]],
        })
    }

    onClose(): void {
        this.initForm();
        this.passwordsMismatch = false;
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {password1, password2} = this.form.value;

            // this.passwordsMismatch = password1 !== password2;
            // if (this.passwordsMismatch) {
            //     return;
            // }

            this.userService.changePasswordForUser(password1, this.user?.userId!)
                .subscribe(() => {
                    this.userCreated.emit();
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Heslo uživatele ${this.user?.username} úspěšně změněno.`);
                    this.initForm();
                });
        }
    }
}
