import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BuildTimeService {
    private buildTimeUrl = 'assets/build-time.json';

    constructor(private http: HttpClient) {}

    getBuildTime(): Observable<{ buildTime: string }> {
        const headers = new HttpHeaders({
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
        });

        return this.http.get<{ buildTime: string }>(this.buildTimeUrl, { headers });
    }
}
