import {BoxDeviceAllResponse, BoxDeviceResponse, GetAllDevicesResponse, GetBoxDeviceResponse, GetBoxDevicesResponse} from "../api";
import {IBoxDevice, IBoxDeviceDetail, IWGBoxDevice} from "../interface/box-device.interface";

export class BoxDeviceMapper {

    static mapBoxDevice(res: BoxDeviceResponse): IBoxDevice {
        return {
            deviceId: res.deviceId,
            name: res.name,
            lastCommunication: res.lastCommunication,
            status: res.status,
            consumptionPoint: res.consumptionPoint,
            message: res.message,
            note: res.note,
            wgApiKey: res.wgApiKey,
            wgIpAddress: res.wgIpAddress
        } as IBoxDevice;
    }

    static mapBoxDeviceDetail(res: GetBoxDeviceResponse): IBoxDeviceDetail {
        return {
            deviceId: res.deviceId,
            name: res.name,
            lastCommunication: res.lastCommunication,
            status: res.status,
            consumptionPoint: res.consumptionPoint,
            message: res.message,
            note: res.note,
            wsApiKey: res.wsApiKey,
            wgIpAddress: res.wgIpAddress,
            wgApiKey: res.wgApiKey
        } as IBoxDeviceDetail;
    }

    static mapBoxDevices(res: GetBoxDevicesResponse) {
        if (!res || !res.devices) {
            return [];
        }

        return res.devices.map((box: BoxDeviceResponse) => {
            return BoxDeviceMapper.mapBoxDevice(box);
        })
    }

    static mapWGBoxDevices(res: GetAllDevicesResponse): IWGBoxDevice[] {
        if (!res || !res.devices) {
            return [];
        }

        return res.devices.map((box: BoxDeviceResponse) => {
            return BoxDeviceMapper.mapWGBoxDevice(box);
        })
    }

    static mapWGBoxDevice(res: BoxDeviceAllResponse): IWGBoxDevice {
        return {
            deviceId: res.deviceId,
            name: res.name,
            lastCommunication: res.lastCommunication,
            status: res.status,
            message: res.message,
            wgApiKey: res.wgApiKey,
            wgIpAddress: res.wgIpAddress,
            companyId: res.company?.companyId,
            companyName: res.company?.companyName,
        } as IWGBoxDevice;
    }
}