import {Component, Input} from '@angular/core';
import {IHeatingBranchData} from '../../../interface/heating-branch.interface';
import {NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common';
import {IconDirective} from "@coreui/icons-angular";
import {EFunctionStatus} from "../../../interface/function-status.interface";
import {TooltipDirective} from "@coreui/angular-pro";

@Component({
    selector: 'app-heating-branch',
    standalone: true,
    imports: [NgForOf, NgOptimizedImage, NgIf, IconDirective, TooltipDirective, NgClass],
    templateUrl: './heating-branch.component.html',
    styleUrl: './heating-branch.component.scss',
})
export class HeatingBranchComponent {
    @Input() data!: IHeatingBranchData;
    showTextData: boolean = false;

    toggleTextData(): void {
        this.showTextData = !this.showTextData;
    }

    circulationPumpRunning(): boolean {
        if (!this.data.serviceDataDetail) {
            return false;
        }

        return this.data.serviceDataDetail.circulationPump === EFunctionStatus.On
            || this.data.serviceDataDetail.circulationPump === EFunctionStatus.Ok;
    }

    circulationPumpError(): boolean {
        if (!this.data.serviceDataDetail) {
            return false;
        }

        return this.data.serviceDataDetail.circulationPump === EFunctionStatus.Error;
    }

    circulationPumpOff(): boolean {
        if (!this.data.serviceDataDetail) {
            return false;
        }

        return this.data.serviceDataDetail.circulationPump === EFunctionStatus.Off;
    }

    circulationPumpUnknown(): boolean {
        return !this.circulationPumpRunning() && !this.circulationPumpError() && !this.circulationPumpOff();
    }

    tooltip(): string {
        if (this.circulationPumpRunning()) {
            return "čerpadlo běží";
        }
        if (this.circulationPumpOff()) {
            return "čerpadlo je zastavené";
        }
        if (this.circulationPumpError()) {
            return "error";
        }

        return "neznámý stav";
    }
}
