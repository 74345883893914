import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IDropdownItem} from "../../../../interface/dropdown.item.interface";

@Component({
  selector: 'app-dropdown-item',
  standalone: true,
  imports: [],
  templateUrl: './dropdown-item.component.html',
  styleUrl: './dropdown-item.component.scss'
})
export class DropdownItemComponent {

    @Output() itemSelected: EventEmitter<IDropdownItem> = new EventEmitter<IDropdownItem>();
    @Input() item!: IDropdownItem;

    onSelect(event: Event) {
        event.preventDefault();
        this.itemSelected.emit(this.item)
    }
}
