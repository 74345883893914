import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";
import {IBeError} from "../interface/be-error.interface";
import {IAlert} from "../interface/alert-inteface";

export type ScreenSize = 'xs'| 'sm' | 'md' | 'lg' | 'xl';

@Injectable({
    providedIn: 'root'
})
export class UiService {

    constructor() {
        this.updateScreenSize(window.innerWidth);
    }

    /**
     * the size of the screen [sm, md, lg, xl]
     */

    private screenSizeSubject: BehaviorSubject<ScreenSize> = new BehaviorSubject<ScreenSize>('sm');
    screenSize$: Observable<ScreenSize> = this.screenSizeSubject.asObservable();

    updateScreenSize(width: number): void {
        let screenSize: ScreenSize = 'sm';
        if (width >= 1200) {
            screenSize = 'xl';
        } else if (width >= 992) {
            screenSize = 'lg';
        } else if (width >= 768) {
            screenSize = 'md';
        } else if (width >= 576) {
            screenSize = 'sm';
        } else {
            screenSize = 'xs';
        }
        this.screenSizeSubject.next(screenSize);
        this.resizedSubject.next();
    }

    updateBe500ErrorModal(data: IBeError) {
        this.beErrorSubject.next({...data, state: 'show'});
    }

    private beErrorSubject: BehaviorSubject<IBeError> = new BehaviorSubject<IBeError>({state: "hidden"});
    beError$: Observable<IBeError> = this.beErrorSubject.asObservable();

    setErrorAlert(message: string) {
        this.alertSubject.next({message, type: "danger"});
    }

    setSuccessAlert(message: string) {
        this.alertSubject.next({message, type: "success", timeout: 5_000});
    }

    private alertSubject: BehaviorSubject<IAlert> = new BehaviorSubject<IAlert>({});
    alert$: Observable<IAlert> = this.alertSubject.asObservable();

    clearErrorAlerts(): void {
        this.clearErrorAlertsSubject.next();
    }

    private clearErrorAlertsSubject: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);
    clearErrorAlerts$: Observable<void> = this.clearErrorAlertsSubject.asObservable();

    toggleModal(name: string) {
        this.openedModalSubject.next(name);
    }

    closeModal(): void {
        this.openedModalSubject.next('');
    }

    private openedModalSubject: BehaviorSubject<string> = new BehaviorSubject<string>( '');
    openedModalToggle$: Observable<string> = this.openedModalSubject.asObservable();

    private resizedSubject: BehaviorSubject<void> = new BehaviorSubject<void>(void 0);
    resized$: Observable<void> = this.resizedSubject.asObservable();


    setSidebarServiceGroupOpened(id: string) {
        this.sidebarServiceGroupOpenedSubject.next(id);
    }

    private sidebarServiceGroupOpenedSubject: BehaviorSubject<string> = new BehaviorSubject<string>( '');
    sidebarServiceGroupOpened$: Observable<string> = this.sidebarServiceGroupOpenedSubject.asObservable();

    setSidebarServiceGroupClosed(id: string) {
        this.sidebarServiceGroupClosedSubject.next(id);
    }

    private sidebarServiceGroupClosedSubject: BehaviorSubject<string> = new BehaviorSubject<string>( '');
    sidebarServiceGroupClosed$: Observable<string> = this.sidebarServiceGroupClosedSubject.asObservable();

    closeBurger(): void {
        this.closeBurgerSubject.next();
    }

    private closeBurgerSubject: Subject<void> = new Subject<void>();
    closeBurger$: Observable<void> = this.closeBurgerSubject.asObservable();

}
