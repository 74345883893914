import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_ADD_COMPANY} from "../../../../interface/modal-type.interface";
import {INewCompany} from "../../../../interface/company.interface";
import {CompanyService} from "../../../../service/company.service";

@Component({
  selector: 'app-add-company',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        FormModalComponent
    ],
  templateUrl: './add-company.component.html',
  styleUrl: './add-company.component.scss'
})
export class AddCompanyComponent implements OnInit{
    @Output()companyCreated: EventEmitter<void> = new EventEmitter<void>();

    form!: FormGroup;

    modalName = MODAL_NAME_ADD_COMPANY // should be unique
    modalTitle = 'Přidat nového uživatele';

    constructor(
        private fb: FormBuilder,
        private companyService: CompanyService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            address: [''],
            ic: [''],
            dic: [''],
            email: ['', Validators.email],
            phone: [''],
        })
    }

    onClose(): void {
        this.initForm();
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {name, address, ic, dic, email, phone} = this.form.value;
            const company: INewCompany = {name, address, ic, dic, email, phone};
            this.companyService.createCompany(company)
                .subscribe(() => {
                    this.companyCreated.emit();
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Firma ${company.name} úspěšně vytvořena.`);
                    this.initForm();
                });
        }
    }
}
