import {Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {NgClass, NgForOf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ServiceGroupSelectItemComponent} from "../../service-group-select-item/service-group-select-item.component";
import {IDropdownItem} from "../../../interface/dropdown.item.interface";
import {DropdownItemComponent} from "./dropdown-item/dropdown-item.component";

@Component({
  selector: 'app-dropdown',
  standalone: true,
    imports: [
        NgForOf,
        ReactiveFormsModule,
        ServiceGroupSelectItemComponent,
        NgClass,
        FormsModule,
        DropdownItemComponent
    ],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss'
})
export class DropdownComponent {

    showDropDown: boolean = false;
    filterText: string = '';

    constructor(private elementRef: ElementRef) {
    }

    @ViewChild('myFilterInput', {static: false}) myFilterInput!: ElementRef;
    @Input() items: IDropdownItem[] = [];
    @Input() buttonText: string = '';
    @Output() itemSelected: EventEmitter<IDropdownItem> = new EventEmitter<IDropdownItem>();

    onClick(event: Event) {
        event.preventDefault();
        this.showDropDown = !this.showDropDown;
        if (this.showDropDown) {
            setTimeout(() => {
                this.myFilterInput.nativeElement.focus();
            }, 100); // element is not visible imidietaly
        }
        this.filterText = '';
    }

    getItems(): IDropdownItem[] {
        if (!this.filterText) {
            return this.items;
        }

        return this.items
            .filter((item: IDropdownItem) => item.name.toLowerCase().includes(this.filterText.toLowerCase()));
    }

    onItemSelected(item: IDropdownItem) {
        this.showDropDown = false;
        this.itemSelected.emit(item);
    }
    @HostListener('document:click', ['$event']) @HostListener('document:keydown.escape', ['$event'])
    onClickOutsideTheElement(event: Event): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside && this.showDropDown) {
            this.showDropDown = false;
            this.filterText = '';
        }
    }
}
