import {Component, OnInit} from '@angular/core';
import {ServiceGroupService} from "../../../../service/service-group.service";
import {IServiceGroup} from "../../../../interface/service.group.interface";
import {ActivatedRoute} from "@angular/router";
import {DateReadonlyInputComponent} from "../../../shared/date-readonly-input/date-readonly-input.component";
import {ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {TextInputReadonlyComponent} from "../../../shared/text-input-readonly/text-input-readonly.component";
import {NgForOf, NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {ActionService} from "../../../../service/action.service";
import {ServiceTableRowComponent} from "../../../service-table-row/service-table-row.component";
import {DropdownComponent} from "../../../shared/dropdown/dropdown.component";
import {IDropdownItem} from "../../../../interface/dropdown.item.interface";
import {MODAL_NAME_ADD_SERVICE_GROUP, MODAL_NAME_EDIT_SERVICE_GROUP_2} from "../../../../interface/modal-type.interface";
import {EditServiceGroupComponent} from "../../admin-service-groups/edit-service-group/edit-service-group.component";
import {ITableConfig, TableComponent} from "../../../shared/table/table.component";
import {AdminEditServiceComponent} from "../../admin-device-detail-page/admin-edit-service/admin-edit-service.component";
import {NavigationService} from "../../../../service/navigation.service";
import {AddServiceGroupComponent} from "../../admin-service-groups/add-service-group/add-service-group.component";

@Component({
    selector: 'app-sub-service-group-list',
    standalone: true,
    imports: [
        DateReadonlyInputComponent,
        ReactiveFormsModule,
        TextInputComponent,
        TextInputReadonlyComponent,
        NgIf,
        NgForOf,
        ServiceTableRowComponent,
        DropdownComponent,
        EditServiceGroupComponent,
        TableComponent,
        AdminEditServiceComponent,
        AddServiceGroupComponent,
    ],
    templateUrl: './sub-service-group-list.component.html',
    styleUrl: './sub-service-group-list.component.scss'
})
export class SubServiceGroupListComponent implements OnInit {

    // data
    groupId!: string; // id from the route

    subServiceGroups: IServiceGroup[] = []; // sub service groups inside the serviceGroup
    suggestServiceGroupsToBeAdded: IServiceGroup[] = []; // sub service groups inside the serviceGroup

    serviceGroupToBeEdited?: IServiceGroup;

    config: ITableConfig[] = [
        {field: 'name', label: 'Název'},
        {field: 'note', label: 'Poznámka'},
        {field: 'orderNum', label: 'Pořadí'},
        {
            specialComponent: {
                type: 'edit',
                onClick: this.onServiceGroupEdit.bind(this)
            }
        },
        {
            specialComponent: {
                type: 'minus',
                onClick: this.onServiceGroupRemovedFromGroup.bind(this)
            }
        },
    ]

    constructor(
        private serviceGroupService: ServiceGroupService,
        private uiService: UiService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.groupId = params['serviceGroupId'];
            if (this.groupId) {
                this.loadSubServiceGroups();
                this.loadAllSuggestedServiceGroupsToBeAdded();
            }
        });
    }

    getServiceGroupsPossibilities(): IDropdownItem[] {
        return this.suggestServiceGroupsToBeAdded.map((group: IServiceGroup) => {
            return {id: group.id, name: group.name} as IDropdownItem;
        });
    }

    onServiceGroupRowClicked(group: IServiceGroup): void {
        this.navigationService.toAdminServiceGroup(group.id)
    }

    onServiceGroupAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_ADD_SERVICE_GROUP);
    }


    onServiceGroupAddSubmitted() {
        this.loadSubServiceGroups();
        this.actionService.reloadServiceGroups();
    }

    onServiceGroupEdit(group: IServiceGroup): void {
        this.serviceGroupToBeEdited = group;
        this.uiService.toggleModal(MODAL_NAME_EDIT_SERVICE_GROUP_2);
    }

    onServiceGroupRemovedFromGroup(group: IServiceGroup): void {
        this.serviceGroupService.removeServiceGroupFromServiceGroup(group.id).subscribe(() => {
            this.uiService.setSuccessAlert(`Skupina ${group.name} úspěšně odebrána jako podskupina.`);
            this.loadSubServiceGroups();
            this.actionService.reloadServiceGroups();
            this.loadAllSuggestedServiceGroupsToBeAdded();
        });
    }

    onServiceGroupEditConfirmed(group: IServiceGroup): void {
        this.loadSubServiceGroups();
        this.actionService.reloadServiceGroups();
    }

    onServiceGroupAddedToGroup(item: IDropdownItem): void {
        const parent = this.groupId;
        const child = item.id;
        this.serviceGroupService.addServiceGroupToServiceGroup(parent, child).subscribe(() => {
            this.uiService.setSuccessAlert(`Skupina ${item.name} úspěšně přidána jako podskupina.`);
            this.loadSubServiceGroups();
            this.actionService.reloadServiceGroups();
            this.loadAllSuggestedServiceGroupsToBeAdded();
        })
    }

    private loadAllSuggestedServiceGroupsToBeAdded(): void {
        this.serviceGroupService.getSuggestedServiceGroupsForAddition(this.groupId).subscribe(
            (data: IServiceGroup[]): void => {
                this.suggestServiceGroupsToBeAdded = data;
            }
        )
    }

    private loadSubServiceGroups(): void {
        this.serviceGroupService.getSubServiceGroups(this.groupId).subscribe(
            (data: IServiceGroup[]): void => {
                this.subServiceGroups = data;
            }
        )
    }

    protected readonly MODAL_NAME_EDIT_SERVICE_GROUP_2 = MODAL_NAME_EDIT_SERVICE_GROUP_2;
}
