import { Component, Input } from '@angular/core';
import { IHeatingBranchData } from '../../../interface/heating-branch.interface';
import { IHotWaterTankData } from '../../../interface/hot-water-tank.interface';
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-hot-water-tank',
  standalone: true,
    imports: [
        NgOptimizedImage,
        NgIf,
        NgClass
    ],
  templateUrl: './hot-water-tank.component.html',
  styleUrl: './hot-water-tank.component.scss',
})
export class HotWaterTankComponent {
  @Input() data!: IHotWaterTankData;
  showTextData: boolean = false;

  toggleTextData(): void {
      this.showTextData = !this.showTextData;
  }
}
