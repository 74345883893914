import {Component, OnInit} from '@angular/core';
import {InfoModalComponent} from "../../shared/info-modal/info-modal.component";
import {MODAL_NAME_COMPANY_CONTEXT_CHANGED} from "../../../interface/modal-type.interface";
import {UserService} from "../../../service/user.service";
import {CompanyService} from "../../../service/company.service";
import {ICompany} from "../../../interface/company.interface";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-change-company-context-modal',
  standalone: true,
    imports: [
        InfoModalComponent,
        NgIf
    ],
  templateUrl: './change-company-context-modal.component.html',
  styleUrl: './change-company-context-modal.component.scss'
})
export class ChangeCompanyContextModalComponent implements OnInit {

    constructor(private companyService: CompanyService, private userService: UserService) {}

    modalName: string = MODAL_NAME_COMPANY_CONTEXT_CHANGED;

    company?: ICompany;

    ngOnInit(): void {
        this.userService.companyChanged$.subscribe((companyId: string) => {
            if(companyId){
                this.companyService.getCompany(companyId).subscribe((data: ICompany) => {
                    this.company = data;
                })
            }
        })
    }
}
