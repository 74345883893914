import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {IConsumptionPointDetail} from "../../../../interface/consumption-point.interface";
import {CustomDatePickerInputComponent} from "../../../shared/custom-date-picker-input/custom-date-picker-input.component";
import {ConsumptionPointService} from "../../../../service/consumption-point.service";
import {getTariffOptions} from "../../../../util/translate/translate-key";
import {IConsumptionPeriod, INewConsumptionPeriod} from "../../../../interface/consumption-period.interface";
import {DatePipe} from "../../../../util/pipe/date.pipe";
import {CustomValidators} from "../../../../util/validator/custom.validators";
import {TextInputReadonlyComponent} from "../../../shared/text-input-readonly/text-input-readonly.component";
import {ActionService} from "../../../../service/action.service";

@Component({
  selector: 'app-add-consumption-period',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        CustomDatePickerInputComponent,
        TextInputReadonlyComponent
    ],
  templateUrl: './add-consumption-period.component.html',
  styleUrl: './add-consumption-period.component.scss'
})
export class AddConsumptionPeriodComponent implements OnInit{
    @Output() created: EventEmitter<void> = new EventEmitter<void>();
    @Input() point?: IConsumptionPointDetail;
    @Input() lastPeriod?: IConsumptionPeriod;

    form!: FormGroup;
    distributionTariffs: ISelectOption[] = [];
    tariffOptions: ISelectOption[] = getTariffOptions();

    constructor(
        private fb: FormBuilder,
        private consumptionPointService: ConsumptionPointService,
        private actionService: ActionService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
        this.consumptionPointService.getCachedDistributionTariffs().subscribe((data: ISelectOption[]) => {
            this.distributionTariffs = data;
        })
    }

    private initForm(): void {
        if (this.lastPeriod) {
            this.form = this.initFromLastPeriod();
        } else {
            this.form = this.initClearForm();
        }
        setTimeout(() => {
            // do not listen on first tarrif change during the first initialization of the form from last period
            this.form.get('tariff')?.valueChanges.subscribe(value => {
                if (value !== null) {
                    this.resetFields();
                }
            });
        }, 50);
    }

    resetFields(): void {
        this.form.patchValue({
            flatRateDistribution: null,
            rate1VTDistributionMWh: null,
            rate2NTDistributionMWh: null,
            spotDistributionMwh: null,
            distributionTariff: null,
            breaker: null,
            rate1VTPowerMWh: null,
            rate2NTPowerMWh: null,
            spotPowerMWh: null,
            flatRatePower: null,
        });
    }

    private initClearForm() {
        return this.fb.group({
            startPeriod: [null, Validators.required],
            tariff: [null, Validators.required],
            flatRateDistribution: [null, [Validators.required, CustomValidators.isNumber]],
            rate1VTDistributionMWh: [null, CustomValidators.isNumber],
            rate2NTDistributionMWh: [null, CustomValidators.isNumber],
            spotDistributionMwh: [null, CustomValidators.isNumber],
            distributionTariff: null,
            breaker: null,
            rate1VTPowerMWh: [null, CustomValidators.isNumber],
            rate2NTPowerMWh: [null, CustomValidators.isNumber],
            spotPowerMWh: [null, CustomValidators.isNumber],
            flatRatePower: [null, [Validators.required, CustomValidators.isNumber]],
        });
    }

    private initFromLastPeriod() {
        return this.fb.group({
            startPeriod: [null, Validators.required],
            tariff: [this.lastPeriod?.tariff, Validators.required],
            flatRateDistribution: [this.lastPeriod?.flatRateDistribution, [Validators.required, CustomValidators.isNumber]],
            rate1VTDistributionMWh: [this.lastPeriod?.rate1VTDistributionMWh, CustomValidators.isNumber],
            rate2NTDistributionMWh: [this.lastPeriod?.rate2NTDistributionMWh, CustomValidators.isNumber],
            spotDistributionMwh: [this.lastPeriod?.spotDistributionMwh, CustomValidators.isNumber],
            distributionTariff: this.lastPeriod?.distributionTariff,
            breaker: this.lastPeriod?.breaker,
            rate1VTPowerMWh: [this.lastPeriod?.rate1VTPowerMWh, CustomValidators.isNumber],
            rate2NTPowerMWh: [this.lastPeriod?.rate2NTPowerMWh, CustomValidators.isNumber],
            spotPowerMWh: [this.lastPeriod?.spotPowerMWh, CustomValidators.isNumber],
            flatRatePower: [this.lastPeriod?.flatRatePower, [Validators.required, CustomValidators.isNumber]],
        });
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const period: INewConsumptionPeriod = {...this.form.value, consumptionPointId: this.point?.consumptionPointId};
            this.consumptionPointService.addPeriod(period)
                .subscribe(() => {
                    this.created.emit();
                    const from = new DatePipe().transform(period.startPeriod);
                    this.uiService.setSuccessAlert(`Období od ${from} úspěšně vytvořeno.`);
                    this.initForm();
                    this.actionService.reloadConsumptionPeriods();
                });
        }
    }
}
