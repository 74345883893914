import {booleanAttribute, Component, Input, OnChanges} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass
  ],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss'
})
export class TextInputComponent implements OnChanges{
    @Input() form!: FormGroup;
    @Input() label!: string;
    @Input() field!: string;
    @Input() class?: string;
    @Input({transform: booleanAttribute}) readonly: boolean = false;
    @Input({transform: booleanAttribute}) required: boolean = false;
    @Input({transform: booleanAttribute}) hideLabel: boolean = false;
    @Input({transform: booleanAttribute}) password: boolean = false;

    formControl(): FormControl {
        return this.form.get(this.field) as FormControl
    }

    ngOnChanges(){
        if(this.readonly) {
            this.form.get(this.field)?.disable();
        } else {
            this.form.get(this.field)?.enable();
        }
    }
}
