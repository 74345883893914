import {Injectable} from '@angular/core';
import {LocalStorageService} from "@coreui/angular-pro";
import {IUserContext, RoleEnum} from "../interface/user.context.interface";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {ApiService} from "./api.service";
import {AddRightToServiceGroupRequest, AddUserRequest, ChangePasswordForUserRequest, ChangePasswordRequest, DeleteUserRequest, EditUserRequest, GetUserRequest, GetUserResponse, GetUsersRequest, GetUsersResponse, LoginRequest, LoginResponse, RemoveRightFromServiceGroupRequest} from "../api";
import {map} from "rxjs/operators";
import {UserMapper} from "../mapper/user.mapper";
import {IHttpConfig} from "../interface/http-config.interface";
import {NoResponseMapper} from "../mapper/no-response.mapper";
import {IUser} from "../interface/user.interface";
import {IPeriod} from "../interface/period.interface";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    readonly LS_USER_DATA_KEY = 'obzor-energy-user-data';

    private authenticated = new BehaviorSubject<boolean>(false);
    authenticated$ = this.authenticated.asObservable();

    private userContext?: IUserContext | null;

    constructor(private localStorageService: LocalStorageService, private apiService: ApiService) {
    }

    login(username: string, password: string): Observable<IUserContext> {
        const config: IHttpConfig = {withApiKey: false, clearAlerts: true}

        const body: LoginRequest = {username, password};

        return this.apiService.post("/auth/login", body, config).pipe(
            map((res: LoginResponse) => UserMapper.mapLoginResponse(res, username)),
            tap((data: IUserContext) => this.setupContext(data))
        );
    }

    createUser(userName: string, email: string, password: string, role: RoleEnum): Observable<void> {
        const companyId: string = this.getCompanyId()
        const config: IHttpConfig = {clearAlerts: true}

        const body: AddUserRequest = {companyId, email, password, role, userName};

        return this.apiService.post("/user/add-user", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    editUser(user: IUser, userNameChanged: boolean): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}
        const {userId, role, email, username} = user;
        let body: EditUserRequest;
        if (userNameChanged) {
            body = {userId, role, email, username};
        } else {
            body = {userId, role, email}
        }

        return this.apiService.post("/user/edit-user", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    deleteUser(userId: string): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}
        const body: DeleteUserRequest = {userId};

        return this.apiService.post("/user/delete-user", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    addRightToServiceGroup(userId: string, serviceGroupId: string): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}
        const body: AddRightToServiceGroupRequest = {userId, serviceGroupId};

        return this.apiService.post("/user/add-right-to-service-group", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    removeRightFromServiceGroup(userId: string, serviceGroupId: string): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}
        const body: RemoveRightFromServiceGroupRequest = {userId, serviceGroupId};

        return this.apiService.post("/user/remove-right-from-service-group", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    changePassword(oldPassword: string, newPassword: string): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}

        const body: ChangePasswordRequest = {oldPassword, newPassword};
        return this.apiService.post("/user/change-password", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    changePasswordForUser(password: string, userId: string): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}

        const body: ChangePasswordForUserRequest = {password, userId};
        return this.apiService.post("/user/change-password-for-user", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    getUsersFromMyCompany(): Observable<IUser[]> {
        const body: GetUsersRequest = {companyId: this.getCompanyId()};

        return this.apiService.post("/user/get-users", body).pipe(
            map((res: GetUsersResponse) => UserMapper.mapUsers(res))
        );
    }

    getUser(userId: string): Observable<IUser> {
        const body: GetUserRequest = {userId};

        return this.apiService.post("/user/get-user", body).pipe(
            map((res: GetUserResponse) => UserMapper.mapUserDetail(res))
        );
    }

    signOut() {
        this.clearContext();
    }

    isAuthenticated(): boolean {
        return !!this.getNullableUserContext();
    }

    isAdminPartner(): boolean {
        if (!this.getNullableUserContext()) {
            return false;
        }
        return this.getUserContext().role === 'AdminPartner';
    }

    isPartnerUser(): boolean {
        if (!this.getNullableUserContext()) {
            return false;
        }
        return this.getUserContext().role === 'UserPartner';
    }

    isAdmin(): boolean {
        if (!this.getNullableUserContext()) {
            return false;
        }
        return this.getUserContext().role === 'AdminOE';
    }

    getUserContext(): IUserContext {
        const context = this.getNullableUserContext();

        if (!context) {
            throw new Error("User context not setup");
        }

        return context;
    }

    getMyRealCompanyId(): string {
        return this.getUserContext().myRealCompanyId;
    }

    getCompanyId() {
        return this.getUserContext().companyId;
    }

    getPeriods(): IPeriod[] {
      return this.getUserContext().periods;
    }

    setPeriods(periods: IPeriod[]) {
      this.setupContext({
        ...this.getUserContext(),
        periods: periods,
      })
    }

    private setupContext(data: IUserContext) {
        this.userContext = data;
        this.apiService.setApiKey(data.apikey);
        this.localStorageService.setItem(this.LS_USER_DATA_KEY, data);

        // let the other component subscribed know that use is authenticated
        this.authenticated.next(true);
    }

    private getNullableUserContext(): IUserContext | null {
        if (this.userContext) {
            return this.userContext;
        }

        const userContext: IUserContext = this.localStorageService.getItem(this.LS_USER_DATA_KEY);
        if (userContext) {
            this.setupContext(userContext);
            return userContext;
        }

        return null;
    }

    private clearContext() {
        this.localStorageService.removeItem(this.LS_USER_DATA_KEY);
        this.apiService.removeApiKey();
        this.authenticated.next(false);
    }

    changeCompanyId(companyId: string ) {
        this.setupContext({
            ...this.getUserContext(),
            companyId: companyId,
        })
        this._updateCompany(companyId);
    }

    private companyChangedSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    companyChanged$: Observable<string> = this.companyChangedSubject.asObservable();

    private _updateCompany(companyId: string): void {
        this.companyChangedSubject.next(companyId);
    }

}
