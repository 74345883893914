import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import { ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {DeleteModalComponent} from "../../../shared/delete-modal/delete-modal.component";
import {UiService} from "../../../../service/ui.service";
import {MODAL_NAME_DELETE_BOX_DEVICE} from "../../../../interface/modal-type.interface";
import {BoxDeviceService} from "../../../../service/box-device.service";
import {IBoxDevice} from "../../../../interface/box-device.interface";

@Component({
  selector: 'app-admin-delete-device',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        DeleteModalComponent
    ],
  templateUrl: './admin-delete-device.component.html',
  styleUrl: './admin-delete-device.component.scss'
})
export class AdminDeleteDeviceComponent {

    @Input() boxDevice?: IBoxDevice;
    @Output() boxDeviceDeleted: EventEmitter<IBoxDevice> = new EventEmitter<IBoxDevice>();

    modalName = MODAL_NAME_DELETE_BOX_DEVICE; // should be unique
    modalTitle = 'Smazat EM box';

    constructor(private boxDeviceService: BoxDeviceService, private uiService: UiService) {
    }

    onConfirm() {
        this.boxDeviceService.deleteDevice(this.boxDevice!.deviceId).subscribe(() => {
            this.boxDeviceDeleted.emit(this.boxDevice);
            this.uiService.toggleModal(this.modalName);
            this.uiService.setSuccessAlert(`EM ox ${this.boxDevice?.name} úspěšně smazán.`);
        })
    }

}
