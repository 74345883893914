import {Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {Breadcrumb} from "./Breadcrumb";
import {UserService} from "../user.service";
import {CompanyService} from "../company.service";
import {BoxDeviceService} from "../box-device.service";
import {IService} from "../../interface/service.interface";
import {LinkService} from "../link.service";
import {IUser} from "../../interface/user.interface";
import {IServiceGroup} from "../../interface/service.group.interface";
import {ServiceGroupService} from "../service-group.service";
import {IConsumptionPointDetail} from "../../interface/consumption-point.interface";
import {IConsumptionPeriod} from "../../interface/consumption-period.interface";
import {DatePipe} from "../../util/pipe/date.pipe";

@Injectable({
    providedIn: 'root'
})
export class AdminBreadcrumbService {

    constructor(
        private serviceGroupService: ServiceGroupService,
        private companyService: CompanyService,
        private userService: UserService,
        private boxDeviceService: BoxDeviceService,
        private linkService: LinkService
    ) {
    }

    private adminBreadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
    adminBreadcrumbs$ = this.adminBreadcrumbsSubject.asObservable();

    private set breadcrumbs(breadcrumbs: Breadcrumb[]) {
        this.adminBreadcrumbsSubject.next(breadcrumbs);
    }

    ofCompanies() {
        const companies = this.companiesBreadcrumb();
        this.breadcrumbs = [companies];
    }

    ofCalculation() {
        const calculation = this.calculationBreadcrumb();
        this.breadcrumbs = [calculation];
    }

    ofWgSettings() {
        const wgSettings = this.wgSettingsBreadcrumb();
        this.breadcrumbs = [wgSettings];
    }

    async ofCompanyDetail() {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();

        this.breadcrumbs = [companies, companyDetail];
    }

    async ofDevices() {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const devices = this.devicesBreadcrumb();

        this.breadcrumbs = [companies, companyDetail, devices];
    }

    async ofDeviceDetail(deviceId: string) {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const devices = this.devicesBreadcrumb();
        const deviceDetail = await this.deviceDetailBreadcrumb(deviceId);

        this.breadcrumbs = [companies, companyDetail, devices, deviceDetail];
    }

    async ofServiceGroups() {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const serviceGroups = this.serviceGroupsBreadcrumb();

        this.breadcrumbs = [companies, companyDetail, serviceGroups];
    }

    async ofServiceGroupDetail(group: IServiceGroup) {

        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const serviceGroups = this.serviceGroupsBreadcrumb();
        const parentServiceGroupDetails = await this.parentServiceGroupDetailsBreadcrumbs(group);
        const serviceGroupDetail = this.serviceGroupDetailBreadcrumb(group);

        this.breadcrumbs = [companies, companyDetail, serviceGroups, ...parentServiceGroupDetails, serviceGroupDetail];
    }

    async ofServiceDetail(service: IService) {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const devices = this.devicesBreadcrumb();
        const deviceDetail = await this.deviceDetailBreadcrumb(service.deviceId);
        const services = this.servicesBreadcrumb();
        const servicesDetail = this.serviceDetailBreadcrumb(service);

        this.breadcrumbs = [companies, companyDetail, devices, deviceDetail, services, servicesDetail];
    }

    async ofUsers() {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const users = this.usersBreadcrumb();

        this.breadcrumbs = [companies, companyDetail, users];
    }

    async ofUserDetail(user: IUser) {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const users = this.usersBreadcrumb();
        const userDetail = this.userDetailBreadcrumb(user);

        if(this.userService.isAdmin()) {
            this.breadcrumbs = [companies, companyDetail, users, userDetail];
        } else {
            this.breadcrumbs = [users, userDetail];
        }
    }

    async ofConsumptionPoints() {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const consumptionPoints = this.consumptionPointsBreadcrumb();

        this.breadcrumbs = [companies, companyDetail, consumptionPoints];
    }

    async ofConsumptionPointDetail(point: IConsumptionPointDetail) {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const consumptionPoints = this.consumptionPointsBreadcrumb();
        const consumptionPoint = this.consumptionPointDetailBreadcrumb(point);

        this.breadcrumbs = [companies, companyDetail, consumptionPoints, consumptionPoint];
    }

    async ofConsumptionPointPeriod(point: IConsumptionPointDetail, period: IConsumptionPeriod) {
        const companies = this.companiesBreadcrumb();
        const companyDetail = await this.companyDetailBreadcrumb();
        const consumptionPoints = this.consumptionPointsBreadcrumb();
        const consumptionPoint = this.consumptionPointDetailBreadcrumb(point);
        const consumptionPeriod = this.consumptionPeriodDetailBreadcrumb(point, period);

        this.breadcrumbs = [companies, companyDetail, consumptionPoints, consumptionPoint, consumptionPeriod];
    }

    clearBreadcrumbs() {
        this.adminBreadcrumbsSubject.next([]);
    }

    private companiesBreadcrumb(): Breadcrumb {
        return {label: 'Firmy', url: this.linkService.companies()};
    }

    private calculationBreadcrumb(): Breadcrumb {
        return {label: 'Kalkulace', url: this.linkService.calculation()};
    }

    private wgSettingsBreadcrumb(): Breadcrumb {
        return {label: 'WG nastavení', url: this.linkService.wgSettings()};
    }

    private async companyDetailBreadcrumb(): Promise<Breadcrumb> {
        const companyName = await firstValueFrom(this.companyService.getCachedCurrentCompanyName());
        return {
            label: companyName,
            url: this.linkService.adminCompanyDetail()
        };
    }

    private async deviceDetailBreadcrumb(deviceId: string): Promise<Breadcrumb> {
        const deviceName = await firstValueFrom(this.boxDeviceService.getDeviceName(deviceId));
        return {
            label: deviceName,
            url: this.linkService.adminDeviceDetail(deviceId),
        };
    }

    private devicesBreadcrumb(): Breadcrumb {
        return {label: 'EM boxy', url: this.linkService.adminDevices()};
    }

    private serviceGroupsBreadcrumb(): Breadcrumb {
        return {label: 'Servisní skupiny', url: this.linkService.adminServiceGroups()};
    }

    private usersBreadcrumb(): Breadcrumb {
        if (this.userService.isAdmin()) {
            return {label: 'Uživatelé', url: this.linkService.adminUsers()};
        }

        return {label: 'Uživatelé', url: this.linkService.adminPartnerUsers()};
    }

    private consumptionPointsBreadcrumb(): Breadcrumb {
        return {label: 'Odběrná místa', url: this.linkService.adminConsumptionPoints()};
    }
    private consumptionPointDetailBreadcrumb(point: IConsumptionPointDetail): Breadcrumb {
        return {label: point.ean ?? '<ean empty>', url: this.linkService.adminConsumptionPointDetail(point.consumptionPointId)};
    }
    private consumptionPeriodDetailBreadcrumb(point: IConsumptionPointDetail, period: IConsumptionPeriod): Breadcrumb {

        const from = new DatePipe().transform(period.startPeriod);
        const pointId = point.consumptionPointId;
        const periodId = period.consumptionPointPeriodId;
        return {label: `Období od ${from}` , url: this.linkService.adminConsumptionPeriodDetail(pointId, periodId)};
    }

    private servicesBreadcrumb(): Breadcrumb {
        return {'label': 'Služby'};
    }

    private serviceDetailBreadcrumb(service: IService): Breadcrumb {
        return {'label': service.name, url: this.linkService.adminService(service.serviceId)};
    }

    private userDetailBreadcrumb(user: IUser): Breadcrumb {
        if(this.userService.isAdmin()) {
            return {'label': user.username, url: this.linkService.adminUserDetail(user.userId)};
        }

        return {'label': user.username, url: this.linkService.adminPartnerDetail(user.userId)};
    }

    private serviceGroupDetailBreadcrumb(group: IServiceGroup) {
        return {'label': group.name, url: this.linkService.adminServiceGroupDetail(group.id)};
    }

    private async parentServiceGroupDetailsBreadcrumbs(group: IServiceGroup): Promise<Breadcrumb[]> {
        const list: Breadcrumb[] = [];
        while (group.parentServiceGroupId) {
            const parentGroup = await firstValueFrom(this.serviceGroupService.getCachedServiceGroup(group.parentServiceGroupId));
            list.unshift(this.serviceGroupDetailBreadcrumb(parentGroup))
            group = parentGroup;
        }

        return list;
    }
}
