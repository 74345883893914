<c-card class="container-width">
  <c-card-body>
    <div class="custom-container mt-4">
      <div class="first-date-section">
        <button class="custom-arrow-button" mat-icon-button (click)="goToPreviousDate()" aria-label="Day Before">
          <mat-icon>arrow_back</mat-icon>
        </button>
        <ng-container *ngIf="getMode() === 'year'; else notYear">
          <!-- Year mode -->
          <app-custom-year-picker-input class="custom-date-picker"
                                        [form]="formGroup"
                                        [label]="'Datum'"
                                        [field]="'date'"
                                        [hideLabel]="true">
          </app-custom-year-picker-input>
        </ng-container>

        <!-- Month mode -->
        <ng-template #notYear>
          <ng-container *ngIf="getMode() === 'month'; else notMonth">
            <app-custom-month-picker-input class="custom-date-picker"
                                           [form]="formGroup"
                                           [label]="'Datum'"
                                           [field]="'date'"
                                           [hideLabel]="true">
            </app-custom-month-picker-input>
          </ng-container>
        </ng-template>

        <!-- Day mode -->
        <ng-template #notMonth>
          <app-custom-date-picker-input class="custom-date-picker"
                                        [form]="formGroup"
                                        [label]="'Datum'"
                                        [field]="'date'"
                                        [hideLabel]="true">
          </app-custom-date-picker-input>
        </ng-template>
        <button class="custom-arrow-button" mat-icon-button (click)="goToNextDate()" aria-label="Day After">
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
      <div class="compare-section">
        <label for="compare" class="compare-label">
          <input type="checkbox" id="compare" name="compare" [(ngModel)]="isCompareEnabled"
                 (ngModelChange)="changeIsCompareEnabled()"/>
          Srovnat
        </label>
        <ng-container *ngIf="isCompareEnabled">
          <ng-container *ngIf="getMode() === 'year'; else notYear">
            <!-- Month mode -->
            <app-custom-year-picker-input class="custom-date-picker"
                                          [form]="formGroup"
                                          [label]="'Srovnávací datum'"
                                          [field]="'compareDate'"
                                          [hideLabel]="true">
            </app-custom-year-picker-input>
          </ng-container>

          <!-- Year mode -->
          <ng-template #notYear>
            <ng-container *ngIf="getMode() === 'month'; else notMonth">
              <app-custom-month-picker-input class="custom-date-picker"
                                             [form]="formGroup"
                                             [label]="'Srovnávací datum'"
                                             [field]="'compareDate'"
                                             [hideLabel]="true">
              </app-custom-month-picker-input>
            </ng-container>
          </ng-template>

          <!-- Day mode -->
          <ng-template #notMonth>
            <app-custom-date-picker-input class="custom-date-picker"
                                          [form]="formGroup"
                                          [label]="'Srovnávací datum'"
                                          [field]="'compareDate'"
                                          [hideLabel]="true">
            </app-custom-date-picker-input>
          </ng-template>
        </ng-container>
      </div>
      <div class="mode-section">
        <form [formGroup]="modeGroup">
          <c-button-group class="mode-group" role="group">
            <input
              class="btn-check"
              formControlName="mode"
              type="radio"
              value="day"
              id="day"
            />
            <label
              (click)="setMode('day')"
              cButton
              cFormCheckLabel
              color="secondary"
              variant="outline"
              for="day"
            >
              Den
            </label>
            <input
              class="btn-check"
              formControlName="mode"
              type="radio"
              value="month"
              id="month"
            />
            <label
              (click)="setMode('month')"
              cButton
              cFormCheckLabel
              color="secondary"
              variant="outline"
              for="month"
            >
              Měsíc
            </label>
            <input
              class="btn-check"
              formControlName="mode"
              type="radio"
              value="year"
              id="year"
            />
            <label
              (click)="setMode('year')"
              cButton
              cFormCheckLabel
              color="secondary"
              variant="outline"
              for="year"
            >
              Rok
            </label>
          </c-button-group>
        </form>
      </div>
    </div>
    <c-chart class="mt-2" *ngIf="!temporaryHideGraphHack" [data]="graphData" [options]="chartOptions"></c-chart>
  </c-card-body>
</c-card>
