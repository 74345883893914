<div class="rotary-switch">
  <div class="rotary-buttons">
    <button (click)="rotateCounterClockwise()">◀</button>
    <button (click)="rotateClockwise()">▶</button>
  </div>

  <div class="rotary-dial">
    <div
      class="rotary-arrow"
      [style.transform]="'rotate(' + rotationAngle + 'deg) translateY(-25px)'"
    >
      ▲
    </div>
  </div>

  <div class="rotary-labels">
    <div
      *ngFor="let option of options; let i = index"
      class="rotary-label"
      [style.top]="getLabelPosition(i).top"
      [style.left]="getLabelPosition(i).left"
      [style.transform]="'translate(-50%, -50%)'"
    >
      <div class="rotary-dot"></div>
      <span class="rotary-text">{{ option }}</span>
    </div>
  </div>
</div>
