import {Component, HostListener, OnInit} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {ScreenSize, UiService} from "../../../service/ui.service";
import {IBeError} from "../../../interface/be-error.interface";

@Component({
  selector: 'app-error-modal',
  standalone: true,
    imports: [
        NgClass,
        NgIf
    ],
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss'
})
export class ErrorModalComponent implements OnInit{

    error: IBeError = {state: 'hidden'};

    extraSmallScreen: boolean = false;


    constructor(private uiService: UiService) {

    }

    onClose(): void {
        this.error = {...this.error, state: 'hidden'};
    }

    ngOnInit() {
        this.uiService.beError$.subscribe((err: IBeError) => {
            this.error = {...err};
        });
        this.uiService.screenSize$.subscribe((ss: ScreenSize) => {
            this.extraSmallScreen = ss === 'xs';
        })
    }


    @HostListener('document:keydown.escape', ['$event'])
    onEscape(event: Event): void {
        this.error = {...this.error, state: 'hidden'};
    }
}
