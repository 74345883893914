import {booleanAttribute, Component, Input, OnChanges} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf
  ],
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss'
})
export class FileInputComponent implements OnChanges {
  @Input() form!: FormGroup;
  @Input() label!: string;
  @Input() field!: string;
  @Input() readonly: boolean = false;
  @Input({transform: booleanAttribute}) required: boolean = false;
  @Input({transform: booleanAttribute}) hideLabel: boolean = false;

  formControl(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  ngOnChanges() {
    if (this.readonly) {
      this.form.get(this.field)?.disable();
    } else {
      this.form.get(this.field)?.enable();
    }
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.form.get(this.field)?.setValue(file);
    }
  }

}
