import { Directive, AfterViewInit, HostListener } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appEqualHeight]'
})
export class EqualHeightDirective implements AfterViewInit {

  constructor() {}

  ngAfterViewInit() {
    this.setEqualHeight();
  }

  private setEqualHeight() {
    const elements = document.getElementsByClassName('equal-height-directive');
    let minHeight = 150;

    for (let i = 0; i < elements.length; i++) {
      const height = (elements[i] as HTMLElement).clientHeight;
      if (height > minHeight) {
        minHeight = height;
      }
    }

    for (let i = 0; i < elements.length; i++) {
      (elements[i] as HTMLElement).style.minHeight = `${minHeight}px`;
    }
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.setEqualHeight();
  }
}
