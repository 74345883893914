import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ITableConfig} from "../table.component";
import {StatusComponent} from "../../status/status.component";
import {TableCellComponent} from "../table-cell/table-cell.component";
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-table-row',
  standalone: true,
    imports: [
        StatusComponent,
        TableCellComponent,
        NgForOf
    ],
  templateUrl: './table-row.component.html',
  styleUrl: './table-row.component.scss'
})
export class TableRowComponent {

    @Input() config: ITableConfig[] = [];
    @Input() rowData: any = {};

    @Output() rowClicked = new EventEmitter<any>();

    onRowClick(event: Event) {
        this.rowClicked.emit(this.rowData);
    }

}
