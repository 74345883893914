import { Injectable } from '@angular/core';
import {UserService} from "./user.service";
import {IService} from "../interface/service.interface";

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  constructor(private userService: UserService) { }

    // ------------------------------ CLIENT ------------------------------
    clientServiceGroup(groupId: string): string {
        const isAdmin = this.userService.isAdmin();
        if (isAdmin) {
            const companyId = this.userService.getCompanyId();
            return `company/${companyId}/service-group/${groupId}`;
        } else {
            return `service-group/${groupId}`;
        }
    }

    clientServiceDetail(groupId: string, service: IService) {
        const isAdmin = this.userService.isAdmin();
        if (isAdmin) {
            const companyId = this.userService.getCompanyId();
            return `company/${companyId}/service-group/${groupId}/service/${service.serviceId}`;
        } else {
            return `/service-group/${groupId}/service/${service.serviceId}`;
        }
    }

    clientServiceSettings(serviceId: string, serviceType: string, groupId: string): string {
        const isAdmin = this.userService.isAdmin();
        const type: string = this.clientServiceType(serviceType);
        if (isAdmin) {
            const companyId = this.userService.getCompanyId();
            return `company/${companyId}/service-group/${groupId}/service-${type}/${serviceId}/settings`;
        } else {
            return `service-group/${groupId}/service-${type}/${serviceId}/settings`;
        }
    }

    clientServiceType(type: string): string {
        if (type === 'valveHeatingBranch') {
            return 'vhb';
        } else {
            console.error('unknown client service type');
            return 'unknown';
        }
    }

    clientServiceGroupDetail(groupId: string) {
        const isAdmin = this.userService.isAdmin();
        if (isAdmin) {
            const companyId = this.userService.getCompanyId();
            return `company/${companyId}/service-group/${groupId}`;
        } else {
            return `/service-group/${groupId}`;
        }
    }

    // ------------------------------ ADMIN ------------------------------

    // company
    adminCompanyDetail(): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/detail`;
    }

    // devices
    adminDevices(): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/devices`;
    }

    adminDeviceDetail(deviceId: string, companyId? : string) {
        if (!companyId) {
            companyId = this.userService.getCompanyId();
        }
        return `admin/company/${companyId}/device/${deviceId}`;
    }

    // service groups
    adminServiceGroups(): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/service-groups`;
    }

    adminServiceGroupDetail(serviceId: string): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/service-groups/${serviceId}`;
    }

    // service
    adminService(serviceId: string): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/service/${serviceId}`;
    }

    // USERS
    adminUsers(): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/users`;
    }

    adminPartnerUsers(): string {
        const companyId = this.userService.getCompanyId();
        return `users`;
    }

    adminUserDetail(userId: string): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/users/${userId}`;
    }

    adminPartnerDetail(userId: string): string {
        return `users/${userId}`;
    }

    // CONSUMPTION POINT
    adminConsumptionPoints(): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/consumption-points`;
    }

    adminConsumptionPointDetail(consumptionPointId: string): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/consumption-points/${consumptionPointId}`;
    }

    adminConsumptionPeriodDetail(consumptionPointId: string, consumptionPeriodId: string): string {
        const companyId = this.userService.getCompanyId();
        return `admin/company/${companyId}/consumption-points/${consumptionPointId}/period/${consumptionPeriodId}`;
    }



    // ------------------------------ GLOBAL ------------------------------
    companies(): string {
        return `global/companies`;
    }

    calculation() {
        return `global/calculation`;
    }

    wgSettings() {
        return `global/wg-settings`;
    }
}
