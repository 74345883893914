<div class="container text-center">
    <!-- spot chart -->
    <p class="h3">SPOT Ceny Energie</p>
    <div class="chart-container">
        <c-chart *ngIf="!temporaryHideGraphHack" [data]="spotPriceGraphData" [options]="chartOptions"></c-chart>
    </div>
    <div class="d-flex justify-content-center mt-2">
        <button cButton color="dark" (click)="changeDate(-1)">&lt;-</button>
        <c-date-picker
                [formControl]="selectedDate"
                [showAdjacentDays]="false"
                [maxDate]="maxDate"
                cleaner="false"
                [locale]="'cs-CZ'"
                data-coreui-locale="cs-CZ"
                closeOnSelect
        ></c-date-picker>
        <button cButton color="dark" [disabled]="isNextDateInFuture()" (click)="changeDate(1)">-&gt;</button>
    </div>

    <p class="h3 mt-4">
        Aktuální cena elektrické energie: {{ actualPrice.actualPriceCZK }} Kč / MWh
    </p>
    <p class="h4" [ngStyle]="{ color: getPriceColor(actualPrice!!.level) }">
        {{ this.getPriceLabel() }}
    </p>

    <!--  TODO premistit chart do vlastni komponenty, bude se pouzivat   -->

    <!--    <p class="h3 mt-4">Spotřeba elektrické energie</p>-->
    <!--    <div class="chart-container">-->
    <!--        <c-chart [data]="consumptionGraphData" type="line"></c-chart>-->
    <!--    </div>-->

    <p class="h4 m-4">Aktuální spotřeba: {{ currentPower }} kW</p>
</div>
