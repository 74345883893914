import {Component, Input, OnInit} from '@angular/core';
import {DropdownComponent} from "../../shared/dropdown/dropdown.component";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {MODAL_NAME_ADD_CONSUMPTION_POINT, MODAL_NAME_DELETE_CONSUMPTION_POINT, MODAL_NAME_EDIT_CONSUMPTION_POINT} from "../../../interface/modal-type.interface";
import {UiService} from "../../../service/ui.service";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {IConsumptionPoint} from "../../../interface/consumption-point.interface";
import {ConsumptionPointService} from "../../../service/consumption-point.service";
import {AddConsumptionPointComponent} from "./add-consumption-point/add-consumption-point.component";
import {ISelectOption} from "../../shared/text-select/text-select.component";
import {EditConsumptionPointComponent} from "./edit-consumption-point/edit-consumption-point.component";
import {DeleteConsumptionPointComponent} from "./delete-consumption-point/delete-consumption-point.component";
import {translateVoltageKey} from "../../../util/translate/translate-key";

@Component({
  selector: 'app-admin-consumption-points',
  standalone: true,
    imports: [
        DropdownComponent,
        TableComponent,
        TextInputReadonlyComponent,
        AddConsumptionPointComponent,
        EditConsumptionPointComponent,
        DeleteConsumptionPointComponent
    ],
  templateUrl: './admin-consumption-points.component.html',
  styleUrl: './admin-consumption-points.component.scss'
})
export class AdminConsumptionPointsComponent implements OnInit{

    @Input() isPartOfOnMainPage = false;

    consumptionPoints: IConsumptionPoint[] = [];

    electricityDistributors: ISelectOption[] = [];
    // gasDistributors: ISelectOption[] = [];

    config: ITableConfig[] = [
        {field: 'ean', label: 'EAN'},
        {field: 'electricityDistributor', label: 'Distributor elektřiny'},
        {field: 'voltageLevel', label: 'Napětí', translateKey: translateVoltageKey.bind(this)},
        {field: 'eic', label: 'EIC'},
        {field: 'address', label: 'Adresa'},
        {field: 'gps', label: 'GPS'},
        {
            specialComponent: {
                type: 'edit',
                onClick: this.onConsumptionPointEdit.bind(this)
            }
        },
        {
            specialComponent: {
                type: 'delete',
                onClick: this.onConsumptionPointDelete.bind(this)
            }
        },
    ];

    consumptionPointToBeDeleted?: IConsumptionPoint;
    consumptionPointToBeEdited?: IConsumptionPoint;

    constructor(private consumptionPointService: ConsumptionPointService, private uiService: UiService, private navigationService: NavigationService, private adminBreadcrumbService: AdminBreadcrumbService) {
    }

    ngOnInit(): void {
        this.loadConsumptionPoints();
        this.loadDistributors();
        this.navigationService.rememberLastTabVisited();
    }

    onRowClicked(consumptionPoint: IConsumptionPoint): void {
        this.navigationService.toAdminConsumptionPointDetail(consumptionPoint.consumptionPointId);
    }

    onConsumptionPointAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_ADD_CONSUMPTION_POINT);
    }

    onConsumptionPointAddSubmitted() {
        this.loadConsumptionPoints();
    }

    onConsumptionPointDelete(point: IConsumptionPoint): void {
        this.consumptionPointToBeDeleted = point;
        this.uiService.toggleModal(MODAL_NAME_DELETE_CONSUMPTION_POINT);
    }

    onConsumptionPointDeleteConfirmed(point: IConsumptionPoint) {
        this.loadConsumptionPoints();
    }

    onConsumptionPointEdit(consumptionPoint: IConsumptionPoint): void {
        this.consumptionPointToBeEdited = consumptionPoint;
        this.uiService.toggleModal(MODAL_NAME_EDIT_CONSUMPTION_POINT);
    }

    onConsumptionPointEditConfirmed(consumptionPoint: IConsumptionPoint): void {
        this.loadConsumptionPoints();
    }

    private loadDistributors(): void {
        this.consumptionPointService.getCachedElectricityDistributors().subscribe((data: ISelectOption[]) => {
            this.electricityDistributors = data;
        })
        // this.consumptionPointService.getCachedGasDistributors().subscribe((data: ISelectOption[]) => {
        //     this.gasDistributors = data;
        // })
    }

    private loadConsumptionPoints(): void {
        this.consumptionPointService.getConsumptionPointsFromMyCompany().subscribe((data: IConsumptionPoint[]) => {
            this.consumptionPoints = data;
            if (!this.isPartOfOnMainPage) {
                this.adminBreadcrumbService.ofConsumptionPoints();
            }
        });
    }



}
