<div class="container">

    <!-- group -->
    <h3>Servisní skupina: {{ group && group.name }}</h3>

    <!-- user detail -->
    <div class="box-shadow">
        <h5>Detail</h5>
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Název'" [value]="this.group?.name"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Poznámka'" [value]="this.group?.note"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Pořadí'" [value]="this.group?.orderNum"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Nadřazená skupina'" [value]="parentServiceGroupName" [link]="parentServiceGroupLink"></app-text-input-readonly>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="onServiceGroupEdit($event)">Upravit skupinu</button>
        <button type="button" class="btn btn-danger ml-2" (click)="onServiceGroupDelete($event)">Smazat skupinu</button>
    </div>

    <!-- service groups list -->
    <app-sub-service-group-list></app-sub-service-group-list>

    <!-- service list -->
    <app-service-list-of-service-group></app-service-list-of-service-group>

</div>

<!-- modals -->
<app-edit-service-group [group]="group" (groupEdited)="onServiceGroupEditConfirmed($event)"></app-edit-service-group>
<app-delete-service-group [group]="group" (groupDeleted)="onServiceGroupDeleteConfirmed($event)"></app-delete-service-group>