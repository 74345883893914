import {Component, Input} from '@angular/core';
import {CustomChartComponent} from '../../../shared/custom-chart/custom-chart.component';
import {CustomBarChartComponent} from "../../../shared/custom-bar-chart/custom-bar-chart.component";

@Component({
  selector: 'app-service-cold-water-meter-detail',
  standalone: true,
  imports: [
    CustomChartComponent,
    CustomBarChartComponent
  ],
  templateUrl: './service-cold-water-meter-detail.component.html',
  styleUrls: ['./service-cold-water-meter-detail.component.scss']
})
export class ServiceColdWaterMeterDetailComponent {

  @Input() serviceId?: string | null;
  @Input() serviceName?: string;

  constructor() {
  }

}
