import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ButtonCloseDirective,
  ButtonDirective,
  ModalBodyComponent,
  ModalComponent,
  ModalFooterComponent,
  ModalHeaderComponent,
  ModalTitleDirective
} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {FileInputComponent} from "../../../shared/file-input/file-input.component";
import {UploadFileAndStartRequest} from "../../../../api";
import {SpotModelService} from "../../../../service/spot-model.service";
import {UiService} from "../../../../service/ui.service";
import {MODAL_NAME_UPLOAD_FILE} from "../../../../interface/modal-type.interface";

@Component({
  selector: 'app-upload-file-modal',
  standalone: true,
  imports: [
    ButtonCloseDirective,
    ButtonDirective,
    ModalBodyComponent,
    ModalComponent,
    ModalFooterComponent,
    ModalHeaderComponent,
    ModalTitleDirective,
    ReactiveFormsModule,
    TextInputComponent,
    NgIf,
    TextSelectComponent,
    FormModalComponent,
    FormModalComponent,
    TextInputComponent,
    FileInputComponent
  ],
  templateUrl: './file-upload-modal.component.html',
  styleUrl: './file-upload-modal.component.scss'
})
export class FileUploadModalComponent implements OnInit {

  @Output() uploaded: EventEmitter<void> = new EventEmitter<void>();
  @Input() taskId: string;

  form!: FormGroup;

  modalName = MODAL_NAME_UPLOAD_FILE // should be unique
  modalTitle = 'Nahrát soubor';

  constructor(
    private fb: FormBuilder,
    private service: SpotModelService,
    private uiService: UiService) {
    this.taskId = '';
  }

  ngOnInit() {
    this.initForm();
  }

  private initForm(): void {
    this.form = this.fb.group({
      file: ['', Validators.required]
    })
  }

  onClose(): void {
    this.initForm();
  }

  onSubmit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.uploadFileAndStart(this.taskId, this.createUploadFileAndStartRequest())
        .subscribe(() => {
          this.uploaded.emit();
          this.uiService.toggleModal(this.modalName);
          this.uiService.setSuccessAlert(`Soubor úspěšně nahrán.`);
        });
    }
  }

  createUploadFileAndStartRequest(): UploadFileAndStartRequest {
    const file = this.form.get('file')?.value as File;
    return {
      file: file
    } as UploadFileAndStartRequest
  }
}
