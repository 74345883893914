import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ActivatedRoute} from "@angular/router";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {TextSelectComponent} from "../../shared/text-select/text-select.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {AdminEditUserComponent} from "../admin-users-page/admin-edit-user/admin-edit-user.component";
import {UiService} from "../../../service/ui.service";
import {MODAL_NAME_DELETE_CONSUMPTION_PERIOD} from "../../../interface/modal-type.interface";
import {FormsModule} from "@angular/forms";
import {DropdownComponent} from "../../shared/dropdown/dropdown.component";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {IConsumptionPointDetail} from "../../../interface/consumption-point.interface";
import {ConsumptionPointService} from "../../../service/consumption-point.service";
import {EditConsumptionPointComponent} from "../admin-consumption-points/edit-consumption-point/edit-consumption-point.component";
import {DeleteConsumptionPointComponent} from "../admin-consumption-points/delete-consumption-point/delete-consumption-point.component";
import {translateVoltageKey} from "../../../util/translate/translate-key";
import {IConsumptionPeriod} from "../../../interface/consumption-period.interface";
import {forkJoin} from "rxjs";
import {DeleteConsumptionPeriodComponent} from "../admin-consumption-point-detail/delete-consumption-period/delete-consumption-period.component";
import {EditConsumptionPeriodComponent} from "../admin-consumption-point-detail/edit-consumption-period/edit-consumption-period.component";
import {DatePipe} from "../../../util/pipe/date.pipe";
import {ActionService, ActionType} from "../../../service/action.service";
import {EditCosnsumptionPeriodModalComponent} from "../admin-consumption-point-detail/edit-cosnsumption-period-modal/edit-cosnsumption-period-modal.component";
import {ModalDialogService} from "../../../service/modal-dialog.service";

@Component({
    selector: 'app-admin-consumption-period-detail-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        TextInputComponent,
        TextSelectComponent,
        TextInputReadonlyComponent,
        AdminEditUserComponent,
        FormsModule,
        NgForOf,
        DropdownComponent,
        EditConsumptionPointComponent,
        DeleteConsumptionPointComponent,
        DeleteConsumptionPeriodComponent,
        EditConsumptionPeriodComponent,
        DatePipe,
        NgIf,
    ],
    templateUrl: './admin-consumption-period-detail-page.component.html',
    styleUrl: './admin-consumption-period-detail-page.component.scss'
})
export class AdminConsumptionPeriodDetailPageComponent implements OnInit {

    // data
    periodId?: string; // id from the route
    pointId?: string; // id from the route
    period?: IConsumptionPeriod;
    point?: IConsumptionPointDetail;

    constructor(
        private consumptionPointService: ConsumptionPointService,
        private uiService: UiService,
        private navigationService: NavigationService,
        private actionService: ActionService,
        private modalDialogService: ModalDialogService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.periodId = params['consumptionPeriodId'];
            this.pointId = params['consumptionPointId'];
            if (this.periodId && this.pointId) {
                this.loadConsumptionData();
            }
        });
        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'reloadConsumptionPeriods') {
                this.loadConsumptionPeriod();
            }
        })
    }

    onConsumptionPeriodEdit(event: Event): void {
        event.preventDefault();
        const modalDialog = this.modalDialogService.openLg(EditCosnsumptionPeriodModalComponent);
        modalDialog.componentInstance.point = this.point;
        modalDialog.componentInstance.period = this.period;
    }

    onConsumptionPeriodDelete(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_DELETE_CONSUMPTION_PERIOD);
    }

    onPeriodDeleteConfirmed(period: IConsumptionPeriod) {
        this.navigationService.toAdminConsumptionPointDetail(this.pointId!);
    }

    protected readonly translateVoltageKey = translateVoltageKey;

    private loadConsumptionPeriod() {
        this.consumptionPointService.getConsumptionPeriod(this.periodId!).subscribe((data: IConsumptionPeriod) => {
            this.period = data;
        });
    }

    private loadConsumptionData(): void {
        const consumptionPeriod$ = this.consumptionPointService.getConsumptionPeriod(this.periodId!);
        const consumptionPointDetail$ = this.consumptionPointService.getConsumptionPoint(this.pointId!);

        forkJoin([consumptionPeriod$, consumptionPointDetail$]).subscribe(
            ([periodData, pointData]: [IConsumptionPeriod, IConsumptionPointDetail]) => {
                this.period = periodData;
                this.point = pointData;

                // Now that both observables are complete, call the breadcrumb service.
                this.adminBreadcrumbService.ofConsumptionPointPeriod(this.point, this.period);
            }
        );
    }
}
