import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IServiceGroup} from "../../interface/service.group.interface";

@Component({
  selector: 'app-service-group-select-item',
  standalone: true,
  imports: [],
  templateUrl: './service-group-select-item.component.html',
  styleUrl: './service-group-select-item.component.scss'
})
export class ServiceGroupSelectItemComponent {

    @Output() groupSelected: EventEmitter<IServiceGroup> = new EventEmitter<IServiceGroup>();
    @Input() group!: IServiceGroup;

    onGroupSelect(event: Event) {
        event.preventDefault();
        if (this.group.id) {
            this.groupSelected.emit(this.group)
        }
    }
}
