import * as i0 from '@angular/core';
import { EventEmitter, afterRender, booleanAttribute, numberAttribute, Component, ChangeDetectionStrategy, Input, HostBinding, Output, ViewChild, NgModule } from '@angular/core';
import merge from 'lodash-es/merge';
import { Chart, registerables } from 'chart.js';
import { customTooltips } from '@coreui/chartjs';
const _c0 = ["canvasElement"];
const _c1 = ["*"];
Chart.register(...registerables);
let nextId = 0;
class ChartjsComponent {
  get hostClasses() {
    return {
      'chart-wrapper': this.wrapper
    };
  }
  constructor(ngZone, renderer, changeDetectorRef) {
    this.ngZone = ngZone;
    this.renderer = renderer;
    this.changeDetectorRef = changeDetectorRef;
    /**
     * Enables custom html based tooltips instead of standard tooltips.
     * @type boolean
     * @default true
     */
    this.customTooltips = true;
    /**
     * ID attribute applied to the rendered canvas.
     * @type string
     */
    this.id = `c-chartjs-${nextId++}`;
    /**
     * The options object that is passed into the Chart.js chart.
     */
    this.options = {};
    /**
     * The plugins array that is passed into the Chart.js chart
     */
    this.plugins = [];
    /**
     * If true, will tear down and redraw chart on all updates.
     * @type boolean
     * @default false
     */
    this.redraw = false;
    /**
     * Chart.js chart type.
     * @type {'line' | 'bar' | 'radar' | 'doughnut' | 'polarArea' | 'bubble' | 'pie' | 'scatter'}
     */
    this.type = 'bar';
    /**
     * Put the chart into the wrapper div element.
     * @default true
     */
    this.wrapper = true;
    this.getDatasetAtEvent = new EventEmitter();
    this.getElementAtEvent = new EventEmitter();
    this.getElementsAtEvent = new EventEmitter();
    this.chartRef = new EventEmitter();
    // todo: verify afterRender / afterNextRender for chartjs (spec fails with 17.0.10)
    afterRender({
      write: () => {
        this.ctx = this.canvasElement?.nativeElement?.getContext('2d');
        this.chartRender();
      }
    });
  }
  ngAfterViewInit() {
    this.chartRender();
  }
  ngOnChanges(changes) {
    if (changes['data'] && !changes['data'].firstChange) {
      this.chartUpdate();
    }
  }
  ngOnDestroy() {
    this.chartDestroy();
  }
  handleClick($event) {
    if (!this.chart) {
      return;
    }
    const datasetAtEvent = this.chart.getElementsAtEventForMode($event, 'dataset', {
      intersect: true
    }, false);
    this.getDatasetAtEvent.emit(datasetAtEvent);
    const elementAtEvent = this.chart.getElementsAtEventForMode($event, 'nearest', {
      intersect: true
    }, false);
    this.getElementAtEvent.emit(elementAtEvent);
    const elementsAtEvent = this.chart.getElementsAtEventForMode($event, 'index', {
      intersect: true
    }, false);
    this.getElementsAtEvent.emit(elementsAtEvent);
  }
  chartDestroy() {
    this.chart?.destroy();
    this.chartRef.emit(undefined);
  }
  chartRender() {
    if (!this.canvasElement?.nativeElement || !this.ctx || this.chart) {
      return;
    }
    this.ngZone.runOutsideAngular(() => {
      const config = this.chartConfig();
      if (config) {
        this.chart = new Chart(this.ctx, config);
        this.ngZone.run(() => {
          this.renderer.setStyle(this.canvasElement.nativeElement, 'display', 'block');
          this.changeDetectorRef.markForCheck();
          this.chartRef.emit(this.chart);
        });
      }
    });
  }
  chartUpdate() {
    if (!this.chart) {
      return;
    }
    if (this.redraw) {
      this.chartDestroy();
      this.chartRender();
      return;
    }
    const config = this.chartConfig();
    if (this.options) {
      Object.assign(this.chart.options ?? {}, config.options ?? {});
    }
    if (!this.chart.config.data) {
      this.chart.config.data = {
        ...config.data
      };
      this.chartUpdateOutsideAngular();
    }
    if (this.chart) {
      Object.assign(this.chart.config.options ?? {}, config.options ?? {});
      Object.assign(this.chart.config.plugins ?? [], config.plugins ?? []);
      Object.assign(this.chart.config.data, config.data);
    }
    this.chartUpdateOutsideAngular();
  }
  chartUpdateOutsideAngular() {
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => {
        this.chart?.update();
        this.ngZone.run(() => {
          this.changeDetectorRef.markForCheck();
        });
      });
    });
  }
  chartToBase64Image() {
    return this.chart?.toBase64Image();
  }
  chartDataConfig() {
    return {
      labels: this.data?.labels ?? [],
      datasets: this.data?.datasets ?? []
    };
  }
  chartOptions() {
    return this.options ?? {};
  }
  chartConfig() {
    this.chartCustomTooltips();
    return {
      data: this.chartDataConfig(),
      options: this.chartOptions(),
      plugins: this.plugins,
      type: this.type
    };
  }
  chartCustomTooltips() {
    if (this.customTooltips) {
      const options = this.options;
      const plugins = this.options?.plugins;
      const tooltip = this.options?.plugins?.tooltip;
      this.options = merge({
        ...options,
        plugins: {
          ...plugins,
          tooltip: {
            ...tooltip,
            enabled: false,
            mode: 'index',
            position: 'nearest',
            external: customTooltips
          }
        }
      });
    }
  }
  static {
    this.ɵfac = function ChartjsComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ChartjsComponent)(i0.ɵɵdirectiveInject(i0.NgZone), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ChartjsComponent,
      selectors: [["c-chart"]],
      viewQuery: function ChartjsComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.canvasElement = _t.first);
        }
      },
      hostVars: 6,
      hostBindings: function ChartjsComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.hostClasses);
          i0.ɵɵstyleProp("height", ctx.height, "px")("width", ctx.width, "px");
        }
      },
      inputs: {
        customTooltips: [2, "customTooltips", "customTooltips", booleanAttribute],
        data: "data",
        height: [2, "height", "height", value => numberAttribute(value, undefined)],
        id: "id",
        options: "options",
        plugins: "plugins",
        redraw: [2, "redraw", "redraw", booleanAttribute],
        type: "type",
        width: [2, "width", "width", value => numberAttribute(value, undefined)],
        wrapper: [2, "wrapper", "wrapper", booleanAttribute]
      },
      outputs: {
        getDatasetAtEvent: "getDatasetAtEvent",
        getElementAtEvent: "getElementAtEvent",
        getElementsAtEvent: "getElementsAtEvent",
        chartRef: "chartRef"
      },
      exportAs: ["cChart"],
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c1,
      decls: 3,
      vars: 3,
      consts: [["canvasElement", ""], ["role", "img", 2, "display", "none", 3, "click", "height", "id", "width"]],
      template: function ChartjsComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "canvas", 1, 0);
          i0.ɵɵlistener("click", function ChartjsComponent_Template_canvas_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.handleClick($event));
          });
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("height", ctx.height)("id", ctx.id)("width", ctx.width);
        }
      },
      styles: [".chart-wrapper[_nghost-%COMP%]{display:block}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChartjsComponent, [{
    type: Component,
    args: [{
      selector: 'c-chart',
      exportAs: 'cChart',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<canvas\n  #canvasElement\n  (click)=\"handleClick($event)\"\n  [height]=\"height\"\n  [id]=\"id\"\n  [width]=\"width\"\n  role=\"img\"\n  style=\"display: none;\"\n>\n  <ng-content />\n  <!--  <ng-container *ngTemplateOutlet=\"fallbackContent\"/>-->\n</canvas>\n",
      styles: [":host.chart-wrapper{display:block}\n"]
    }]
  }], () => [{
    type: i0.NgZone
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ChangeDetectorRef
  }], {
    customTooltips: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    data: [{
      type: Input
    }],
    height: [{
      type: HostBinding,
      args: ['style.height.px']
    }, {
      type: Input,
      args: [{
        transform: value => numberAttribute(value, undefined)
      }]
    }],
    id: [{
      type: Input
    }],
    options: [{
      type: Input
    }],
    plugins: [{
      type: Input
    }],
    redraw: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    type: [{
      type: Input
    }],
    width: [{
      type: HostBinding,
      args: ['style.width.px']
    }, {
      type: Input,
      args: [{
        transform: value => numberAttribute(value, undefined)
      }]
    }],
    wrapper: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    getDatasetAtEvent: [{
      type: Output
    }],
    getElementAtEvent: [{
      type: Output
    }],
    getElementsAtEvent: [{
      type: Output
    }],
    chartRef: [{
      type: Output
    }],
    canvasElement: [{
      type: ViewChild,
      args: ['canvasElement']
    }],
    hostClasses: [{
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class ChartjsModule {
  static {
    this.ɵfac = function ChartjsModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ChartjsModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ChartjsModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ChartjsModule, [{
    type: NgModule,
    args: [{
      imports: [ChartjsComponent],
      exports: [ChartjsComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of coreui-angular-chartjs
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ChartjsComponent, ChartjsModule };
