import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SidebarModule} from '@coreui/angular-pro';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {SideBarComponent} from './component/side-bar/side-bar.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import appRoutes from './app.routes';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {ApiModule, Configuration} from './api';
import {environment} from '../environment/environment';
import {ErrorModalComponent} from "./component/general/error-modal/error-modal.component";
import {AlertContainerComponent} from "./component/general/alert-container/alert-container.component";
import {InfoModalComponent} from "./component/shared/info-modal/info-modal.component";
import {
  ChangeCompanyContextModalComponent
} from "./component/general/change-company-context-modal/change-company-context-modal.component";
import {AdminBreadcrumbComponent} from "./component/shared/admin-breadcrumb/admin-breadcrumb.component";
import {HttpInterceptorService} from "./service/loader/httpInterceptor.service";
import {LoaderComponent} from "./component/shared/loader/loader.component";

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [BrowserModule,
        RouterModule.forRoot(appRoutes),
        ApiModule.forRoot(() => {
            return new Configuration({
                basePath: environment.baseUrl,
            });
        }),
        BrowserAnimationsModule,
        SidebarModule,
        SideBarComponent,
        DashboardComponent,
        ErrorModalComponent,
        AlertContainerComponent,
        InfoModalComponent,
        ChangeCompanyContextModalComponent,
        AdminBreadcrumbComponent,
        LoaderComponent], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {
}
