import {Injectable} from "@angular/core";
import {ApiService} from "../api.service";
import {Observable} from "rxjs";
import {GetValveHeatingBranchSettingsRequest} from "../../api";
import {map} from "rxjs/operators";
import {NoResponseMapper} from "../../mapper/no-response.mapper";
import {GetValveHeatingBranchSettingsResponse, IValveHeatingBranchSettings, ValveHeatingBranchSettingsMapper} from "./valve-heating-branch-settings.mapper";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root',
})
export class ValveHeatingBranchSettingsService {
  constructor(private apiService: ApiService, private valveHeatingBranchSettingsMapper: ValveHeatingBranchSettingsMapper) {
  }

  getSettings(serviceId: string): Observable<IValveHeatingBranchSettings> {
    const body: GetValveHeatingBranchSettingsRequest = {serviceId};
    return this.apiService.post('/box/service/get-valve-heating-branch-settings', body)
        .pipe(map((res: GetValveHeatingBranchSettingsResponse) => this.valveHeatingBranchSettingsMapper.mapResponseToSettings(res)))
  }

  setSettings(serviceId: string, form: FormGroup): Observable<void> {

    const request = this.valveHeatingBranchSettingsMapper.mapFormToRequest(serviceId, form);
    return this.apiService.post("/box/service/set-valve-heating-branch-settings", request).pipe(
      map(() => NoResponseMapper.map())
    );
  }
}
