import { Component } from '@angular/core';

@Component({
  selector: 'app-is-admin',
  standalone: true,
  imports: [],
  templateUrl: './is-admin.component.html',
  styleUrl: './is-admin.component.scss'
})
export class IsAdminComponent {

}
