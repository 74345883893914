import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-rotary-switch',
  standalone: true,
  templateUrl: './rotary-switch.component.html',
  imports: [
    NgForOf
  ],
  styleUrl: './rotary-switch.component.scss'
})
export class RotarySwitchComponent implements OnInit {
  @Input() options: string[] = [];
  @Input() selectedOption?: string;
  @Output() selectionChanged = new EventEmitter<number>();

  selectedIndex: number = 0;

  ngOnInit() {
    this.selectedIndex = this.selectedOption ? this.options.indexOf(this.selectedOption) : 0;
    this.fixOptionsOrder();
  }

  fixOptionsOrder() {
    const firstElement = this.options.shift();

    if (firstElement !== undefined) {
      this.options.push(firstElement);
    }
  }

  get rotationAngle(): number {
    return (360 / this.options.length) * (this.selectedIndex);
  }

  rotateClockwise() {
    this.selectedIndex += 1;
    const index = this.getOuterIndex(this.options.length, this.selectedIndex);
    this.selectionChanged.emit(index);
  }

  rotateCounterClockwise() {
    this.selectedIndex -= 1;
    const index = this.getOuterIndex(this.options.length, this.selectedIndex);
    this.selectionChanged.emit(index);
  }

  getOuterIndex(length: number, index: number): number {
    const adjustedIndex = (length + index % length) % length;
    return (length + adjustedIndex - 1) % length;
  }

  getLabelPosition(index: number) {
    const angle = (((360 / this.options.length)) * index) + this.getAngleOffset();
    const radius = 110; // Radius for the labels
    const x = Math.cos((angle * Math.PI) / 180) * radius;
    const y = Math.sin((angle * Math.PI) / 180) * radius;
    return {
      top: `calc(50% + ${y}px)`,
      left: `calc(50% + ${x}px)`,
    };
  }

  // TODO Optimize this with calculation
  getAngleOffset(): number {
    if (this.options.length === 3) {
      return 30;
    } else if (this.options.length === 5) {
      return -20;
    } else if (this.options.length === 6) {
      return -30;
    } else {
      return 0;
    }
  }
}
