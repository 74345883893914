import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import { ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {DeleteModalComponent} from "../../../shared/delete-modal/delete-modal.component";
import {UiService} from "../../../../service/ui.service";
import {MODAL_NAME_DELETE_CONSUMPTION_POINT} from "../../../../interface/modal-type.interface";
import {ConsumptionPointService} from "../../../../service/consumption-point.service";
import {IConsumptionPoint, IConsumptionPointDetail} from "../../../../interface/consumption-point.interface";

@Component({
  selector: 'app-delete-consumption-point',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        DeleteModalComponent
    ],
  templateUrl: './delete-consumption-point.component.html',
  styleUrl: './delete-consumption-point.component.scss'
})
export class DeleteConsumptionPointComponent {

    @Input() point?: IConsumptionPoint;
    @Output() deleted: EventEmitter<IConsumptionPoint> = new EventEmitter<IConsumptionPoint>();

    modalName = MODAL_NAME_DELETE_CONSUMPTION_POINT; // should be unique
    modalTitle = 'Smazat odběrné místo?';

    constructor(private consumptionPointService: ConsumptionPointService, private uiService: UiService) {
    }

    onConfirm() {
        this.consumptionPointService.deleteConsumptionPoint(this.point!.consumptionPointId).subscribe(() => {
            this.deleted.emit(this.point);
            this.uiService.toggleModal(this.modalName);
            this.uiService.setSuccessAlert(`Odběrné místo ${this.point?.ean ?? ''} úspěšně smazáno.`);
        })
    }

}
