<div class="table-container">
    <table class="table table-striped table-hover">
        <thead>
        <tr>
            <app-table-header *ngFor="let conf of config" [config]="conf" [sortByField]="sortByField" (sortByFieldSelected)="onSortByFieldSelected($event)" class="contents"></app-table-header>
        </tr>
        </thead>
        <tbody>
        <app-table-row *ngFor="let row of getData()" [config]="config" [rowData]="row" class="contents" (rowClicked)="onRowClick($event)"></app-table-row>
        </tbody>
    </table>
</div>