<div *ngIf="error.state === 'show'" class="modal show fade" tabindex="-1">
    <div class="modal-dialog top-right-extra-small" [ngClass]="extraSmallScreen ? 'top-right-extra-small' : 'top-right'">
        <div class="callout callout-danger modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Chyba http volání</h5>
                <button type="button" class="btn-close" data-coreui-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
            </div>
            <div class="modal-body">
                <div><strong>Message: : </strong>{{error.message}}</div>
                <div><strong>Timestamp: </strong>{{error.timestamp}}</div>
                <div><strong>Status: </strong>{{error.status}}</div>
                <div><strong>ErrorId: </strong>{{error.errorId}}</div>
                <div><strong>Url: </strong>{{error.url}}</div>
                <div *ngIf="error.code"><strong>Code: </strong>{{error.code}}</div>
            </div>
        </div>
    </div>
</div>