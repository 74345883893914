<div class="modal-header">
    <h4 class="modal-title">{{ title }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()" tabindex="-1"></button>
</div>
<div class="modal-body mt-2">
    <app-edit-consumption-period
            [point]="point"
            [period]="period"
            (edited)="close()"
    >
    </app-edit-consumption-period>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="close()">Zavřít</button>
    <button type="button" class="btn btn-primary" (click)="accept()">Uložit</button>
</div>
