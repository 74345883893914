import {Component, Input} from '@angular/core';
import {StatusEnum} from "../../../interface/spotbox.interface";
import {NgClass} from "@angular/common";
import {TooltipDirective} from "@coreui/angular-pro";

@Component({
  selector: 'app-status',
  standalone: true,
    imports: [
        NgClass,
        TooltipDirective
    ],
  templateUrl: './status.component.html',
  styleUrl: './status.component.scss'
})
export class StatusComponent {

    @Input() status?: StatusEnum;

    getStatusClass(): string {
        if(this.status) {
            return this.status;
        }
        return '';
    }

    getLabel(): string {
        if (this.status === 'connected') {
            return 'Připojeno'
        }
        if (this.status === 'disconnected') {
            return "Odpojeno"
        }
        if (this.status === 'error') {
            return "error";
        }

        return this.status || "Žádný stav";
    }

}
