<!-- service list -->
<div class="box-shadow mt-3 min-h-44">
    <h5>Služby servisní skupiny</h5>
    <div class="table-container">
        <app-table [data]="services" [config]="config" (rowClicked)="onServiceRowClicked($event)"></app-table>
    </div>
    <app-dropdown [items]="getServicePossibilities()" (itemSelected)="onServiceAddedToGroup($event)" [buttonText]="'Přiřadit službu'"></app-dropdown>
</div>

<!-- modals -->
<app-admin-edit-service [service]="serviceToBeEdited" (serviceEdited)="onEditServiceConfirmed($event)"></app-admin-edit-service>