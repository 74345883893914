<div class="slider-container">
  <input
    type="range"
    min="{{min}}"
    max="{{max}}"
    step="{{step}}"
    [ngModel]="form?.value"
    (input)="onInput($event)"
    class="slider"
  />
  <div class="slider-value">{{ form?.value }}</div>
</div>
