import { Component, Input } from '@angular/core';
import { CustomLineChartComponent } from '../../../shared/custom-line-chart/custom-line-chart.component';

@Component({
  selector: 'app-service-valve-heating-branch-detail',
  standalone: true,
  imports: [
    CustomLineChartComponent
  ],
  templateUrl: './service-valve-heating-branch-detail.component.html',
  styleUrls: ['./service-valve-heating-branch-detail.component.scss']
})
export class ServiceValveHeatingBranchDetailComponent {

  @Input() serviceId?: string | null;
  @Input() serviceName?: string;

  constructor() {
  }

}
