import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {IConsumptionPeriod} from "../../../../interface/consumption-period.interface";
import {ConsumptionPointService} from "../../../../service/consumption-point.service";
import {CustomDatePickerInputComponent} from "../../../shared/custom-date-picker-input/custom-date-picker-input.component";
import {IConsumptionPoint} from "../../../../interface/consumption-point.interface";
import {CustomValidators} from "../../../../util/validator/custom.validators";
import {TextInputReadonlyComponent} from "../../../shared/text-input-readonly/text-input-readonly.component";
import {translateTariffKey} from "../../../../util/translate/translate-key";
import {DatePipe} from "../../../../util/pipe/date.pipe";
import {ActionService} from "../../../../service/action.service";

@Component({
  selector: 'app-edit-consumption-period',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        CustomDatePickerInputComponent,
        TextInputReadonlyComponent
    ],
  templateUrl: './edit-consumption-period.component.html',
  styleUrl: './edit-consumption-period.component.scss'
})
export class EditConsumptionPeriodComponent implements OnInit {
    @Input() period?: IConsumptionPeriod;
    @Input() point?: IConsumptionPoint;
    @Output() edited: EventEmitter<IConsumptionPeriod> = new EventEmitter<IConsumptionPeriod>();

    form!: FormGroup;
    distributionTariffs: ISelectOption[] = [];

    constructor(
        private fb: FormBuilder,
        private consumptionPointService: ConsumptionPointService,
        private actionService: ActionService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
        this.consumptionPointService.getCachedDistributionTariffs().subscribe((data: ISelectOption[]) => {
            this.distributionTariffs = data;
        })
    }

    // ngOnChanges(changes: SimpleChanges) {
    //     if (changes['period']) {
    //         const period: IConsumptionPeriod = changes['period'].currentValue as IConsumptionPeriod;
    //         if (period) {
    //             this.form = this.fb.group({
    //                 startPeriod: [period?.startPeriod, Validators.required],
    //                 flatRateDistribution: [period?.flatRateDistribution, [Validators.required, CustomValidators.isNumber]],
    //                 rate1VTDistributionMWh: [period?.rate1VTDistributionMWh, CustomValidators.isNumber],
    //                 rate2NTDistributionMWh: [period?.rate2NTDistributionMWh, CustomValidators.isNumber],
    //                 spotDistributionMwh: [period?.spotDistributionMwh, CustomValidators.isNumber],
    //                 distributionTariff: period?.distributionTariff,
    //                 breaker: period?.breaker,
    //                 rate1VTPowerMWh: [period?.rate1VTPowerMWh, CustomValidators.isNumber],
    //                 rate2NTPowerMWh: [period?.rate2NTPowerMWh, CustomValidators.isNumber],
    //                 spotPowerMWh: [period?.spotPowerMWh, CustomValidators.isNumber],
    //                 flatRatePower: [period?.flatRatePower, [Validators.required, CustomValidators.isNumber]],
    //             });
    //         }
    //     }
    // }

    private initForm(): void {
        this.form = this.fb.group({
            startPeriod: [this.period?.startPeriod, Validators.required],
            flatRateDistribution: [this.period?.flatRateDistribution, [Validators.required, CustomValidators.isNumber]],
            rate1VTDistributionMWh: [this.period?.rate1VTDistributionMWh, CustomValidators.isNumber],
            rate2NTDistributionMWh: [this.period?.rate2NTDistributionMWh, CustomValidators.isNumber],
            spotDistributionMwh: [this.period?.spotDistributionMwh, CustomValidators.isNumber],
            distributionTariff: this.period?.distributionTariff,
            breaker: this.period?.breaker,
            rate1VTPowerMWh: [this.period?.rate1VTPowerMWh, CustomValidators.isNumber],
            rate2NTPowerMWh: [this.period?.rate2NTPowerMWh, CustomValidators.isNumber],
            spotPowerMWh: [this.period?.spotPowerMWh, CustomValidators.isNumber],
            flatRatePower: [this.period?.flatRatePower, [Validators.required, CustomValidators.isNumber]],
        });
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const period: IConsumptionPeriod = {...this.period, ...this.form.value} as IConsumptionPeriod;

            this.consumptionPointService.editConsumptionPeriod(period).subscribe(() => {
                this.edited.emit(period);
                const from = new DatePipe().transform(period.startPeriod);
                this.uiService.setSuccessAlert(`Období od ${from} úspěšně upraveno.`);
                this.actionService.reloadConsumptionPeriods();
            })
        }
    }

    protected readonly translateTariffKey = translateTariffKey;
}
