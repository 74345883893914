import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

export class ModalUtils {
  static createDefaultModalOptions(size?: 'sm' | 'lg' | 'xl'): NgbModalOptions {
    const options = { keyboard: false, backdrop: 'static', centered: true } as NgbModalOptions;
    if (size) {
      options.size = size;
    }
    return options;
  }

  static createModalOptionsForVariableDetail(parentElement: HTMLElement): NgbModalOptions {
    return {
      keyboard: true,
      backdrop: 'static',
      centered: false,
      container: parentElement
    } as NgbModalOptions;
  }
}
