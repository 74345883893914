<!-- service groups list -->
<div class="box-shadow mt-3 min-h-44">
    <h5>Podřízené servisní skupiny</h5>
    <div class="table-container">
        <app-table [data]="subServiceGroups" [config]="config" (rowClicked)="onServiceGroupRowClicked ($event)"></app-table>
    </div>
    <div class="d-flex" style="max-height: 38px">
        <button type="button" class="btn btn-primary mr-2" (click)="onServiceGroupAdd($event)">Vytvořit novou skupinu</button>
        <app-dropdown [items]="getServiceGroupsPossibilities()" (itemSelected)="onServiceGroupAddedToGroup($event)" [buttonText]="'Přiřadit existující skupinu'"></app-dropdown>
    </div>
</div>

<!-- modals -->
<app-add-service-group [parentServiceGroupId]="groupId" (groupCreated)="onServiceGroupAddSubmitted()"></app-add-service-group>
<app-edit-service-group [group]="serviceGroupToBeEdited" [modalName]="MODAL_NAME_EDIT_SERVICE_GROUP_2" (groupEdited)="onServiceGroupEditConfirmed($event) "></app-edit-service-group>