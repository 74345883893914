<!--<div class="container">-->


    <!-- user detail -->
    <div class="container">
    <div class="box-shadow">
        <h3>Firma: {{ this.company?.name }}</h3>
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Jméno'" [value]="this.company?.name"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Adresa'" [value]="this.company?.address"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'IČ'" [value]="this.company?.ic"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'DIČ'" [value]="this.company?.dic"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Email'" [value]="this.company?.email"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Telefon'" [value]="this.company?.phone"></app-text-input-readonly>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="onCompanyEdit($event)">Upravit firmu</button>
    </div>
    </div>


    <app-admin-device-page [isPartOfMainPage]="true"></app-admin-device-page>
    <app-admin-service-groups [isPartOfMainPage]="true"></app-admin-service-groups>
    <app-admin-users-page [isPartOfOnMainPage]="true"></app-admin-users-page>

<!--    &lt;!&ndash; service groups &ndash;&gt;-->
<!--    <div class="box-shadow mt-3 min-h-44">-->
<!--        <h5 class="pt-3">Servisní skupiny</h5>-->
<!--        <app-table [data]="userServiceGroups" [config]="config" (rowClicked)="onServiceGroupClicked($event)"></app-table>-->
<!--        <app-dropdown [items]="getPossibilities()" (itemSelected)="onGroupSelected($event)" [buttonText]="'Přidat uživatele'"  ></app-dropdown>-->
<!--    </div>-->

    <!-- modals   -->
    <app-edit-company [company]="company" (companyEdited)="onCompanyEditConfirmed($event)"></app-edit-company>
<!--</div>-->