import {Component, Input, OnInit} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {UiService} from "../../../service/ui.service";

@Component({
  selector: 'app-info-modal',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        NgIf,
        NgForOf,
        NgOptimizedImage
    ],
  templateUrl: './info-modal.component.html',
  styleUrl: './info-modal.component.scss'
})
export class InfoModalComponent implements OnInit{

    // @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    @Input() title: string = '';
    // @Input() body: string = '';
    @Input() name: string = '';

    constructor(private uiService: UiService) {
    }

    ngOnInit() {
        this.uiService.openedModalToggle$.subscribe((name: string): void => {
            if (this.name === name) {
                this.toggleModal();
            }
        })
    }

    public modalVisible = false;

    toggleModal() {
        this.modalVisible = !this.modalVisible;

        if(!this.modalVisible){
            // this.closed.emit();
            this.uiService.closeModal(); //fix modal is not reopened on revisit
        }
    }

    handleModalChange(event: any) {
        this.modalVisible = event;
        if(!this.modalVisible) {
            // this.closed.emit();
            this.uiService.closeModal(); //fix modal is not reopened on revisit
        }
    }
}
