<nav>
    <ng-container *ngIf="breadcrumbs.length">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last">
            <ng-container *ngIf="breadcrumb.url; else noLink">
                <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
                <span *ngIf="!last"> &gt; </span>
            </ng-container>
            <ng-template #noLink>
                {{ breadcrumb.label }}
                <span *ngIf="!last"> &gt; </span>
            </ng-template>
        </ng-container>
    </ng-container>
</nav>
