import {ConsumptionPointResponse, DistributorResponse, GetConsumptionPointPeriodResponse, GetConsumptionPointPeriodsResponse, GetConsumptionPointResponse, GetConsumptionPointsResponse, GetDistributorsResponse, GetUserResponse, GetUsersResponse, LoginResponse, UserResponse} from "../api";
import {IUserContext} from "../interface/user.context.interface";
import {IUser} from "../interface/user.interface";
import {IConsumptionPoint, IConsumptionPointDetail} from "../interface/consumption-point.interface";
import {ISelectOption} from "../component/shared/text-select/text-select.component";
import {IConsumptionPeriod} from "../interface/consumption-period.interface";

export class ConsumptionPointMapper {

    private static mapConsumptionPoint(res: ConsumptionPointResponse): IConsumptionPoint {
        return {
            consumptionPointId: res.consumptionPointId,
            ean: res.ean,
            eic: res.eic,
            address: res.address,
            gps: res.gps,
            electricityDistributor: res.electricityDistributor,
            voltageLevel: res.voltageLevel,
        } as IConsumptionPoint;
    }

    static mapConsumptionPointRes(res: GetConsumptionPointResponse): IConsumptionPointDetail {
        return {
            consumptionPointId: res.consumptionPointId,
            companyId: res.companyId,
            ean: res.ean,
            eic: res.eic,
            address: res.address,
            gps: res.gps,
            electricityDistributor: res.electricityDistributor,
            voltageLevel: res.voltageLevel
        } as IConsumptionPointDetail;
    }

    static mapConsumptionPoints(res: GetConsumptionPointsResponse): IConsumptionPoint[] {
        if (!res || !res.list) {
            return [];
        }

        return res.list.map((point: ConsumptionPointResponse) => {
            return this.mapConsumptionPoint(point);
        })
    }

    static mapConsumptionPeriods(res: GetConsumptionPointPeriodsResponse): IConsumptionPeriod[] {
        if (!res || !res.periods) {
            return [];
        }

        return res.periods.map((point: GetConsumptionPointPeriodResponse) => {
            return this.mapConsumptionPeriod(point);
        })
    }

    static mapConsumptionPeriod(res: GetConsumptionPointPeriodResponse): IConsumptionPeriod {
        return {
            consumptionPointPeriodId: res.consumptionPointPeriodId,
            startPeriod: res.startPeriod,
            tariff: res.tariff,
            breaker: res.breaker,
            distributionTariff: res.distributionTariff,
            flatRateDistribution: res.flatRateDistribution,
            rate1VTDistributionMWh: res.rate1VTDistributionMWh,
            rate2NTDistributionMWh: res.rate2NTDistributionMWh,
            spotDistributionMwh: res.spotDistributionMwh,
            flatRatePower: res.flatRatePower,
            rate1VTPowerMWh: res.rate1VTPowerMWh,
            rate2NTPowerMWh: res.rate2NTPowerMWh,
            spotPowerMWh: res.spotPowerMWh,
        } as IConsumptionPeriod;
    }

    private static mapElectricityDistributor(res: DistributorResponse): ISelectOption {
        return {
            name: res.value,
            key: res.key,
        } as ISelectOption;
    }

    static mapDistributors(res: GetDistributorsResponse): ISelectOption[] {
        if (!res || !res.list) {
            return [];
        }

        return res.list.map((distributor: DistributorResponse) => {
            return this.mapElectricityDistributor(distributor);
        })
    }

    // static mapUserDetail(res: GetUserResponse): IUser{
    //     return {
    //         userId: res.userId,
    //         email: res.email,
    //         companyId: res.companyId,
    //         username: res.username,
    //         role: res.role,
    //     } as IUser;
    // }
    //
    // static mapUser(res: UserResponse): IUser{
    //     return {
    //         userId: res.userId,
    //         email: res.email,
    //         companyId: res.companyId,
    //         username: res.username,
    //         role: res.role,
    //     } as IUser;
    // }
}