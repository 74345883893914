import {Component, OnInit} from '@angular/core';
import {IServiceGroupForSidebar} from "../../../interface/service.group.interface";
import {ServiceGroupService} from "../../../service/service-group.service";
import {ActionService, ActionType} from "../../../service/action.service";
import {UserService} from "../../../service/user.service";
import {NgForOf} from "@angular/common";
import {SideBarGroupComponent} from "../side-bar-group/side-bar-group.component";
import {UiService} from "../../../service/ui.service";
import {NavigationService} from "../../../service/navigation.service";

@Component({
  selector: 'app-side-bar-groups-root',
  standalone: true,
    imports: [
        NgForOf,
        SideBarGroupComponent,
    ],
  templateUrl: './side-bar-groups-root.component.html',
  styleUrl: './side-bar-groups-root.component.scss'
})
export class SideBarGroupsLvl0Component implements OnInit {


    serviceGroups: IServiceGroupForSidebar[] = [];

    openedServiceGroupRemembered: string = '';

    constructor(
        private serviceGroupService: ServiceGroupService,
        private actionService: ActionService,
        private navigationService: NavigationService,
        private uiService: UiService,
        private userService: UserService,
    ) {
    }

    ngOnInit() {
        this.loadServiceGroups();

        this.uiService.sidebarServiceGroupOpened$.subscribe((groupId: string) => {
            if (groupId) {
                if (this.serviceGroups.length === 0) {
                    // service groups not loaded yet
                    this.openedServiceGroupRemembered = groupId;
                } else {
                    // service groups loaded
                    const group = this.serviceGroupService.findServiceGroup(groupId, this.serviceGroups);
                    if (group) {
                        // make just direct children visible
                        group!.children.forEach(child => {
                            child.visible = true;
                        })
                        // make all parents visible if needed (F5, come to app via link)
                        if (group?.parentServiceGroupId) {
                            const parent = this.serviceGroupService.findServiceGroup(group?.parentServiceGroupId, this.serviceGroups);
                            if (parent && (!parent.visible || !parent.opened)) {
                                parent!.visible = true;
                                parent!.opened = true;
                                this.uiService.setSidebarServiceGroupOpened(group?.parentServiceGroupId);
                            }
                        }
                        // setup this node opened and visible
                        group!.opened = true;
                        group!.visible = true;
                    }
                }
            }
        });

        this.uiService.sidebarServiceGroupClosed$.subscribe((groupId: string) => {
            if (groupId) {
                const group = this.serviceGroupService.findServiceGroup(groupId, this.serviceGroups);

                if (group){
                    // make all children hidden
                    group?.children.forEach(child => {
                        child.visible = false;
                        child.opened = false;
                        this.uiService.setSidebarServiceGroupClosed(child?.id);
                    })

                    // setup this node closed
                    group!.opened = false;
                }
            }
        });

        this.userService.companyChanged$.subscribe(() => {
            this.loadServiceGroups();
        });

        this.actionService.action$.subscribe((type: ActionType) => {
            if (type === 'reloadServiceGroups') {
                this.loadServiceGroups();
            }
        })
    }

    sortedServiceGroups(): IServiceGroupForSidebar[] {
        // Sort the array based on the orderNum property in ascending order
        return this.serviceGroups.sort((a, b) => a.orderNum - b.orderNum);
    }

    private loadServiceGroups(): void {
        this.serviceGroupService.getServiceGroupsForSidebar()
            .subscribe((serviceGroups: IServiceGroupForSidebar[]) => {
                this.serviceGroups = serviceGroups;

                if (this.openedServiceGroupRemembered) {
                    this.uiService.setSidebarServiceGroupOpened(this.openedServiceGroupRemembered);
                }

                this.navigationService.resendCurrentNavItem();
            });
    }

}
