import {booleanAttribute, Component, Input} from '@angular/core';
import {DateTimePipe} from "../../../util/pipe/date-time.pipe";
import {NgIf} from "@angular/common";
import {Router} from "@angular/router";
import {DatePipe} from "../../../util/pipe/date.pipe";

@Component({
  selector: 'app-text-input-readonly',
  standalone: true,
    imports: [
        DateTimePipe,
        NgIf
    ],
  templateUrl: './text-input-readonly.component.html',
  styleUrl: './text-input-readonly.component.scss'
})
export class TextInputReadonlyComponent {
    @Input() label!: string;
    @Input() value?: string | number;
    @Input() link?: string;
    @Input({transform: booleanAttribute}) date: boolean = false;

    constructor(private router: Router) {
    }

    onClick(event: Event): void {
        event.preventDefault();
        if(this.link) {
            this.router.navigate([this.link]);
        }
    }

    getValue(): string | number | undefined {
        if(this.date) {
            return new DatePipe().transform(this.value as string);
        }

        return this.value;
    }

}
