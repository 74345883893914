<tr (click)="onRowClick($event)">
    <td class="row-container">
        <div>
            <app-status [status]="service.status"></app-status>
        </div>
    </td>
    <td>
        {{ service.name }}
    </td>
    <td>
        {{ service.serviceName }}
    </td>
    <td>
        {{ resolveType() }}
    </td>
    <td>
        {{ service.orderNum }}
    </td>
    <td>
        {{ service.note }}
    </td>
    <td class="text-end align-middle">
        <button type="button" class="btn btn-danger my-1" (click)="onRemoveClick($event)">Odebrat</button>
    </td>
</tr>