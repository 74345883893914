import {Component, Input, OnInit} from '@angular/core';
import {IUser} from "../../../interface/user.interface";
import {UserService} from "../../../service/user.service";
import {NgForOf} from "@angular/common";
import {AdminAddUserComponent} from "./admin-add-user/admin-add-user.component";
import {UiService} from "../../../service/ui.service";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {DeleteModalComponent} from "../../shared/delete-modal/delete-modal.component";
import {AdminEditUserComponent} from "./admin-edit-user/admin-edit-user.component";
import {AdminDeleteUserComponent} from "./admin-delete-user/admin-delete-user.component";
import {MODAL_NAME_ADD_USER, MODAL_NAME_DELETE_USER, MODAL_NAME_EDIT_USER} from "../../../interface/modal-type.interface";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {translateUserRoleKey} from "../../../util/translate/translate-key";

@Component({
  selector: 'app-admin-users-page',
  standalone: true,
    imports: [
        NgForOf,
        TableComponent,
        DeleteModalComponent,
        AdminAddUserComponent,
        AdminEditUserComponent,
        AdminDeleteUserComponent
    ],
  templateUrl: './admin-users-page.component.html',
  styleUrl: './admin-users-page.component.scss'
})
export class AdminUsersPageComponent implements OnInit{

    @Input() isPartOfOnMainPage = false;

    users: IUser[] = [];

    config: ITableConfig[] = [
        {field: 'username', label: 'Jméno'},
        {field: 'email', label: 'Email'},
        {field: 'role', label: 'Role', translateKey: translateUserRoleKey.bind(this)},
        {
            conditionallyHide: this.isNotUserPartner.bind(this),
            specialComponent: {
                type: 'edit',
                onClick: this.onUserEdited.bind(this)
            }
        },
        {
            conditionallyHide: this.isNotUserPartner.bind(this),
            specialComponent: {
                type: 'delete',
                onClick: this.onUserDelete.bind(this)
            }
        },
    ];

    addUserModalName = MODAL_NAME_ADD_USER;

    deleteUserModalName = MODAL_NAME_DELETE_USER;
    userToBeDeleted?: IUser;

    editUserModalName = MODAL_NAME_EDIT_USER;
    userToBeEdited?: IUser;

    constructor(private userService: UserService, private uiService: UiService, private navigationService: NavigationService, private adminBreadcrumbService: AdminBreadcrumbService) {
    }

    ngOnInit(): void {
        this.loadData();
        this.navigationService.rememberLastTabVisited();
    }

    private loadData(): void {
        this.userService.getUsersFromMyCompany().subscribe((data: IUser[]) => {
            this.users = data;
            if (!this.isPartOfOnMainPage) {
                this.adminBreadcrumbService.ofUsers();
            }
        })
    }

    onRowClicked(user: IUser): void {
        this.navigationService.toAdminUserDetail(user.userId);
    }

    onUserAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(this.addUserModalName);
    }

    onUserAddSubmitted() {
        this.loadData();
    }

    onUserDelete(user: IUser): void {
        this.userToBeDeleted = user;
        this.uiService.toggleModal(this.deleteUserModalName);
    }

    onUserDeleteConfirmed(user: IUser) {
        this.loadData();
    }

    onUserEdited(user: IUser): void {
        this.userToBeEdited = user;
        this.uiService.toggleModal(this.editUserModalName);
    }

    onUserEditConfirmed(user: IUser): void {
        this.loadData();
    }

    isNotUserPartner(user: IUser): boolean {
        return !this.userService.isAdmin() &&  user.role !== 'UserPartner'
    }

    isMe(user: IUser): boolean {
        return user.username === this.userService.getUserContext().username;
    }
}
