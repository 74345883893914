<div class="container">
    <div class="box-shadow my-3 min-h-44 pb-3">
        <h3>Přehled EM boxů</h3>
        <app-table [config]="config" [data]="boxDevices" (rowClicked)="onRowClicked($event)"></app-table>
        <button type="button" class="btn btn-primary" (click)="onBoxDeviceAdd($event)">Přidat EM box</button>
    </div>



    <!-- modals  -->
    <app-admin-add-device (boxCreated)="onBoxDeviceAddSubmitted()"></app-admin-add-device>
    <app-admin-edit-device [device]="boxDeviceToBeEdited" (deviceEdited)="onBoxDeviceEditConfirmed($event)"></app-admin-edit-device>
    <app-admin-delete-device [boxDevice]="boxDeviceToBeDeleted" (boxDeviceDeleted)="onBoxDeviceDeleteConfirmed($event)"></app-admin-delete-device>
</div>