import {Component, Input} from '@angular/core';
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {DateTimePipe} from "../../../util/pipe/date-time.pipe";

@Component({
  selector: 'app-date-readonly-input',
  standalone: true,
    imports: [
        ReactiveFormsModule,
        DateTimePipe
    ],
  templateUrl: './date-readonly-input.component.html',
  styleUrl: './date-readonly-input.component.scss'
})
export class DateReadonlyInputComponent {

    @Input() value?: string;
    @Input() label!: string;

}
