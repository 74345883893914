import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTime',
  standalone: true
})
export class DateTimePipe implements PipeTransform {

    transform(value?: string): string {
        if (!value) return '';

        const date = new Date(value);
        const today = new Date();

        const hours = this.padZero(date.getHours());
        const minutes = this.padZero(date.getMinutes());
        const seconds = this.padZero(date.getSeconds());
        const day = date.getDate();
        const month = date.getMonth() + 1; // months are zero-based
        const year = date.getFullYear();

        // Check if the date is today
        const isToday = date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();

        // Calculate yesterday's date
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        // Check if the date is yesterday
        const isYesterday = date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear();

        if (isToday) {
            return `${hours}:${minutes}:${seconds} dnes`;
        } else if (isYesterday) {
            return `${hours}:${minutes}:${seconds} včera`;
        } else {
            return `${hours}:${minutes}:${seconds} ${day}. ${month}. ${year}`;
        }
    }
    padZero(value: number): string {
        return value < 10 ? `0${value}` : `${value}`;
    }

    transformDate(value?: string): string {
        if (!value) return '';

        const date = new Date(value);

        const day = date.getDate();
        const month = date.getMonth() + 1; // months are zero-based
        const year = date.getFullYear();

        return `${day}. ${month}. ${year}`;
    }
}
