import {Component, OnInit} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {DatePickerComponent} from "@coreui/angular-pro";
import {RouterLink} from "@angular/router";
import {Breadcrumb} from "../../../service/breadcrumb/Breadcrumb";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";

@Component({
    selector: 'app-admin-breadcrumb',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        DatePickerComponent,
        RouterLink,
        NgForOf
    ],
    templateUrl: './admin-breadcrumb.component.html',
    styleUrl: './admin-breadcrumb.component.scss'
})
export class AdminBreadcrumbComponent implements OnInit {
    breadcrumbs: Breadcrumb[] = [];

    constructor(private adminBreadcrumbService: AdminBreadcrumbService) {
    }

    ngOnInit() {
        this.adminBreadcrumbService.adminBreadcrumbs$.subscribe(breadcrumbs => {
            this.breadcrumbs = breadcrumbs;
        });
    }
}
