import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {UiService} from "../../../service/ui.service";
import {IAlert} from "../../../interface/alert-inteface";

@Component({
  selector: 'app-delete-modal',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        NgIf,
        NgForOf,
        NgOptimizedImage
    ],
  templateUrl: './delete-modal.component.html',
  styleUrl: './delete-modal.component.scss'
})
export class DeleteModalComponent implements OnInit{

    @Output() submitted: EventEmitter<void> = new EventEmitter<void>();
    @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    @Input() title: string = '';
    @Input() name: string = '';

    error: string = '';

    constructor(private uiService: UiService) {
    }

    ngOnInit() {
        this.uiService.openedModalToggle$.subscribe((name: string): void => {
            if (this.name === name) {
                this.toggleModal();
            }
        })
        this.uiService.alert$.subscribe((alert: IAlert): void => {
            if (alert.type === 'danger') {
                this.error = alert.message!;
                if (alert.timeout) {
                    setTimeout(() => this.removeAlert(), alert.timeout);
                }
            }
        });
    }

    public modalVisible = false;

    toggleModal() {
        this.modalVisible = !this.modalVisible;

        if(!this.modalVisible){
            this.closed.emit();
            this.uiService.closeModal(); //fix modal is not reopened on revisit
        }
    }

    handleModalChange(event: any) {
        this.modalVisible = event;
        if(!this.modalVisible) {
            this.closed.emit();
            this.uiService.closeModal(); //fix modal is not reopened on revisit
        }
    }

    onSubmit() {
        this.error = '';
        this.submitted.emit();
    }

    @HostListener('document:keydown.enter', ['$event'])
    handleEnterKey(): void {
        if (this.modalVisible) {
            this.onSubmit();
        }
    }

    removeAlert() {
        this.error = '';
    }
}
