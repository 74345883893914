import {Component, OnInit} from '@angular/core';
import {NgForOf, NgOptimizedImage} from "@angular/common";
import {IBoxDevice, IBoxDeviceDetail} from "../../../interface/box-device.interface";
import {BoxDeviceService} from "../../../service/box-device.service";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {TextSelectComponent} from "../../shared/text-select/text-select.component";
import {UiService} from "../../../service/ui.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormGroup} from "@angular/forms";
import {IService} from "../../../interface/service.interface";
import {ServiceService} from "../../../service/service.service";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {AdminEditDeviceComponent} from "../admin-device-page/admin-edit-device/admin-edit-device.component";
import {MODAL_NAME_ADD_SERVICE, MODAL_NAME_DELETE_SERVICE, MODAL_NAME_EDIT_BOX_DEVICE, MODAL_NAME_EDIT_SERVICE} from "../../../interface/modal-type.interface";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {AdminDeviceDeleteServiceComponent} from "./admin-device-delete-service/admin-device-delete-service.component";
import {AdminAddUserComponent} from "../admin-users-page/admin-add-user/admin-add-user.component";
import {AdminAddServiceComponent} from "./admin-add-service/admin-add-service.component";
import {AdminEditServiceComponent} from "./admin-edit-service/admin-edit-service.component";
import {UserService} from "../../../service/user.service";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";

@Component({
    selector: 'app-admin-device-detail-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        TextInputComponent,
        TextSelectComponent,
        NgForOf,
        TextInputReadonlyComponent,
        AdminEditDeviceComponent,
        TableComponent,
        AdminDeviceDeleteServiceComponent,
        AdminAddUserComponent,
        AdminAddServiceComponent,
        AdminEditServiceComponent
    ],
    templateUrl: './admin-device-detail-page.component.html',
    styleUrl: './admin-device-detail-page.component.scss'
})
export class AdminDeviceDetailPageComponent implements OnInit {

    // data
    deviceId!: string; // id from the route
    boxDevice?: IBoxDeviceDetail;
    form!: FormGroup;
    boxDeviceServices: IService[] = [];
    allServices: IService[] = [];

    serviceToBeDeleted?: IService;
    serviceToBeEdited?: IService;

    config: ITableConfig[] = [
        {field: 'status', specialComponent: {
            type: 'status'
        }},
        {field: 'name', label: 'Název'},
        {field: 'serviceName', label: 'Servisní jméno'},
        {field: 'note', label: 'Poznámka'},
        {field: 'orderNum', label: 'Pořadí'},
        {field: 'type', label: 'Typ', specialComponent: {
            type: 'serviceType',
        }},
        {specialComponent: {
            type: 'edit',
            onClick: this.onEditService.bind(this)
        }},
        {specialComponent: {
            type: 'delete',
            onClick: this.onDeleteService.bind(this)
        }}
    ]

    constructor(
        private uiService: UiService,
        private route: ActivatedRoute,
        private navigationService: NavigationService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private serviceService: ServiceService,
        private boxDeviceService: BoxDeviceService,
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.deviceId = params['deviceId'];
            if (this.deviceId) {
                this.loadData();
                this.adminBreadcrumbService.ofDeviceDetail(this.deviceId);
            }
        });
    }

    onBoxDeviceEdit(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_EDIT_BOX_DEVICE);
    }

    onBoxDeviceEditConfirmed(boxDevice: IBoxDevice): void {
        this.loadBoxDeviceDetail();
    }

    onDeleteService(service: IService): void {
        this.serviceToBeDeleted = service;
        this.uiService.toggleModal(MODAL_NAME_DELETE_SERVICE);
    }

    onDeleteServiceConfirm(service: IService) {
        this.loadServicesByDeiceId();
    }

    onEditService(service: IService): void {
        this.serviceToBeEdited = service;
        this.uiService.toggleModal(MODAL_NAME_EDIT_SERVICE);
    }

    onEditServiceEditConfirmed(service: IService): void {
        this.loadServicesByDeiceId();
    }

    onServiceAdd(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_ADD_SERVICE);
    }

    onServiceAddSubmitted() {
        this.loadServicesByDeiceId();
    }

    onServiceRowClicked(service: IService): void {
        this.navigationService.toAdminService(service.serviceId)
    }

    private loadData(): void {
        this.loadBoxDeviceDetail();
        this.loadServicesByDeiceId();
        this.serviceService.getMyServices().subscribe(
            (data: IService[]) => {
                this.allServices = data;
            }
        );
    }

    private loadServicesByDeiceId(): void {
        this.serviceService.getServicesByDeviceId(this.deviceId!).subscribe(
            (data: IService[]) => {
                this.boxDeviceServices = data;
            }
        );
    }

    private loadBoxDeviceDetail() : void {
        this.boxDeviceService.getBoxDeviceDetail(this.deviceId).subscribe((data: IBoxDeviceDetail) => {
            this.boxDevice = data;
        });
    }
}
