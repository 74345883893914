import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from "../../service/user.service";
import {IUserContext} from "../../interface/user.context.interface";
import {ICompany} from "../../interface/company.interface";
import {CompanyService} from "../../service/company.service";
import {PasswordChangeComponent} from "../password-change/password-change.component";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-user-profile',
    standalone: true,
    imports: [
        PasswordChangeComponent,
        NgIf
    ],
    templateUrl: './user-profile.component.html',
    styleUrl: './user-profile.component.scss'
})
export class UserProfileComponent implements OnInit {

    userContext?: IUserContext;
    company?: ICompany;
    pwdChangeInProgress = false;
    passwordChangedSuccess = false;

    constructor(private userService: UserService, private companyService: CompanyService) {
    }

    ngOnInit(): void {
        this.userContext = this.userService.getUserContext();
        this.companyService.getMyRealCompany().subscribe(
            (data) => this.company = data,
        );
    }

    onPwdChange(event: Event): void {
        event.preventDefault();
        this.pwdChangeInProgress = !this.pwdChangeInProgress;
        this.passwordChangedSuccess = false;
    }

    onPwdChangeSuccess(): void {
        this.pwdChangeInProgress = !this.pwdChangeInProgress;
        this.passwordChangedSuccess = true;
    }

    onPwdChangeCancel(): void {
        this.pwdChangeInProgress = !this.pwdChangeInProgress;
    }
}
