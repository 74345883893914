import {Component, OnInit} from '@angular/core';
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {BoxDeviceService} from "../../../service/box-device.service";
import {IBoxDevice, IWGBoxDevice} from "../../../interface/box-device.interface";
import {Router} from "@angular/router";
import {UserService} from "../../../service/user.service";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";

@Component({
  selector: 'app-global-wg-settings',
  standalone: true,
    imports: [
        TableComponent
    ],
  templateUrl: './global-wg-settings.component.html',
  styleUrl: './global-wg-settings.component.scss'
})
export class GlobalWgSettingsComponent implements OnInit{

    boxDevices: IWGBoxDevice[] = [];

    constructor(private boxDeviceService: BoxDeviceService, private userService: UserService, private navigationService: NavigationService, private adminBreadcrumbService: AdminBreadcrumbService) {

    }

    config: ITableConfig[] = [
        {
            field: 'status',
            specialComponent: {
                type: 'status'
            }
        },
        {field: 'name', label: 'Název'},
        {field: 'wgIpAddress', label: 'Adresa'},
        {field: 'wgApiKey', label: 'Public key'},
        {field: 'companyName', label: 'Společnost'},
    ]

    ngOnInit(): void {
        this.boxDeviceService.getAllBoxDevices().subscribe((data: IWGBoxDevice[]) => {
            this.boxDevices = data;
        })
        this.navigationService.rememberLastTabVisited();
        this.adminBreadcrumbService.ofWgSettings();
    }

    onRowClicked(wgBoxDevice: IWGBoxDevice): void {
        const {deviceId, companyId} = wgBoxDevice;
        this.navigationService.toAdminDeviceDetail(deviceId, companyId);
    }

}
