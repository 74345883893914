import {Component} from '@angular/core';

@Component({
  selector: 'app-service-heating-branch-detail',
  standalone: true,
  imports: [],
  templateUrl: './service-heating-branch-detail.component.html',
  styleUrls: ['./service-heating-branch-detail.component.scss'],
})
export class ServiceHeatingBranchDetailComponent {

  constructor() {
  }

}
