import {AbstractControl, ValidationErrors} from "@angular/forms";

export class CustomValidators {
  static isNumber(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (value === null || value === undefined || value === '') return null;
    return isNaN(value) ? {isNumber: true} : null;
  }

  static isNumberInRange(min: number, max: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value < min || value > max) {
        return {outOfRange: {min, max}};
      }
      return null;
    }
  }
}
