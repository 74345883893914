<div class="container">
    <div class="box-shadow my-3 min-h-44 pb-3">
        <h3>Přehled odběrných míst</h3>
        <app-table [config]="config" [data]="consumptionPoints" (rowClicked)="onRowClicked($event)"></app-table>
        <button type="button" class="btn btn-primary" (click)="onConsumptionPointAdd($event)">Přidat odběrné místo</button>
    </div>

    <!-- modal dialogs    -->
    <app-add-consumption-point
            (created)="onConsumptionPointAddSubmitted()"
            [electricityDistributors]="electricityDistributors">
    </app-add-consumption-point>


    <app-delete-consumption-point [point]="consumptionPointToBeDeleted" (deleted)="onConsumptionPointDeleteConfirmed($event)"></app-delete-consumption-point>
    <app-edit-consumption-point
            [consumptionPoint]="consumptionPointToBeEdited"
            (edited)="onConsumptionPointEditConfirmed($event)"
            [electricityDistributors]="electricityDistributors"
    ></app-edit-consumption-point>
</div>
