import { Component, Input } from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IHotWaterTankDataDetail} from "../../../../interface/hot-water-tank.interface";
import {IPowerMeterDetail} from "../../../../interface/box-service/power-meter-detail";

@Component({
  selector: 'app-service-power-meter',
  templateUrl: './service-power-meter.component.html',
  styleUrl: 'service-power-meter.component.scss',
  imports: [],
  standalone: true
})
export class ServicePowerMeterComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

  getDetail(): IPowerMeterDetail | undefined {
     return this.service?.detail as IPowerMeterDetail;
  }

  getCurrentActPower(): number {
      return this.getDetail()?.currentActPower || 0;
  }

  getQuarterSumActPower(): number {
      return this.getDetail()?.quarterSumActPower || 0;
  }

  getSumActPower(): number {
      return this.getDetail()?.sumActPower || 0;
  }

}
