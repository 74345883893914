import {Component} from '@angular/core';

@Component({
  selector: 'app-service-hot-water-tank-detail',
  standalone: true,
  imports: [],
  templateUrl: './service-hot-water-tank-detail.component.html',
  styleUrls: ['./service-hot-water-tank-detail.component.scss'],
})
export class ServiceHotWaterTankDetailComponent {

  constructor() {
  }

}
