import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StatusComponent} from "../shared/status/status.component";
import {IService, serviceTypeTranslation} from "../../interface/service.interface";
import {UserService} from "../../service/user.service";
import {Router} from "@angular/router";
import {IServiceGroup} from "../../interface/service.group.interface";

@Component({
  selector: 'app-service-table-row',
  standalone: true,
    imports: [
        StatusComponent
    ],
  templateUrl: './service-table-row.component.html',
  styleUrl: './service-table-row.component.scss'
})
export class ServiceTableRowComponent {

    @Input() service!: IService;
    @Output() serviceRemoved: EventEmitter<IService> = new EventEmitter<IService>();

    constructor(private userService: UserService, private router: Router) {
    }

    resolveType(): string {
        return serviceTypeTranslation[this.service.type];
    }
    onRowClick(event: Event) {
        event.preventDefault();
        const companyId: string = this.userService.getCompanyId();
        const id: string = this.service.serviceId;
        this.router.navigate([`company/${companyId}/service/${id}`])
    }

    onRemoveClick(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.serviceRemoved.emit(this.service);
    }


}
