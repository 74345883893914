import {Component, OnInit} from '@angular/core';
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {ServiceGroupService} from "../../../service/service-group.service";
import {UserService} from "../../../service/user.service";
import {ActivatedRoute} from "@angular/router";
import {IUser} from "../../../interface/user.interface";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {TextSelectComponent} from "../../shared/text-select/text-select.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {IServiceGroup} from "../../../interface/service.group.interface";
import {AdminEditUserComponent} from "../admin-users-page/admin-edit-user/admin-edit-user.component";
import {UiService} from "../../../service/ui.service";
import {MODAL_NAME_CHANGE_PASSWORD, MODAL_NAME_DELETE_USER, MODAL_NAME_EDIT_USER} from "../../../interface/modal-type.interface";
import {FormsModule} from "@angular/forms";
import {DropdownComponent} from "../../shared/dropdown/dropdown.component";
import {IDropdownItem} from "../../../interface/dropdown.item.interface";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {AdminDeleteUserComponent} from "../admin-users-page/admin-delete-user/admin-delete-user.component";
import {AdminChangePasswordComponent} from "./admin-change-password/admin-change-password.component";

@Component({
    selector: 'app-admin-user-detail-page',
    standalone: true,
    imports: [
        NgOptimizedImage,
        TextInputComponent,
        TextSelectComponent,
        TextInputReadonlyComponent,
        AdminEditUserComponent,
        FormsModule,
        NgForOf,
        DropdownComponent,
        TableComponent,
        AdminDeleteUserComponent,
        NgIf,
        AdminChangePasswordComponent,
    ],
    templateUrl: './admin-user-detail-page.component.html',
    styleUrl: './admin-user-detail-page.component.scss'
})
export class AdminUserDetailPageComponent implements OnInit {

    // data
    id?: string; // id from the route
    user?: IUser;
    userServiceGroups: IServiceGroup[] = [];
    allServiceGroups: IServiceGroup[] = [];

    config: ITableConfig[] = [
        {field: 'name', label: 'Název'},
        {field: 'note', label: 'Poznámka'},
        {
            specialComponent: {
                type: 'minus',
                onClick: this.onRemoveFromServiceGroup.bind(this)
            }
        }
    ]

    constructor(
        private serviceGroupService: ServiceGroupService,
        private userService: UserService,
        private uiService: UiService,
        private navigationService: NavigationService,
        private adminBreadcrumbService: AdminBreadcrumbService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.id = params['userId'];
            if (this.id) {
                this.loadAllData();
            }
        });
    }

    getPossibilities(): IDropdownItem[] {
        // do not suggest service groups which already are inside this group
        const ids = new Set(
            this.userServiceGroups.map(item => item.id)
        );

        return this.allServiceGroups
            .filter(item => !ids.has(item.id))
            .map((group: IServiceGroup) => {
                return {id: group.id, name: group.name} as IDropdownItem;
            })
    }

    onUserEdit(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_EDIT_USER);
    }

    onUserEditConfirmed(user: IUser) {
        this.loadUserDetail();
    }

    onUserDelete(event: Event) {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_DELETE_USER);
    }

    onUserDeleteConfirmed(user: IUser) {
        this.navigationService.toAdminUsers();
    }

    onUserChangePassword(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_CHANGE_PASSWORD);
    }

    onUserChangePasswordConfirmed(user: IUser) {
        this.loadUserDetail();
    }


    onRemoveFromServiceGroup(group: IServiceGroup) {
        this.userService.removeRightFromServiceGroup(this.user!.userId, group.id).subscribe(() => {
            this.uiService.setSuccessAlert(`Právo na skupinu ${group.name} úspěšně odebráno.`);
            this.loadUserGroups();
        });
    }

    onGroupSelected(item: IDropdownItem) {
        this.userService.addRightToServiceGroup(this.user!.userId, item.id).subscribe(() => {
            this.uiService.setSuccessAlert(`Právo na skupinu ${item.name} úspěšně přidáno.`)
            this.loadUserGroups();
        })
    }


    onServiceGroupClicked(event: IServiceGroup) {
        this.navigationService.toAdminServiceGroup(event.id);
    }

    private loadAllData(): void {
        this.loadUserDetail();
        this.loadAllGroups();
        this.loadUserGroups();
    }

    private loadUserDetail(): void {
        this.userService.getUser(this.id!).subscribe((data: IUser) => {
            this.user = data;
            this.adminBreadcrumbService.ofUserDetail(this.user)
        });
    }

    private loadAllGroups(): void {
        this.serviceGroupService.getMyServiceGroups().subscribe((data: IServiceGroup[]) => {
            this.allServiceGroups = data;
        })
    }

    private loadUserGroups(): void {
        this.serviceGroupService.getServiceGroupsByUserId(this.id!).subscribe((data: IServiceGroup[]) => {
            this.userServiceGroups = data;
        })
    }

    showServiceGroups(): boolean {
        return this.userService.isAdmin() || this.user?.role === 'UserPartner';
    }

    showButtons(): boolean{
        return this.userService.isAdmin() || this.user?.role === 'UserPartner';
    }
}
