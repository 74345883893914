/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HotWaterTankDataDetailResponse } from './hotWaterTankDataDetailResponse';


export interface HotWaterDataResponse { 
    serviceId?: string;
    lastDateTime?: string;
    type: HotWaterDataResponse.TypeEnum;
    name?: string;
    serviceDataDetail?: HotWaterTankDataDetailResponse;
    freshData?: boolean;
}
export namespace HotWaterDataResponse {
    export type TypeEnum = 'heatingBranch' | 'pumpHeatingBranch' | 'valveHeatingBranch' | 'hotWaterTank' | 'powerMeter' | 'associatedPowerMeter' | 'waterMeterHot' | 'waterMeterCold' | 'calorimeter';
    export const TypeEnum = {
        HeatingBranch: 'heatingBranch' as TypeEnum,
        PumpHeatingBranch: 'pumpHeatingBranch' as TypeEnum,
        ValveHeatingBranch: 'valveHeatingBranch' as TypeEnum,
        HotWaterTank: 'hotWaterTank' as TypeEnum,
        PowerMeter: 'powerMeter' as TypeEnum,
        AssociatedPowerMeter: 'associatedPowerMeter' as TypeEnum,
        WaterMeterHot: 'waterMeterHot' as TypeEnum,
        WaterMeterCold: 'waterMeterCold' as TypeEnum,
        Calorimeter: 'calorimeter' as TypeEnum
    };
}


