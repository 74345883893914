<app-client-breadcrumb></app-client-breadcrumb>
<div class="container">
  <div class="container-auto">
    <div class="row align-items-center row1">
      <h3 class="title text-center">Detail služby {{service?.name}}</h3>
      <button (click)="redirectToSettings()" class="btn btn-primary rounded-pill btn-settings">
        <mat-icon class="mt-1">settings</mat-icon>
      </button>
    </div>
    <div class="row2">
      <ng-container *ngIf="service?.type === IBoxServiceType.AssociatedPowerMeter">
        <app-service-associated-power-detail [serviceId]="serviceId" [serviceName]="service?.name"></app-service-associated-power-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.Calorimeter">
        <app-service-calorimeter-detail [serviceId]="serviceId" [serviceName]="service?.name"></app-service-calorimeter-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.HeatingBranch">
        <app-service-heating-branch-detail></app-service-heating-branch-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.HotWaterTank">
        <app-service-hot-water-tank-detail></app-service-hot-water-tank-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.PowerMeter">
        <app-service-power-meter-detail [serviceId]="serviceId" [serviceName]="service?.name"></app-service-power-meter-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.PumpHeatingBranch">
        <app-service-pump-heating-branch-detail [serviceId]="serviceId" [serviceName]="service?.name"></app-service-pump-heating-branch-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.ValveHeatingBranch">
        <app-service-valve-heating-branch-detail [serviceId]="serviceId" [serviceName]="service?.name"></app-service-valve-heating-branch-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.WaterMeterCold">
        <app-service-cold-water-meter-detail [serviceId]="serviceId" [serviceName]="service?.name"></app-service-cold-water-meter-detail>
      </ng-container>
      <ng-container *ngIf="service?.type === IBoxServiceType.WaterMeterHot">
        <app-service-hot-water-meter-detail [serviceId]="serviceId" [serviceName]="service?.name"></app-service-hot-water-meter-detail>
      </ng-container>
    </div>
    <div class="row d-flex justify-content-center row3">
      <button (click)="redirectBack()" class="btn btn-primary btn-back">Zpět</button>
    </div>
  </div>
</div>
