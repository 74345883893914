import { Component, Input } from '@angular/core';
import { CustomChartComponent } from '../../../shared/custom-chart/custom-chart.component';
import {CustomBarChartComponent} from "../../../shared/custom-bar-chart/custom-bar-chart.component";

@Component({
  selector: 'app-service-hot-water-meter-detail',
  standalone: true,
    imports: [
        CustomChartComponent,
        CustomBarChartComponent
    ],
  templateUrl: './service-hot-water-meter-detail.component.html',
  styleUrls: ['./service-hot-water-meter-detail.component.scss']
})
export class ServiceHotWaterMeterDetailComponent {

  @Input() serviceId?: string | null;
  @Input() serviceName?: string;

  constructor() {
  }

}
