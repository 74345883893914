import {Component, OnInit} from '@angular/core';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {Router} from '@angular/router';
import { version } from '../../../../package.json';
import {ScreenSize, UiService} from "../../service/ui.service";
import {UserSettingsComponent} from "../user-settings/user-settings.component";
import {UserService} from "../../service/user.service";
import {CompanySelectComponent} from "../company-select/company-select.component";
import {BuildTimeService} from "../../service/build-time.service";
import {INavItem, NAV_LINK, NavigationService} from "../../service/navigation.service";
import {SideBarGroupsLvl0Component} from "./side-bar-groups-root/side-bar-groups-root.component";
import {HealthService} from "../../service/health.service";
import {ModalDialogService} from "../../service/modal-dialog.service";
import {ModalDialogTypeEnum} from "../shared/modal-dialog/modal-dialog-type.enum";
import {ModalUtils} from "../../util/modal/modal.utils";
import {ModalDialogComponent} from "../shared/modal-dialog/modal-dialog.component";

@Component({
    selector: 'app-side-bar',
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgForOf,
        UserSettingsComponent,
        CompanySelectComponent,
        SideBarGroupsLvl0Component,
    ],
    templateUrl: './side-bar.component.html',
    styleUrl: './side-bar.component.scss',
})
export class SideBarComponent implements OnInit {

    // data
    appVersion = version;
    buildTime: string = '';

    // ui
    screenSize: ScreenSize = 'sm';

    burgerOpened: boolean = false;
    // is name of the screen or id of group
    selectedItem: NAV_LINK = 'none';
    isAdmin: boolean = this.userService.isAdmin();
    isAdminPartner: boolean = this.userService.isAdminPartner();

    // todo temporarily always opened, maybe always :)
    readonly isFullyOpened: boolean = true;

    constructor(
        private uiService: UiService,
        private ns: NavigationService,
        private buildTimeService: BuildTimeService,
        private modalDialogService: ModalDialogService,
        private healthService: HealthService,
        private userService: UserService,
        private navigationService: NavigationService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.uiService.screenSize$.subscribe((el: ScreenSize) => {
            this.screenSize = el;
        });

        this.uiService.closeBurger$.subscribe(() => {
            this.toggleBurger();
        });

        this.buildTimeService.getBuildTime().subscribe(data => {
            this.buildTime = data.buildTime;
        });

        this.ns.navItem$.subscribe((item: INavItem) => {
            this.selectedItem = item.name;
        })
    }

    toggleBurger(event?: Event): void {
        if (event) {
            event.preventDefault();
        }

        if (this.screenSmallerThanLg()) {
            this.burgerOpened = !this.burgerOpened;
        }

    }

    navigateToDashboard(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigateTo('/dashboard');
    }

    navigateToSpotBoxes(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toAdminDevices();
    }

    navigateToCalculation(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toCalculation();
    }

    navigateToWgSettings(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toWgSettings();
    }

    navigateToCompanies(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toCompanies();
    }

    navigateServiceGroups(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toAdminServiceGroups();
    }

    navigateToUsers(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toAdminUsers();
    }

    navigateToConsumptionPoints(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toAdminConsumptionPoints();
    }

    navigateToCompanyDetail(event: Event) {
        event.preventDefault();
        this.toggleBurger();
        this.navigationService.toAdminCompanyDetail();
    }

    screenBiggerThanMd(): boolean {
        return this.screenSize === "lg" || this.screenSize === "xl";
    }

    screenSmallerThanLg(): boolean {
        return this.screenSize === "xs" || this.screenSize === "sm" || this.screenSize === "md";
    }

    displaySideBar(): boolean {
        return this.screenBiggerThanMd() || this.burgerOpened;
    }

    navigateTo(url: string) {
        if(this.userService.isAdmin()) {
            this.router.navigate(['/company/' + this.userService.getCompanyId() + url])
        } else {
            this.router.navigate([url])
        }
    }

  showBackendVersion() {
    this.healthService.getBackendVersion()
      .subscribe(version => {
        const modalDialog = this.modalDialogService.open(ModalDialogComponent, ModalUtils.createDefaultModalOptions('lg'));
        modalDialog.componentInstance.title = 'Backend verze';
        modalDialog.componentInstance.text = version;
        modalDialog.componentInstance.noButtonLabel = 'Zavřít';
        modalDialog.componentInstance.showYesButton = false;
        modalDialog.componentInstance.withoutParent = true;
        modalDialog.componentInstance.type = ModalDialogTypeEnum.INFO;
      })
  }
}
