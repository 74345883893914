import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription, timer} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private loadingSubject = new BehaviorSubject<boolean>(false);
  isLoading = this.loadingSubject.asObservable();
  private loaderTimeout?: Subscription;
  private hideTimeout?: Subscription;
  private activeRequests = 0;
  private readonly displayDelay = 100;
  private readonly minDisplayDuration = 500;

  show() {
    this.activeRequests++;

    if (this.loaderTimeout) {
      this.loaderTimeout.unsubscribe();
    }

    this.loaderTimeout = timer(this.displayDelay).subscribe(() => {
      if (this.activeRequests > 0) {
        this.loadingSubject.next(true);

        this.hideTimeout = timer(this.minDisplayDuration).subscribe(() => {});
      }
    });
  }

  hide() {
    this.activeRequests = Math.max(this.activeRequests - 1, 0);

    if (this.activeRequests === 0) {
      if (this.loaderTimeout) {
        this.loaderTimeout.unsubscribe();
      }

      if (this.hideTimeout) {
        this.hideTimeout.add(() => {
          this.loadingSubject.next(false);
          this.hideTimeout = undefined;
        });
      } else {
        this.loadingSubject.next(false);
      }
    }
  }
}
