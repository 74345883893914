import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {
    CreateConsumptionPointPeriodRequest,
    CreateConsumptionPointRequest,
    CreateServiceGroupRequest,
    DeleteConsumptionPointPeriodRequest,
    DeleteConsumptionPointRequest, EditConsumptionPointPeriodRequest,
    EditConsumptionPointRequest, GetConsumptionPointPeriodRequest, GetConsumptionPointPeriodResponse,
    GetConsumptionPointPeriodsRequest,
    GetConsumptionPointPeriodsResponse,
    GetConsumptionPointRequest,
    GetConsumptionPointResponse,
    GetConsumptionPointsRequest,
    GetConsumptionPointsResponse,
    GetDistributorsResponse
} from "../api";
import {map} from "rxjs/operators";
import {ApiService} from "./api.service";
import {UserService} from "./user.service";
import {ConsumptionPointMapper} from "../mapper/consumption-point.mapper";
import {IConsumptionPoint, IConsumptionPointDetail, INewConsumptionPoint} from "../interface/consumption-point.interface";
import {INewServiceGroup} from "../interface/service.group.interface";
import {NoResponseMapper} from "../mapper/no-response.mapper";
import {ISelectOption} from "../component/shared/text-select/text-select.component";
import {IConsumptionPeriod, INewConsumptionPeriod} from "../interface/consumption-period.interface";
import {DateUtils} from "../util/date/date-utils";

@Injectable({
  providedIn: 'root'
})
export class ConsumptionPointService {

  constructor(private apiService: ApiService, private userService: UserService) { }

    getConsumptionPointsFromMyCompany(): Observable<IConsumptionPoint[]> {
        const body: GetConsumptionPointsRequest = {companyId: this.userService.getCompanyId()};

        return this.apiService.post("/consumption-point/get-consumption-points", body).pipe(
            map((res: GetConsumptionPointsResponse) => ConsumptionPointMapper.mapConsumptionPoints(res))
        );
    }

    getConsumptionPeriods(consumptionPointId: string): Observable<IConsumptionPeriod[]> {
        const body: GetConsumptionPointPeriodsRequest = {consumptionPointId};

        return this.apiService.post("/consumption-point/get-consumption-point-periods", body).pipe(
            map((res: GetConsumptionPointPeriodsResponse) => ConsumptionPointMapper.mapConsumptionPeriods(res))
        );
    }

    getConsumptionPoint(consumptionPointId: string): Observable<IConsumptionPointDetail> {
        const body: GetConsumptionPointRequest = {consumptionPointId};

        return this.apiService.post("/consumption-point/get-consumption-point", body).pipe(
            map((res: GetConsumptionPointResponse) => ConsumptionPointMapper.mapConsumptionPointRes(res))
        );
    }

    getConsumptionPeriod(consumptionPointPeriodId: string): Observable<IConsumptionPeriod> {
        const body: GetConsumptionPointPeriodRequest = {consumptionPointPeriodId};

        return this.apiService.post("/consumption-point/get-consumption-point-period", body).pipe(
            map((res: GetConsumptionPointPeriodResponse) => ConsumptionPointMapper.mapConsumptionPeriod(res))
        );
    }

    private electricityDistributors: ISelectOption[] = [];
    private gasDistributors: ISelectOption[] = [];
    private tariffs: ISelectOption[] = [];

    getCachedElectricityDistributors(): Observable<ISelectOption[]> {
        if (this.electricityDistributors.length > 0) {
            return of(this.electricityDistributors);
        }
        return this.apiService.post("/consumption-point/get-electricity-distributors", {}).pipe(
            map((res: GetDistributorsResponse) => {
                this.electricityDistributors = ConsumptionPointMapper.mapDistributors(res);
                return this.electricityDistributors;
            })
        );
    }

    getCachedGasDistributors(): Observable<ISelectOption[]> {
        if (this.gasDistributors.length > 0) {
            return of(this.gasDistributors);
        }
        return this.apiService.post("/consumption-point/get-gas-distributors", {}).pipe(
            map((res: GetDistributorsResponse) => {
                this.gasDistributors = ConsumptionPointMapper.mapDistributors(res);
                return this.gasDistributors;
            })
        );
    }

    getCachedDistributionTariffs(): Observable<ISelectOption[]> {
        if (this.tariffs.length > 0) {
            return of(this.tariffs);
        }
        return this.apiService.post("/consumption-point/get-distribution-tariffs", {}).pipe(
            map((res: GetDistributorsResponse) => {
                this.tariffs = ConsumptionPointMapper.mapDistributors(res);
                return this.tariffs;
            })
        );
    }

    createConsumptionPoint(consumptionPoint: INewConsumptionPoint): Observable<void> {
        const companyId: string = this.userService.getCompanyId();
        const body: CreateConsumptionPointRequest = {...consumptionPoint, companyId};
        return this.apiService
            .post('/consumption-point/create-consumption-point', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    editConsumptionPoint(consumptionPoint: IConsumptionPoint): Observable<void> {
        const companyId: string = this.userService.getCompanyId();

        const body: EditConsumptionPointRequest = {...consumptionPoint};
        return this.apiService
            .post('/consumption-point/edit-consumption-point', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    editConsumptionPeriod(period: IConsumptionPeriod): Observable<void> {
        const startPeriod = DateUtils.formatDateString(period.startPeriod);
        const body: EditConsumptionPointPeriodRequest = {...period, startPeriod};

        return this.apiService
            .post('/consumption-point/edit-consumption-point-period', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    deleteConsumptionPoint(consumptionPointId: string): Observable<void> {
        const body: DeleteConsumptionPointRequest = {consumptionPointId};
        return this.apiService
            .post('/consumption-point/delete-consumption-point', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));
    }

    addPeriod(period: INewConsumptionPeriod): Observable<void> {

        const startPeriod = DateUtils.formatDateString(period.startPeriod);
        const body: CreateConsumptionPointPeriodRequest = {...period, startPeriod};
        return this.apiService
            .post('/consumption-point/add-consumption-point-period', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));

    }

    deletePeriod(consumptionPointPeriodId: string): Observable<void> {

        const body: DeleteConsumptionPointPeriodRequest = {consumptionPointPeriodId};
        return this.apiService
            .post('/consumption-point/delete-consumption-point-period', body, {clearAlerts: true})
            .pipe(map(() => NoResponseMapper.map()));

    }
}
