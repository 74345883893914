<div class="custom-widget equal-height-directive color">
  <div class="row-content m-1">
    <div class="column1">
      <div class="custom-header mb-2">
        <a [routerLink]="getDetailPath()">
          {{ service?.name }}
        </a>
      </div>
      <div class="custom-body">
        <ng-container *ngIf="service?.type === IBoxServiceType.AssociatedPowerMeter">
          <app-service-associated-power-meter [service]="service"></app-service-associated-power-meter>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.Calorimeter">
          <app-service-calorimeter [service]="service"></app-service-calorimeter>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.HeatingBranch">
          <app-service-heating-branch [service]="service"></app-service-heating-branch>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.HotWaterTank">
          <app-service-hot-water-tank [service]="service"></app-service-hot-water-tank>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.PowerMeter">
          <app-service-power-meter [service]="service"></app-service-power-meter>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.PumpHeatingBranch">
          <app-service-pump-heating-branch [service]="service"></app-service-pump-heating-branch>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.WaterMeterCold">
          <app-service-cold-water-meter [service]="service"></app-service-cold-water-meter>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.WaterMeterHot">
          <app-service-hot-water-meter [service]="service"></app-service-hot-water-meter>
        </ng-container>
        <ng-container *ngIf="service?.type === IBoxServiceType.ValveHeatingBranch">
          <app-service-valve-heating-branch [service]="service"></app-service-valve-heating-branch>
        </ng-container>
      </div>
    </div>
    <div class="column2">
      <div class="row-content">
        <img [src]="getIconByType()" [style.height.px]="50" [style.width.px]="50" alt="service_icon">
        <c-dropdown variant="btn-group">
          <button [caret]="false" cButton cDropdownToggle class="p-0" color="transparent">
            <svg [cIcon]="icons.cilOptions" height="25" width="25" class="text-high-emphasis-inverse"></svg>
          </button>
          <div cDropdownMenu>
            <a [routerLink]="getDetailPath()"
               cDropdownItem>Detail</a>
            <a (click)="onTileSettingsOpen($event)"
               cDropdownItem>Nastavení</a>
          </div>
        </c-dropdown>
      </div>
    </div>
  </div>
</div>
