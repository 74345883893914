<div class="container">
    <h3>Uživatel: {{ this.user?.username }}</h3>

    <!-- user detail -->
    <div class="box-shadow">
        <h5>Detail</h5>
        <div class="row">
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Jméno'" [value]="this.user?.username"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Email'" [value]="this.user?.email"></app-text-input-readonly>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-12 mb-3">
                <app-text-input-readonly [label]="'Role'" [value]="this.user?.role"></app-text-input-readonly>
            </div>
        </div>
        <button *ngIf="showButtons()" type="button" class="btn btn-primary mr-2" (click)="onUserEdit($event)">Upravit uživatele</button>
        <button *ngIf="showButtons()" type="button" class="btn btn-primary mr-2" (click)="onUserChangePassword($event)">Změnit heslo</button>
        <button *ngIf="showButtons()" type="button" class="btn btn-danger" (click)="onUserDelete($event)">Smazat uživatele</button>
    </div>

    <!-- service groups -->
    <div *ngIf="showServiceGroups()" class="box-shadow mt-3 min-h-44">
        <h5 class="pt-3">Servisní skupiny</h5>
        <app-table [data]="userServiceGroups" [config]="config" (rowClicked)="onServiceGroupClicked($event)"></app-table>
        <app-dropdown [items]="getPossibilities()" (itemSelected)="onGroupSelected($event)" [buttonText]="'Přidat servisní skupinu'"  ></app-dropdown>
    </div>

    <!-- modals   -->
    <app-admin-edit-user [user]="user" (userEdited)="onUserEditConfirmed($event)"></app-admin-edit-user>
    <app-admin-delete-user [user]="user" (userDeleted)="onUserDeleteConfirmed($event)"></app-admin-delete-user>
    <app-admin-change-password [user]="user"></app-admin-change-password>
</div>