import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule } from '@angular/forms';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { UiService } from '../../../service/ui.service';
import { BoxGraphService } from '../../../service/box-graph.service';
import { distinctUntilChanged } from 'rxjs';
import { DateUtils } from '../../../util/date/date-utils';
import { IBoxGraphData } from '../../../interface/box-graph-data.interface';
import { CardBodyComponent, CardComponent } from '@coreui/angular-pro';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { CustomDatePickerInputComponent } from '../custom-date-picker-input/custom-date-picker-input.component';
import { NgIf } from '@angular/common';
import { ChartjsComponent } from '@coreui/angular-chartjs';

@Component({
  selector: 'app-custom-line-chart',
  standalone: true,
  imports: [
    CardComponent,
    CardBodyComponent,
    MatIcon,
    MatIconButton,
    CustomDatePickerInputComponent,
    FormsModule,
    NgIf,
    ChartjsComponent
  ],
  templateUrl: './custom-line-chart.component.html',
  styleUrl: './custom-line-chart.component.scss'
})
export class CustomLineChartComponent implements OnInit {

  @Input() serviceId?: string | null;
  @Input() lineTitle!: string;

  formGroup: FormGroup;

  temporaryHideGraphHack: boolean = false;
  isCompareEnabled: boolean = false;

  graphData?: ChartData;
  chartOptions: ChartOptions = {
    scales: {
      first: {
        type: 'linear',
        position: 'left',
        beginAtZero: false
      }
    }
  };

  constructor(private fb: FormBuilder, private uiService: UiService, private boxGraphService: BoxGraphService) {
    this.formGroup = this.formGroup = this.fb.group({
      day: new Date(),
      secondDay: new Date()
    });
  }

  get dayControl(): FormControl {
    return this.formGroup.get('day') as FormControl;
  }

  get secondDayControl(): FormControl {
    return this.formGroup.get('secondDay') as FormControl;
  }

  ngOnInit(): void {
    this.uiService.resized$.subscribe(() => {
      this.temporaryHideGraphHack = true;
      setTimeout(() => {
        this.temporaryHideGraphHack = false;
      }, 10);
    });

    this.dayControl.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.getServiceDetailTemperatureGraph();
      });

    this.secondDayControl.valueChanges
      .pipe(
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.getServiceDetailTemperatureGraphForSecondDay();
      });
  }

  getServiceDetailTemperatureGraph() {
    if (this.serviceId) {
      const currentDay: Date = this.dayControl.value;
      this.boxGraphService.getServiceDetailTemperatureGraph(this.serviceId, DateUtils.getStartOfDay(currentDay)).subscribe(data => {
        this.graphData = this.transformGraphData(data);
      });
    }
  }

  getServiceDetailTemperatureGraphForSecondDay() {
    if (this.serviceId) {
      const secondDayControl = this.formGroup.get('secondDay');
      if (secondDayControl) {
        const secondDay: Date = secondDayControl.value;
        this.boxGraphService.getServiceDetailTemperatureGraph(this.serviceId, DateUtils.getStartOfDay(secondDay))
          .subscribe(data => {
            if (data) {
              this.graphData = this.addSecondDayGraphData(data);
            }
          });
      }
    }
  }

  transformGraphData(data: IBoxGraphData[]): ChartData {
    if (this.graphData && this.isCompareEnabled && this.graphData.datasets[1]) {
      const secondDayDataSet = this.graphData.datasets[1];
      return {
        ...this.graphData,
        datasets: [this.createGraphData(data, this.lineTitle, 'blue'), secondDayDataSet]
      };
    } else {
      return {
        labels: data.map(
          (item, index) => `${index}:00`
        ),
        datasets: [this.createGraphData(data, this.lineTitle, 'blue')]
      };
    }
  }

  addSecondDayGraphData(data: IBoxGraphData[]): ChartData {
    if (this.graphData) {
      const newData: ChartData = {
        ...this.graphData,
        datasets: [this.graphData.datasets[0]]
      };
      newData.datasets.push(this.createGraphData(data, this.lineTitle + ' - srovnání', 'red'));
      return newData;
    } else {
      return this.transformGraphData(data);
    }
  }

  createGraphData(data: IBoxGraphData[], label: string, color: string): ChartDataset {
    return {
      yAxisID: 'first',
      label: label,
      data: data.map((item) => item.value),
      borderWidth: 2,
      backgroundColor: color,
      borderColor: color,
      type: 'line',
      fill: false
    };
  }

  removeSecondDayGraphData(): ChartData | undefined {
    if (this.graphData) {
      return {
        ...this.graphData,
        datasets: [this.graphData.datasets[0]]
      };
    } else {
      return undefined;
    }
  }

  goToPreviousDay() {
    const currentDate = this.dayControl.value as Date;
    if (currentDate) {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);
      this.formGroup.patchValue({ day: yesterday });
    }
  }

  goToNextDay() {
    const currentDate = this.dayControl.value as Date;
    if (currentDate) {
      const tomorrow = new Date(currentDate);
      tomorrow.setDate(currentDate.getDate() + 1);
      this.formGroup.patchValue({ day: tomorrow });
    }
  }

  changeIsCompareEnabled() {
    if (this.isCompareEnabled) {
      const currentDate = this.dayControl.value as Date;
      if (currentDate) {
        const nextDayBeforeCurrentDay = new Date(currentDate);
        nextDayBeforeCurrentDay.setDate(currentDate.getDate() - 1);
        this.formGroup.patchValue({ day: currentDate, secondDay: nextDayBeforeCurrentDay });
      }
    } else {
      this.graphData = this.removeSecondDayGraphData();
    }
  }

}
