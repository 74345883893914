<c-modal [visible]="modalVisible" (visibleChange)="handleModalChange($event)">
    <c-modal-header>
        <h5 cModalTitle>{{ title }}</h5>
        <button (click)="toggleModal()" cButtonClose></button>
    </c-modal-header>
    <c-modal-body>
        <ng-content></ng-content>
        <div *ngIf="error" class="alert alert-danger" role="alert">
            {{ error }}
        </div>
    </c-modal-body>
    <c-modal-footer class="d-flex justify-content-start">
            <button cButton color="primary" (click)="onSubmit()">
                <span>Smazat</span>
            </button>
            <button (click)="toggleModal()" cButton color="light">
                Zrušit
            </button>
    </c-modal-footer>
</c-modal>