import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormsModule} from "@angular/forms";

@Component({
  selector: 'app-custom-slider',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './custom-slider.component.html',
  styleUrl: './custom-slider.component.scss'
})
export class CustomSliderComponent {

  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() step: number = 1;
  @Input() form?: FormControl | null | undefined;

  @Output() valueChange = new EventEmitter<number>();

  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (this.form) {
      this.form.patchValue(input.value);
      this.form.markAsTouched();
    }
  }
}
