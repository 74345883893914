import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CreateServiceRequest, EditServiceRequest, GetServicesByServiceGroupRequest, GetServicesRequest, GetServicesResponse} from "../api";
import {ApiService} from "./api.service";
import {UserService} from "./user.service";
import {INewService, IService} from "../interface/service.interface";
import {ServiceMapper} from "../mapper/service.mapper";
import {NoResponseMapper} from "../mapper/no-response.mapper";

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private apiService: ApiService, private userService: UserService) { }

    getServicesByDeviceId(deviceId: string): Observable<IService[]> {
        const body: GetServicesRequest = {companyId: this.userService.getMyRealCompanyId(), deviceId};
        return this.apiService
            .post("/box/service/get-services", body)
            .pipe(map((res: GetServicesResponse) => ServiceMapper.mapServices(res)));
    }

    getServicesByServiceGroup(groupId: string): Observable<IService[]> {
        const body: GetServicesByServiceGroupRequest = {serviceGroupId: groupId};
        return this.apiService
            .post("/box/service/get-services-by-service-group", body)
            .pipe(map((res: GetServicesResponse) => ServiceMapper.mapServices(res)));
    }

    getMyServices(): Observable<IService[]> {
        const body: GetServicesRequest = {companyId: this.userService.getCompanyId()};
        return this.apiService
            .post("/box/service/get-services", body)
            .pipe(map((res: GetServicesResponse) => ServiceMapper.mapServices(res)));

    }

    createService(service: INewService): Observable<void> {
        const body: CreateServiceRequest = {...service};
        return this.apiService
            .post("/box/service/create-service", body, {clearAlerts: true})
            .pipe(map((res: GetServicesResponse) => NoResponseMapper.map()));
    }

    editService(service: IService): Observable<void> {
        const body: EditServiceRequest = {...service};
        return this.apiService
            .post("/box/service/edit-service", body, {clearAlerts: true})
            .pipe(map((res: GetServicesResponse) => NoResponseMapper.map()));
    }

}
