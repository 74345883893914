import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Breadcrumb} from "./Breadcrumb";

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbService {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  set breadcrumbs(breadcrumbs: Breadcrumb[]) {
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  clearBreadcrumbs() {
    this.breadcrumbsSubject.next([]);
  }
}
