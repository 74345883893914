import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_ADD_USER} from "../../../../interface/modal-type.interface";
import {getUserRoleOptions} from "../../../../util/translate/translate-key";

@Component({
  selector: 'app-admin-add-user',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent
    ],
  templateUrl: './admin-add-user.component.html',
  styleUrl: './admin-add-user.component.scss'
})
export class AdminAddUserComponent implements OnInit{
    @Output() userCreated: EventEmitter<void> = new EventEmitter<void>();

    form!: FormGroup;

    modalName = MODAL_NAME_ADD_USER // should be unique
    modalTitle = 'Přidat nového uživatele';

    roleOptions: ISelectOption[] = getUserRoleOptions(this.userService.getUserContext().role);

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            userName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            role: ['', [Validators.required]] ,
        })
    }

    onClose(): void {
        this.initForm();
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {userName, email, password, role} = this.form.value;
            this.userService.createUser(userName, email, password, role)
                .subscribe(() => {
                    this.userCreated.emit();
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Uživatel ${userName} úspěšně vytvořen.`);
                    this.initForm();
                });
        }
    }
}
