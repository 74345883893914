import {Component, forwardRef, Input} from '@angular/core';
import {IServiceGroupForSidebar} from "../../../interface/service.group.interface";
import {NgForOf, NgIf} from "@angular/common";
import {SideBarGroupComponent} from "../side-bar-group/side-bar-group.component";

@Component({
  selector: 'app-side-bar-groups',
  standalone: true,
    imports: [
        NgForOf,
        NgIf,
        forwardRef(() => SideBarGroupComponent),
    ],
  templateUrl: './side-bar-groups.component.html',
  styleUrl: './side-bar-groups.component.scss'
})
export class SideBarGroupsComponent {


    @Input()serviceGroups: IServiceGroupForSidebar[] = [];

    sortedServiceGroups(): IServiceGroupForSidebar[] {
        // Sort the array based on the orderNum property in ascending order
        return this.serviceGroups.sort((a, b) => a.orderNum - b.orderNum);
    }
}
