<div class="form-group mb-2">
    <!-- label-->
    <label *ngIf="!hideLabel" [for]="field">
        <strong>{{ label }}</strong>
        <span *ngIf="required" style="color: red">*</span>
    </label>

    <!-- input -->
    <input [id]="field" [formControl]="formControl()" type="text" class="form-control" [ngClass]="class">

    <!-- errors -->
    <ng-container *ngIf="formControl().invalid && formControl().touched">
        <div *ngIf="formControl().errors?.['required']" class="error">Pole {{ label.toLowerCase() }} je povinné.</div>
        <div *ngIf="formControl().errors?.['isNumber']" class="error">Musí být číslo.</div>
        <div *ngIf="formControl().errors?.['minlength']" class="error">
            Minimální délka {{formControl().errors?.['minlength'].requiredLength}}
        </div>
        <div *ngIf="formControl().errors?.['outOfRange']" class="error">
          Číslo je mimo rozsah ({{ formControl().errors?.['outOfRange'].min }} - {{ formControl().errors?.['outOfRange'].max }}).
        </div>
        <div *ngIf="formControl().errors?.['email']" class="error">Nevalidní formát e-mailu.</div>
    </ng-container>
</div>
