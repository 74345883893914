<div class="container">

    <div class="box-shadow my-3 min-h-44 pb-3 table-container">
        <h3>Přehled Servisních skupin</h3>
        <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" [(ngModel)]="onlyRootGroups">
            <label class="form-check-label" for="flexCheckChecked">
                Pouze root skupiny
            </label>
        </div>
        <app-table [config]="config" [data]="filteredServiceGroups" (rowClicked)="onRowClicked($event)"></app-table>
        <button type="button" class="btn btn-primary" (click)="onServiceGroupAdd($event)">Přidat skupinu</button>
    </div>

    <!-- modal dialogs    -->
    <app-add-service-group (groupCreated)="onServiceGroupAddSubmitted()"></app-add-service-group>
    <app-delete-service-group [group]="serviceGroupToBeDeleted" (groupDeleted)="onServiceGroupDeleteConfirmed($event)"></app-delete-service-group>
    <app-edit-service-group [group]="serviceGroupToBeEdited" (groupEdited)="onServiceGroupEditConfirmed($event)"></app-edit-service-group>
</div>
