<div class="form-group mb-2">

    <!-- label -->
    <label>
        <strong>{{ label }}</strong>
        <span *ngIf="required" style="color: red">*</span>
    </label>

    <!-- select -->
    <select class="form-select" aria-label="Default select example" [formControl]="formControl()">
        <option *ngFor="let option of getOptions()" [value]="option.key">{{ option.name }}</option>
    </select>

    <!-- error -->
    <ng-container *ngIf="formControl().invalid && formControl().touched">
        <div *ngIf="formControl().errors?.['required']" class="error">Pole {{ label.toLowerCase() }} je povinné.</div>
    </ng-container>
</div>
