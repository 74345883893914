<div class="container">
    <div class="box-shadow my-3 min-h-44 pb-3">
        <h3>Přehled firem</h3>
        <app-table [config]="config" [data]="companies" (rowClicked)="onRowClicked($event)"></app-table>
    </div>

    <button type="button" class="btn btn-primary" (click)="onCompanyAdd($event)">Přidat firmu</button>

    <!-- modal dialogs    -->
    <app-add-company (companyCreated)="onCompanyAddSubmitted()"></app-add-company>
    <app-delete-company [company]="companyToBeDeleted" (companyDeleted)="onCompanyDeleteConfirm($event)"></app-delete-company>
    <app-edit-company [company]="companyToBeEdited" (companyEdited)="onCompanyEditConfirmed($event)"></app-edit-company>
</div>
