/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AggregatorConfigResponse } from './aggregatorConfigResponse';
import { AggregatorActualDataResponse } from './aggregatorActualDataResponse';


export interface GetAggregatorDetailResponse { 
    aggregatorId: string;
    name: string;
    status: GetAggregatorDetailResponse.StatusEnum;
    actualData?: AggregatorActualDataResponse;
    requestedPower?: number;
    config?: AggregatorConfigResponse;
}
export namespace GetAggregatorDetailResponse {
    export type StatusEnum = 'running' | 'error' | 'disabled';
    export const StatusEnum = {
        Running: 'running' as StatusEnum,
        Error: 'error' as StatusEnum,
        Disabled: 'disabled' as StatusEnum
    };
}


