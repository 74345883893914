import {Component, Input} from '@angular/core';
import {CustomBarChartComponent} from '../../../shared/custom-bar-chart/custom-bar-chart.component';

@Component({
  selector: 'app-service-power-meter-detail',
  standalone: true,
  imports: [
    CustomBarChartComponent
  ],
  templateUrl: './service-power-meter-detail.component.html',
  styleUrls: ['./service-power-meter-detail.component.scss'],
})
export class ServicePowerMeterDetailComponent {

  @Input() serviceId?: string | null;
  @Input() serviceName?: string;

  constructor() {
  }

}
