import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ISortByField, ITableConfig} from "../table.component";
import {NgSwitch, NgSwitchCase} from "@angular/common";

@Component({
  selector: 'app-table-header-sort',
  standalone: true,
    imports: [
        NgSwitch,
        NgSwitchCase,
    ],
  templateUrl: './table-header-sort.component.html',
  styleUrl: './table-header-sort.component.scss'
})
export class TableHeaderSortComponent {

    @Input() sortByField: ISortByField | null = null;
    @Input() config: ITableConfig = {};
    @Output() sortByFieldSelected = new EventEmitter<ISortByField | null>();

    getIconClass(): string {
        if (this.isAlreadySortedByThisField()) {

            if (this.sortByField?.asc) {
                return 'cil-arrow-bottom';
            } else {
                return 'cil-arrow-top';
            }
        }

        return 'cil-swap-vertical'; // default
    }


    onSelect(event: Event) {
        event.preventDefault();

        if (!this.isAlreadySortedByThisField()) {
            // nothing selected yet => sort asc
            this.sortByFieldSelected.emit({field: this.config.field, asc: true});
        } else {
            if (this.sortByField?.asc) {
                // selected asc => sort desc
                this.sortByFieldSelected.emit({field: this.config.field, asc: false});
            } else {
                // selected desc => cancel sorting
                this.sortByFieldSelected.emit(null);
            }
        }
    }

    private isAlreadySortedByThisField(): boolean {
        return this.config.field === this.sortByField?.field;
    }
}
