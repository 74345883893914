import { booleanAttribute, Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { DatePickerComponent } from '@coreui/angular-pro';

@Component({
  selector: 'app-custom-date-picker-input',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    DatePickerComponent
  ],
  templateUrl: './custom-date-picker-input.component.html',
  styleUrl: './custom-date-picker-input.component.scss'
})
export class CustomDatePickerInputComponent implements OnChanges {
  @Input() form!: FormGroup;
  @Input() label!: string;
  @Input() field!: string;
  @Input() readonly: boolean = false;
  @Input({ transform: booleanAttribute }) required: boolean = false;
  @Input({ transform: booleanAttribute }) hideLabel: boolean = false;

  formControl(): FormControl {
    return this.form.get(this.field) as FormControl;
  }

  ngOnChanges() {
    if (this.readonly) {
      this.form.get(this.field)?.disable();
    } else {
      this.form.get(this.field)?.enable();
    }
  }

}
