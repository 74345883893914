import {Component, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';
import {NgClass, NgIf, NgOptimizedImage} from "@angular/common";
import {Router} from "@angular/router";
import {UserService} from "../../service/user.service";
import {NavigationService} from "../../service/navigation.service";

@Component({
  selector: 'app-user-settings',
  standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgOptimizedImage
    ],
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.scss'
})
export class UserSettingsComponent {

    show: boolean = false;

    constructor(private userService: UserService, private router: Router, private elementRef: ElementRef, private navigationService: NavigationService) {
    }

    toggleShow(event?: Event) {
        if(event) {
            event.preventDefault();
        }

        this.show = !this.show;
    }

    clickProfile(event: Event) {
        event.preventDefault();
        this.toggleShow();
        this.router.navigate(['/my-profile']);
    }

    signOut(event: Event) {
        event.preventDefault();
        this.userService.signOut();
        this.navigationService.forgetLastTabVisited();
        this.router.navigate(['/login']);
    }

    @HostListener('document:click', ['$event'])
    onClickOutsideTheElement(event: Event): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside && this.show) {
            this.toggleShow();
        }
    }
}
