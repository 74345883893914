import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {ModalUtils} from "../util/modal/modal.utils";

@Injectable({
  providedIn: 'root'
})
export class ModalDialogService {
  private readonly MODAL_OVERFLOW_HIDDEN_CSS_CLASS = 'modal-overflow-hidden';
  private readonly MODAL_CDK_OVERLAY_CONTAINER_Z_INDEX = 'modal-cdk-overlay-container-z-index';

  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, private ngbModal: NgbModal) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  dismissAll(reason?: any): void {
    this.ngbModal.dismissAll(reason);
  }

  /**
   * Open the modal with options, if provided, or use the default options. Additionally, add CSS styles to the 'html' tag
   * to prevent the page from scrolling to the top of the page. CSS styles are removed when the modal is closed.
   *
   * @param content component with modal definition
   * @param options modal options
   * @param isModalWithFormElements flag to recognize if modal has form elements to set correct CSS z-index
   *
   * @return reference to the opened modal dialog
   */
  open(content: any, options?: NgbModalOptions, isModalWithFormElements?: boolean): NgbModalRef {
    this.addGlobalCssClassesForModal(isModalWithFormElements);
    const modalRef = this.ngbModal.open(content, options);
    modalRef.result.finally(() => this.removeGlobalCssClassesForModal());
    return modalRef;
  }

    openLg(content: any): NgbModalRef {
        return this.open(content, ModalUtils.createDefaultModalOptions('lg'))
    }

  private addGlobalCssClassesForModal(isModalWithFormElements: boolean = false): void {
    this.renderer.addClass(document.documentElement, this.MODAL_OVERFLOW_HIDDEN_CSS_CLASS);

    if (isModalWithFormElements) {
      this.renderer.addClass(document.documentElement, this.MODAL_CDK_OVERLAY_CONTAINER_Z_INDEX);
    }
  }

  private removeGlobalCssClassesForModal(): void {
    this.renderer.removeClass(document.documentElement, this.MODAL_OVERFLOW_HIDDEN_CSS_CLASS);
    this.renderer.removeClass(document.documentElement, this.MODAL_CDK_OVERLAY_CONTAINER_Z_INDEX);
  }
}
