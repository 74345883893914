import {Component, OnInit} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {DatePickerComponent} from "@coreui/angular-pro";
import {RouterLink} from "@angular/router";
import {Breadcrumb} from "../../../service/breadcrumb/Breadcrumb";
import {ClientBreadcrumbService} from "../../../service/breadcrumb/client-breadcrumb.service";

@Component({
    selector: 'app-client-breadcrumb',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        NgIf,
        DatePickerComponent,
        RouterLink,
        NgForOf
    ],
    templateUrl: './client-breadcrumb.component.html',
    styleUrl: './client-breadcrumb.component.scss'
})
export class ClientBreadcrumbComponent implements OnInit {
    clientBreadcrumbs: Breadcrumb[] = [];

    constructor(private clientBreadcrumbService: ClientBreadcrumbService) {
    }

    ngOnInit() {
        this.clientBreadcrumbService.clientBreadcrumbs$.subscribe(breadcrumbs => {
            this.clientBreadcrumbs = breadcrumbs;
        });
    }
}
