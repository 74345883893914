<div class="dropdown" [ngClass]="{ 'margin-big-screen': screenBiggerThanMd() }">
    <button class="btn btn-dark dropdown-toggle" (click)="onClick($event)" type="submit">{{ this.selectedCompany?.name }}</button>
    <ul class="dropdown-menu" [ngClass]="{ 'show': show }">
        <li class="px-1">
            <input type="text" class="form-control" placeholder="Filtrovat..." [(ngModel)]="filterText" #filterInput>
        </li>
        <li>
            <hr class="dropdown-divider">
        </li>
        <li *ngFor='let company of getCompaniesByFilter()'>
            <app-company-select-option [company]="company" (companyChanged)="onCompanySelect($event)"></app-company-select-option>
        </li>
    </ul>
</div>