import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {map} from "rxjs/operators";
import {AddCompanyRequest, CompanyResponse, DeleteCompanyRequest, EditCompanyRequest, GetCompaniesResponse, GetCompanyRequest} from "../api";
import {ICompany, INewCompany} from "../interface/company.interface";
import {Observable, of, tap} from "rxjs";
import {UserService} from "./user.service";
import {CompanyMapper} from "../mapper/company.mapper";
import {IHttpConfig} from "../interface/http-config.interface";
import {NoResponseMapper} from "../mapper/no-response.mapper";

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private apiService: ApiService, private userService: UserService) {
    }

    private companyNamesMap: Map<string, string> = new Map<string, string>();

    getAllCompanies(): Observable<ICompany[]> {
        return this.apiService
            .post("/company/get-companies", {})
            .pipe(
                map((res: GetCompaniesResponse) => CompanyMapper.mapCompanies(res)),
                tap((companies: ICompany[]) => {
                    companies.forEach(company => {
                        this.companyNamesMap.set(company.companyId, company.name);
                    });
                })
            );
    }

    getCompany(companyId: string): Observable<ICompany> {
        const body: GetCompanyRequest = {companyId};
        return this.apiService
            .post("/company/get-company", body)
            .pipe(map((res: CompanyResponse) => CompanyMapper.mapCompany(res)));
    }

    getCachedCurrentCompanyName(): Observable<string> {
        const companyId = this.userService.getCompanyId();
        if (this.companyNamesMap.has(companyId)) {
            return of(this.companyNamesMap.get(companyId)!);
        } else {
            return this.getCompany(companyId).pipe(
                map((company: ICompany) => {
                    const companyName = company.name;
                    this.companyNamesMap.set(companyId, companyName);
                    return companyName;
                })
            );
        }
    }


    getMyRealCompany(): Observable<ICompany> {
        const body: GetCompanyRequest = {companyId: this.userService.getMyRealCompanyId()};
        return this.apiService
            .post("/company/get-company", body)
            .pipe(map((res: CompanyResponse) => CompanyMapper.mapCompany(res)));
    }

    createCompany(company: INewCompany): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}

        const body: AddCompanyRequest = {...company};

        return this.apiService.post("/company/add-company", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    deleteCompany(companyId: string): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}

        const body: DeleteCompanyRequest = {companyId};

        return this.apiService.post("/company/delete-company", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }

    editCompany(company: ICompany): Observable<void> {
        const config: IHttpConfig = {clearAlerts: true}

        const body: EditCompanyRequest = {...company};

        return this.apiService.post("/company/edit-company", body, config).pipe(
            map(() => NoResponseMapper.map())
        );
    }
}
