import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {DateReadonlyInputComponent} from "../../shared/date-readonly-input/date-readonly-input.component";
import {NgClass, NgForOf} from "@angular/common";
import {TextInputComponent} from "../../shared/text-input/text-input.component";
import {TextInputReadonlyComponent} from "../../shared/text-input-readonly/text-input-readonly.component";
import {IService, translateType} from "../../../interface/service.interface";
import {ActivatedRoute} from "@angular/router";
import {BoxServiceService} from "../../../service/box-service.service";
import {CompanySelectOptionComponent} from "../../company-select-option/company-select-option.component";
import {IServiceGroup} from "../../../interface/service.group.interface";
import {ServiceGroupService} from "../../../service/service-group.service";
import {ServiceGroupSelectItemComponent} from "../../service-group-select-item/service-group-select-item.component";
import {UiService} from "../../../service/ui.service";
import {MODAL_NAME_DELETE_SERVICE, MODAL_NAME_EDIT_SERVICE} from "../../../interface/modal-type.interface";
import {AdminEditServiceComponent} from "../admin-device-detail-page/admin-edit-service/admin-edit-service.component";
import {ITableConfig, TableComponent} from "../../shared/table/table.component";
import {DropdownComponent} from "../../shared/dropdown/dropdown.component";
import {IDropdownItem} from "../../../interface/dropdown.item.interface";
import {NavigationService} from "../../../service/navigation.service";
import {AdminBreadcrumbService} from "../../../service/breadcrumb/admin-breadcrumb.service";
import {AdminDeviceDeleteServiceComponent} from "../admin-device-detail-page/admin-device-delete-service/admin-device-delete-service.component";

@Component({
    selector: 'app-admin-service-detail-page',
    standalone: true,
    imports: [
        DateReadonlyInputComponent,
        NgForOf,
        TextInputComponent,
        TextInputReadonlyComponent,
        CompanySelectOptionComponent,
        NgClass,
        ServiceGroupSelectItemComponent,
        AdminEditServiceComponent,
        TableComponent,
        DropdownComponent,
        AdminDeviceDeleteServiceComponent,
    ],
    templateUrl: './admin-service-detail-page.component.html',
    styleUrl: './admin-service-detail-page.component.scss'
})
export class AdminServiceDetailPageComponent implements OnInit {
    @Input() service?: IService;
    id?: string;

    readonly: boolean = true;
    showDropDown: boolean = false;

    filterText: string = '';

    allMyServicesGroups: IServiceGroup[] = [];
    serviceInsideGroups: IServiceGroup[] = [];

    config: ITableConfig[] = [
        {field: 'name', label: 'Název'},
        {field: 'note', label: 'Poznámka'},
        {
            specialComponent: {
                type: 'delete',
                onClick: this.onRemoveFromServiceGroup.bind(this)
            }
        },
    ];

    @ViewChild('filterInput', {static: false}) filterInput!: ElementRef;

    constructor(private boxService: BoxServiceService,
                private route: ActivatedRoute,
                private uiService: UiService,
                private navigationService: NavigationService,
                private adminBreadcrumbService: AdminBreadcrumbService,
                private serviceGroupService: ServiceGroupService,
                private elementRef: ElementRef) {
    }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.id = params['serviceId'];
            if (this.id) {
                this.loadData();
            }
        });
    }

    onEditServiceEditConfirmed(service: IService): void {
        this.loadService();
    }

    onServiceGroupClicked(service: IServiceGroup): void {
        this.navigationService.toAdminServiceGroup(service.id);
    }

    onServiceEdit(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_EDIT_SERVICE);
    }

    getPossibilities(): IDropdownItem[] {
        // do not suggest service groups which already are inside this group
        const ids = new Set(
            this.serviceInsideGroups.map(item => item.id)
        );

        return this.allMyServicesGroups
            .filter(item => !ids.has(item.id))
            .map((group: IServiceGroup) => {
                return {id: group.id, name: group.name} as IDropdownItem;
            })
    }

    onGroupSelected(item: IDropdownItem) {
        this.boxService.addToServiceGroup(this.id!!, item.id)
            .subscribe(() => {
                this.uiService.setSuccessAlert(`Skupina úspěšně přídána do skupiny ${item.name}`);
                this.loadGroupsRelatedToService();
            })
    }

    onDeleteService(event: Event): void {
        event.preventDefault();
        this.uiService.toggleModal(MODAL_NAME_DELETE_SERVICE);
    }

    onDeleteServiceConfirm(service: IService) {
        this.navigationService.toAdminDeviceDetail(service.deviceId);
    }

    onClick(event: Event) {
        event.preventDefault();
        this.showDropDown = !this.showDropDown;
        if (this.showDropDown) {
            setTimeout(() => {
                this.filterInput.nativeElement.focus();
            }, 150); // element is not visible imidietaly
        }
    }

    @HostListener('document:click', ['$event']) @HostListener('document:keydown.escape', ['$event'])
    onClickOutsideTheElement(event: Event): void {
        const clickedInside = this.elementRef.nativeElement.contains(event.target);
        if (!clickedInside && this.showDropDown) {
            this.showDropDown = false;
        }
    }

    getServicesGroupsByFilter(): IServiceGroup[] {
        if (!this.filterText) {
            return this.getServiceGroupPossibilities();
        }

        return this.getServiceGroupPossibilities()
            .filter((serviceGroup: IServiceGroup) => serviceGroup.name.toLowerCase().includes(this.filterText.toLowerCase()));
    }


    onServiceGroupSelect(group: IServiceGroup) {
        this.showDropDown = false;
        this.boxService.addToServiceGroup(this.id!!, group.id)
            .subscribe(() => {
                this.serviceInsideGroups = [...this.serviceInsideGroups, group];
                this.uiService.setSuccessAlert(`Skupina úspěšně přídána do skupiny ${group.name}`);
            });
    }

    onRemoveFromServiceGroup(group: IServiceGroup): void {
        this.boxService.removeFromServiceGroup(this.id!!, group.id)
            .subscribe(() => {
                this.serviceInsideGroups = this.serviceInsideGroups.filter((item: IServiceGroup) => item.id !== group.id);
                this.uiService.setSuccessAlert(`Skupina úspěšně odebrána ze skupiny ${group.name}`);
            });
    }

    getServiceGroupPossibilities(): IServiceGroup[] {
        // do not suggest groups which already contains this service
        const groupIds = new Set(this.serviceInsideGroups.map(group => group.id));
        return this.allMyServicesGroups.filter(group => !groupIds.has(group.id));
    }

    private loadData(): void {
        this.loadService();
        this.serviceGroupService.getMyServiceGroups().subscribe(
            (data: IServiceGroup[]) => {
                this.allMyServicesGroups = data;
            }
        );
        this.loadGroupsRelatedToService();
    }

    private loadGroupsRelatedToService(): void {
        this.serviceGroupService.getServiceGroupsByServiceId(this.id!!).subscribe(
            (data: IServiceGroup[]) => {
                this.serviceInsideGroups = data;
            }
        )

    }

    private loadService(): void {
        this.boxService.getService(this.id!).subscribe(
            (data: IService) => {
                this.service = data;
                this.adminBreadcrumbService.ofServiceDetail(this.service);
            }
        );
    }

    protected readonly translateType = translateType;
}
