<ng-container [ngSwitch]="getType()">
    <td *ngSwitchCase="'standard'" class="align-middle ps-2">
        <ng-container *ngIf="!hideComponent()">
            {{ getValueByField() }}
        </ng-container>
    </td>
    <td *ngSwitchCase="'date'" class="align-middle ps-2">
        <ng-container *ngIf="!hideComponent()">
            {{ getValueByField() | date }}
        </ng-container>
    </td>
    <td *ngSwitchCase="'delete'" class="align-middle text-center" style="min-width: 32px">
        <ng-container *ngIf="!hideComponent()">
            <img *ngIf="!hideComponent()" (click)="onClick($event)" class="trash" ngSrc="assets/icon/trash.svg" height="24" width="24" alt="trash">
        </ng-container>
    </td>
    <td *ngSwitchCase="'minus'" class="align-middle text-center" style="min-width: 32px">
        <ng-container *ngIf="!hideComponent()">
            <img (click)="onClick($event)" class="trash" ngSrc="assets/icon/odebrat.svg" height="24" width="24" alt="trash">
        </ng-container>
    </td>
    <td *ngSwitchCase="'edit'" class="align-middle text-center" style="min-width: 32px">
        <ng-container *ngIf="!hideComponent()">
            <img (click)="onClick($event)" class="trash" ngSrc="assets/icon/pencil.svg" height="24" width="24" alt="trash">
        </ng-container>
    </td>
    <td *ngSwitchCase="'serviceType'" class="align-middle ps-2">
        <ng-container *ngIf="!hideComponent()">
            {{ translateType(getValueByField()) }}
        </ng-container>
    </td>
    <td *ngSwitchCase="'status'" class="align-middle">
        <ng-container *ngIf="!hideComponent()">
            <app-status [status]="getValueByField()"></app-status>
        </ng-container>
    </td>
</ng-container>
