import {Component, Input} from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IHeatingBranchDataDetail} from "../../../../interface/heating-branch.interface";
import {IHotWaterTankDetail} from "../../../../interface/box-service/hot-water-tank-detail";
import {IHotWaterTankDataDetail} from "../../../../interface/hot-water-tank.interface";

@Component({
  selector: 'app-service-hot-water-tank',
  templateUrl: './service-hot-water-tank.component.html',
  styleUrl: 'service-hot-water-tank.component.scss',
  imports: [],
  standalone: true
})
export class ServiceHotWaterTankComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

    getDetail(): IHotWaterTankDataDetail | undefined {
        return this.service?.detail as IHotWaterTankDataDetail;
    }

  getTempTank(): number {
      return this.getDetail()?.tempTank || 0;
  }

  getTempCirculation(): number {
      return this.getDetail()?.tempCirculation || 0;
  }

}
