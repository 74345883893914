import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalDialogTypeEnum} from "./modal-dialog-type.enum";
import {NgClass, NgIf} from "@angular/common";

@Component({
  selector: 'app-modal-dialog',
  standalone: true,
  templateUrl: './modal-dialog.component.html',
  imports: [
    NgClass,
    NgIf
  ],
  styleUrls: ['./modal-dialog.component.scss']
})
export class ModalDialogComponent implements OnInit {
  @Input() title: string;
  @Input() text: string;
  @Input() noButtonLabel: string;
  @Input() yesButtonLabel: string;
  @Input() showNoButton?: boolean;
  @Input() showYesButton?: boolean;
  @Input() showCloseButton?: boolean;
  @Input() withoutParent: boolean;
  @Input() type: ModalDialogTypeEnum;
  @Output() buttonEvent: EventEmitter<boolean>;

  ModalDialogTypeEnum = ModalDialogTypeEnum;

  constructor(private activeModal: NgbActiveModal) {
    this.title = '';
    this.text = '';
    this.noButtonLabel = '';
    this.yesButtonLabel = '';
    this.showNoButton = true;
    this.showYesButton = true;
    this.showCloseButton = true;
    this.withoutParent = true;
    this.type = ModalDialogTypeEnum.INFO;
    this.buttonEvent = new EventEmitter();
  }

  ngOnInit() {
  }

  close() {
    if (this.withoutParent) {
      this.activeModal.close(false);
    } else {
      this.buttonEvent.emit(false);
    }
  }

  accept() {
    if (this.withoutParent) {
      this.activeModal.close(true);
    } else {
      this.buttonEvent.emit(true);
    }
  }
}
