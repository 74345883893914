import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ICompany} from "../../interface/company.interface";
import {UserService} from "../../service/user.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-company-select-option',
    standalone: true,
    imports: [],
    templateUrl: './company-select-option.component.html',
    styleUrl: './company-select-option.component.scss'
})
export class CompanySelectOptionComponent {
    @Input() company!: ICompany;
    @Output() companyChanged: EventEmitter<ICompany> = new EventEmitter<ICompany>();

    constructor(private userService: UserService, private router: Router) {
    }

    onCompanySelect(event: Event) {
        event.preventDefault();
        if (this.company.companyId) {
            this.userService.changeCompanyId(this.company.companyId);
            this.companyChanged.emit(this.company)
            this.router.navigate(['/home'])
        }
    }
}
