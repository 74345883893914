import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_ADD_SERVICE_GROUP} from "../../../../interface/modal-type.interface";
import {ServiceGroupService} from "../../../../service/service-group.service";
import {CustomValidators} from "../../../../util/validator/custom.validators";
import {INewServiceGroup} from "../../../../interface/service.group.interface";
import {ActionService} from "../../../../service/action.service";

@Component({
  selector: 'app-add-service-group',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        FormModalComponent
    ],
  templateUrl: './add-service-group.component.html',
  styleUrl: './add-service-group.component.scss'
})
export class AddServiceGroupComponent implements OnInit{
    @Output() groupCreated: EventEmitter<void> = new EventEmitter<void>();
    @Input() parentServiceGroupId?: string;

    form!: FormGroup;

    modalName = MODAL_NAME_ADD_SERVICE_GROUP // should be unique
    modalTitle = 'Přidat novou skupinu';

    constructor(
        private fb: FormBuilder,
        private groupService: ServiceGroupService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            note: [''],
            orderNum: [0, [Validators.required, CustomValidators.isNumber]],
        })
    }

    onClose(): void {
        this.initForm();
    }

    onSubmit() {
        if (this.form.valid) {
            const {name, note, orderNum} = this.form.value;
            const parentServiceGroupId = this.parentServiceGroupId;
            const group: INewServiceGroup = {name, note, orderNum, parentServiceGroupId};
            this.groupService.createGroup(group)
                .subscribe(() => {
                    this.groupCreated.emit();
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Skupina ${name} úspěšně vytvořena`);
                    this.initForm();
                });
        }
    }
}
