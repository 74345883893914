import {Component, OnInit} from '@angular/core';
import {
    FormBuilder,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import {Router} from '@angular/router';
import {NgIf} from '@angular/common';
import {
    FormModule,
    ButtonModule,
    InputGroupComponent,
} from '@coreui/angular-pro';
import {UserService} from "../../../service/user.service";
import {NavigationService} from "../../../service/navigation.service";

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        FormModule,
        ButtonModule,
        InputGroupComponent,
        NgIf,
    ],
})
export class LoginPageComponent implements OnInit {
    loginForm: FormGroup;
    passwordFieldType: string = 'password';

    constructor(
        private fb: FormBuilder,
        private userService: UserService,
        private navigationService: NavigationService,
    ) {
        this.loginForm = this.fb.group({
            loginName: ['', Validators.required],
            loginPassword: ['', Validators.required],
        });
    }

    authorize() {
        if (this.loginForm.valid) {
            const {loginName, loginPassword} = this.loginForm.value;

            this.userService.login(loginName, loginPassword).subscribe({
                next: () => {
                    this.navigationService.toHome();
                    this.navigationService.forgetLastTabVisited();
                },
            });
        }
    }

    togglePasswordVisibility() {
        this.passwordFieldType =
            this.passwordFieldType === 'password' ? 'text' : 'password';
    }

    ngOnInit(): void {
        this.userService.signOut();
        this.navigationService.forgetLastTabVisited();
    }
}
