import {Injectable} from "@angular/core";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TimeControlResponse} from "../../api";

@Injectable({
  providedIn: 'root',
})
export class ValveHeatingBranchSettingsMapper {
  constructor(private fb: FormBuilder) {
  }

  mapResponseToSettings(response: GetValveHeatingBranchSettingsResponse): IValveHeatingBranchSettings {
    return {
      controlType: response.controlType,
      reducedTemp: response.reducedTemp,
      constTemp: response.constTemp,
      timeControl: response.timeControl ? {
        monday: response.timeControl.monday ? response.timeControl.monday.hourSettings : undefined,
        tuesday: response.timeControl.tuesday ? response.timeControl.tuesday.hourSettings : undefined,
        wednesday: response.timeControl.wednesday ? response.timeControl.wednesday.hourSettings : undefined,
        thursday: response.timeControl.thursday ? response.timeControl.thursday.hourSettings : undefined,
        friday: response.timeControl.friday ? response.timeControl.friday.hourSettings : undefined,
        saturday: response.timeControl.saturday ? response.timeControl.saturday.hourSettings : undefined,
        sunday: response.timeControl.sunday ? response.timeControl.sunday.hourSettings : undefined,
      } : undefined,
    } as IValveHeatingBranchSettings;
  }

  mapSettingsToForm(settings: IValveHeatingBranchSettings): FormGroup {
    return this.fb.group({
      controlType: this.translateControlTypeToCZ(settings.controlType),
      reducedTemp: settings.reducedTemp,
      constTemp: settings.constTemp,
      timeControl: settings.timeControl
    });
  }

  mapFormToRequest(serviceId: string, form: FormGroup): SetValveHeatingBranchSettingsRequest {
    return {
      serviceId: serviceId,
      controlType: this.translateControlTypeToRequest(form.get('controlType')?.value),
      reducedTemp: form.get('reducedTemp')?.value,
      constTemp: form.get('constTemp')?.value,
      timeControl: this.mapTimeControlToTimeControlRequestResponse(form.get('timeControl')?.value)
    } as SetValveHeatingBranchSettingsRequest;
  }

  mapTimeControlToTimeControlRequestResponse(timeControl: ITimeControl): TimeControlRequestResponse {
    return {
      monday: timeControl.monday ? this.mapTimeControlToTimeControlDayRequestResponse(timeControl.monday) : [],
      tuesday: timeControl.tuesday ? this.mapTimeControlToTimeControlDayRequestResponse(timeControl.tuesday) : [],
      wednesday: timeControl.wednesday ? this.mapTimeControlToTimeControlDayRequestResponse(timeControl.wednesday) : [],
      thursday: timeControl.thursday ? this.mapTimeControlToTimeControlDayRequestResponse(timeControl.thursday) : [],
      friday: timeControl.friday ? this.mapTimeControlToTimeControlDayRequestResponse(timeControl.friday) : [],
      saturday: timeControl.saturday ? this.mapTimeControlToTimeControlDayRequestResponse(timeControl.saturday) : [],
      sunday: timeControl.sunday ? this.mapTimeControlToTimeControlDayRequestResponse(timeControl.sunday) : [],
    } as TimeControlRequestResponse;
  }

  mapTimeControlToTimeControlDayRequestResponse(array: string[]): TimeControlDayRequestResponse {
    return {
      hourSettings: array
    } as TimeControlDayRequestResponse;
  }

  translateControlTypeToCZ(option: string): string {
    if (option === 'timeControl') {
      return 'Rozvrh';
    } else if (option === 'const') {
      return 'Topit';
    } else if (option === 'reduced') {
      return 'Útlum';
    } else {
      return 'Vypnuto';
    }
  }

  translateControlTypeToRequest(option: string): string {
    if (option === 'Rozvrh') {
      return 'timeControl';
    } else if (option === 'Topit') {
      return 'const';
    } else if (option === 'Útlum') {
      return 'reduced';
    } else {
      return 'off';
    }
  }
}

export interface GetValveHeatingBranchSettingsResponse {
  controlType: string;
  reducedTemp?: number;
  constTemp?: number;
  timeControl?: TimeControlResponse;
}

export interface SetValveHeatingBranchSettingsRequest {
  controlType: string;
  reducedTemp?: number;
  constTemp?: number;
  timeControl?: TimeControlRequestResponse;
}

export interface TimeControlRequestResponse {
  monday?: TimeControlDayRequestResponse;
  tuesday?: TimeControlDayRequestResponse;
  wednesday?: TimeControlDayRequestResponse;
  thursday?: TimeControlDayRequestResponse;
  friday?: TimeControlDayRequestResponse;
  saturday?: TimeControlDayRequestResponse;
  sunday?: TimeControlDayRequestResponse;
}

export interface TimeControlDayRequestResponse {
  hourSettings: Array<string>;
}

export interface IValveHeatingBranchSettings {
  controlType: string;
  timeControl?: ITimeControl;
  name?: string;
  reducedTemp?: number;
  constTemp?: number;
}

export interface ITimeControl {
  monday?: Array<string>;
  tuesday?: Array<string>;
  wednesday?: Array<string>;
  thursday?: Array<string>;
  friday?: Array<string>;
  saturday?: Array<string>;
  sunday?: Array<string>;
}
