import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_EDIT_CONSUMPTION_POINT} from "../../../../interface/modal-type.interface";
import {IConsumptionPoint, IConsumptionPointDetail} from "../../../../interface/consumption-point.interface";
import {CustomValidators} from "../../../../util/validator/custom.validators";
import {ConsumptionPointService} from "../../../../service/consumption-point.service";

@Component({
  selector: 'app-edit-consumption-point',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent
    ],
  templateUrl: './edit-consumption-point.component.html',
  styleUrl: './edit-consumption-point.component.scss'
})
export class EditConsumptionPointComponent implements OnInit, OnChanges {
    @Input() consumptionPoint?: IConsumptionPoint;
    @Input() electricityDistributors: ISelectOption[] = [];
    @Output() edited: EventEmitter<IConsumptionPoint> = new EventEmitter<IConsumptionPoint>();

    form!: FormGroup;

    modalName = MODAL_NAME_EDIT_CONSUMPTION_POINT // should be unique
    modalTitle = 'Upravit odběrné místo';

    constructor(
        private fb: FormBuilder,
        private consumptionPointService: ConsumptionPointService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['consumptionPoint']) {
            const point: IConsumptionPoint = changes['consumptionPoint'].currentValue as IConsumptionPoint;
            if (point) {
                this.form = this.fb.group({
                    ean: [point.ean, [CustomValidators.isNumber]],
                    eic: [point.eic, []],
                    address: [point.address, ],
                    electricityDistributor: [point.electricityDistributor] ,
                    voltageLevel: [point.voltageLevel, []] ,
                    gps: [point.gps, []] ,
                })
            }
        }
    }
    private initForm(): void {
        this.form = this.fb.group({
            ean: ['', [Validators.required, CustomValidators.isNumber]],
            eic: ['', [CustomValidators.isNumber]],
            address: ['', ],
            electricityDistributor: ['', []] ,
            gasDistributor: ['', []] ,
            gps: ['', []] ,
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const point: IConsumptionPoint = {...this.consumptionPoint, ...this.form.value} as IConsumptionPoint;

            this.consumptionPointService.editConsumptionPoint(point)
                .subscribe(() => {
                    this.edited.emit(point);
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Odběrné místo ${point.ean ?? ''} úspěšně upraveno.`);
                });
        }
    }
}
