import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetBoxGraphDataRequest, GetBoxGraphDataResponse} from '../api';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {BoxGraphMapper} from '../mapper/box-graph.mapper';
import {IBoxGraphData} from '../interface/box-graph-data.interface';
import {BoxGraphDataRequest} from './api.model/boxGraphDataRequest';

@Injectable({
  providedIn: 'root'
})
export class BoxGraphService {

  constructor(private apiService: ApiService) {
  }


  getServiceDetailTemperatureGraph(serviceId: string, from?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: GetBoxGraphDataRequest = {serviceId, from, type};
    return this.apiService
      .post('/box/graph/get-temperature-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }

  getServiceDetailVolumeGraph(serviceId: string, from?: string, to?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: BoxGraphDataRequest = {serviceId, from, to, type};
    return this.apiService
      .post('/box/graph/get-volume-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }

  getServiceDetailPowerGraph(serviceId: string, from?: string, to?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: BoxGraphDataRequest = {serviceId, from, to, type};
    return this.apiService
      .post('/box/graph/get-power-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }

  getServiceEnergyVolumeGraph(serviceId: string, from?: string, to?: string, type?: string): Observable<IBoxGraphData[]> {
    const body: BoxGraphDataRequest = {serviceId, from, to, type};
    return this.apiService
      .post('/box/graph/get-energy-graph-data', body)
      .pipe(map((res: GetBoxGraphDataResponse) => BoxGraphMapper.mapBoxGraphData(res)));
  }
}
