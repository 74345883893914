import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {
  CreateSpotCalculationRequest,
  CreateSpotCalculationResponse,
  GetSpotCalculationStateRequest, GetSpotCalculationStateResponse,
  UploadFileAndStartRequest,
} from "../api";
import {map} from "rxjs/operators";
import {ApiService} from "./api.service";
import { HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SpotModelService {

  constructor(private apiService: ApiService) {
  }

  createSpotCalculation(request: CreateSpotCalculationRequest): Observable<string | undefined> {
    return this.apiService
      .post("/spot-model/create-spot-calculation", request)
      .pipe(map((res: CreateSpotCalculationResponse) => res.taskId));
  }

  uploadFileAndStart(taskId: string, request: UploadFileAndStartRequest): Observable<string | undefined> {
    const formData = new FormData();
    formData.append('file', request.file, 'file.xlsx');

    const params = new HttpParams().set('taskId', taskId);

    return this.apiService
      .post("/spot-model/upload-file-and-start", formData, {}, params)
      .pipe(map((res: CreateSpotCalculationResponse) => res.taskId));
  }

  getSpotCalculationState(request: GetSpotCalculationStateRequest): Observable<GetSpotCalculationStateResponse> {
    return this.apiService
      .post("/spot-model/get-spot-calculation-state", request, {})
      .pipe(map((res: GetSpotCalculationStateResponse) => res));
  }

  downloadResult(taskId: string): Observable<File> {
    const params = new HttpParams().set('taskId', taskId);

    return this.apiService
      .get("/spot-model/download-result", {}, params, 'blob')
      .pipe(map(response => {
        return new File([response], 'downloadedResult.xlsx');
      }));
  }
}
