<div class="grid-wrapper">
  <div class="grid-container" (mousedown)="startDrag()" (mouseup)="endDrag()">
    <div class="grid-header-column">
      <div class="grid-header-cell"></div> <!-- Empty cell for top-left corner -->
      <div class="grid-header-cell" *ngFor="let hour of hours">
        {{ hour }}
      </div>
    </div>

    <div class="grid-column" *ngFor="let day of days">
      <div class="grid-header-cell">
        {{ translateDayToCz(day) }}
      </div>
      <ng-container class="custom-ng-container">
        <div
          class="grid-cell"
          *ngFor="let hour of hours"
          [ngClass]="getSymbolClass(schedule[day][hour])"
          (mousedown)="changeSymbol(day, hour)"
          (mouseover)="dragChange(day, hour)"
        >
          {{ translateHourToCz(schedule[day][hour]) }}
        </div>
      </ng-container>
    </div>
  </div>
</div>
