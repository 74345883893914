import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import { ReactiveFormsModule} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {DeleteModalComponent} from "../../../shared/delete-modal/delete-modal.component";
import {UiService} from "../../../../service/ui.service";
import {ICompany} from "../../../../interface/company.interface";
import {MODAL_NAME_DELETE_COMPANY} from "../../../../interface/modal-type.interface";
import {CompanyService} from "../../../../service/company.service";
import {UserService} from "../../../../service/user.service";

@Component({
  selector: 'app-delete-company',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent,
        DeleteModalComponent
    ],
  templateUrl: './delete-company.component.html',
  styleUrl: './delete-company.component.scss'
})
export class DeleteCompanyComponent {

    @Input() company?: ICompany;
    @Output() companyDeleted: EventEmitter<ICompany> = new EventEmitter<ICompany>();

    modalName = MODAL_NAME_DELETE_COMPANY; // should be unique
    modalTitle = 'Smazat firmu?';

    constructor(private companyService: CompanyService, private uiService: UiService, private userService: UserService, private userSertive: UserService) {
    }

    onConfirm() {
        if (this.company?.companyId === this.userService.getMyRealCompanyId()) {
            alert('Not allowed to delete own company. Ha, ha, ha.');
        } else {
            this.companyService.deleteCompany(this.company!.companyId).subscribe(() => {
                this.companyDeleted.emit(this.company);
                this.uiService.toggleModal(this.modalName);
                this.uiService.setSuccessAlert(`Firma ${this.company?.name} úspěšně smazána.`);
            })
        }
    }

}
