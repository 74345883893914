import {Component, OnInit} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { temporaryOteApiService } from '../../service/ote.service';
import { PowerMeterService } from '../../service/power-meter.service';
import {
  ButtonDirective,
  ColComponent,
  DatePickerComponent,
  RowComponent,
} from '@coreui/angular-pro';
import {ChartjsComponent} from "@coreui/angular-chartjs";
import {CommonModule} from '@angular/common';
import { IPowerData } from '../../interface/power-data.interface';
import { IActualPrice } from '../../interface/actual-price.interface';
import type { ChartData, ChartOptions } from 'chart.js';
import {debounceTime, distinctUntilChanged} from "rxjs";
import {UserService} from "../../service/user.service";
import {GetPowerMeterDataResponse, PowerMeterDataResponse} from "../../api";
import {UiService} from "../../service/ui.service";
import {NavigationService} from "../../service/navigation.service";


@Component({
  selector: 'app-dashboard',
  standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ColComponent,
        DatePickerComponent,
        RowComponent,
        ButtonDirective,
        ChartjsComponent,
    ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  public selectedDate = new FormControl(new Date());
  public maxDate = new Date();
  spotPriceGraphData?: ChartData;
    temporaryHideGraphHack: boolean = false;
  // consumptionGraphData?: ChartData;
  actualPrice: IActualPrice = {
    level: '',
    actualPriceCZK: 0,
  };
  currentPower: number = 0;

    chartOptions: ChartOptions = {
        // responsive: true,
        scales: {
            first: {
                type: 'linear',
                position: 'left',
                beginAtZero: true,
            },
            second: {
                type: 'linear',
                position: 'right',
                beginAtZero: false,
                // suggestedMin: 0,
                // min: -5.5,,
                grid: { display: false }
            },
        }
    };

  constructor(
    private apiOteService: temporaryOteApiService,
    private uiService: UiService,
    private navigationService: NavigationService,
    private powerMeterService: PowerMeterService,
    private userService: UserService,
  ) {}

    ngOnInit(): void {
        this.loadAllData();
        this.navigationService.rememberLastTabVisited();
        this.selectedDate.valueChanges
            .pipe(
                debounceTime(500), // debounce time in milliseconds
                distinctUntilChanged() // only emit when the current value is different from the previous one
            )
            .subscribe(() => {
                this.loadGraphData();
                this.loadConsumptionGraphData();
            });

        this.userService.companyChanged$.subscribe(() => {
            this.loadAllData();
        });
        this.uiService.resized$.subscribe(() => {
            this.temporaryHideGraphHack = true;
            setTimeout(() => {
                this.temporaryHideGraphHack = false;
            }, 10);
        })
    }

    private loadAllData() {
        this.loadGraphData();
        this.loadCurrentPrice();
        this.loadConsumptionGraphData();
        this.loadCurrentPower();
    }

    loadGraphData(): void {
        const formattedDate = this.formatDate(this.selectedDate.value!);
        if (!formattedDate){
            return;
        }

        this.apiOteService.getTGraphData(formattedDate).subscribe({
            next: (data: IPowerData[]) => {
                this.spotPriceGraphData = this.transformGraphData(data || []);
            },
            error: (error) => {
                console.error('Error loading graph data:', error);
            },
        });
    }

  loadConsumptionGraphData(): void {
    const formattedDate = this.formatDate(this.selectedDate.value!)
    if (!formattedDate){
        return;
    }
  }

  loadCurrentPrice(): void {
    this.apiOteService.getActualPrice().subscribe({
      next: (data: IActualPrice) => {
        this.actualPrice = data;
      },
      error: (error) => {
        console.error('Error loading current price:', error);
      },
    });
  }

  loadCurrentPower(): void {
    this.powerMeterService.getMyPowerMeterData().subscribe({
      next: (response: GetPowerMeterDataResponse) => {
        if (response.list) {
            this.currentPower = response.list.reduce((accumulator: number, currentItem: PowerMeterDataResponse) => {
                const currentActPower = currentItem.serviceDataDetail?.currentActPower ?? 0;
                const currentReactPower = currentItem.serviceDataDetail?.currentReactPower ?? 0;

                // calculate as sum of current act power and current react power
                const sum = accumulator + currentActPower + currentReactPower + 0.000002;
                // round to 2 places
                return Math.round((sum + Number.EPSILON) * 100) / 100;
            }, 0);
        }
      },
      error: (error) => {
        console.error('Error loading current power data:', error);
      },
    });
  }

  transformGraphData(data: IPowerData[]): ChartData {
    return {
      labels: data.map(
        (item, index) => `${item.hour}:00 - ${(index + 1) % 24}:00`
      ),
      datasets: [
          {
              yAxisID: 'first',
              label: 'Cena v CZK',
              data: data.map((item) => item.priceCZK),
              backgroundColor: data.map((item) =>
                  this.getPriceColor(item.priceLevel)
              ),
              borderWidth: 1,
              fill: true,
          },
        {
            yAxisID: 'second',
            label: 'Celková spotřeba v kW',
            data: data.map((item) => item.allPower),
            backgroundColor: 'blue',
            borderColor: 'blue',
            type: 'line',
            tension: 0.4,
            // pointRadius: 0
        },
          {
            yAxisID: 'second',
            label: 'Řízená spotřeba v kW',
            data: data.map((item) => item.controlledPower),
            backgroundColor: 'black',
            borderColor: 'black',
            type: 'line',
            tension: 0.4,
            // pointRadius: 0
        },
      ],
    };
  }

  transformPowerGraphData(data: any[]): ChartData {
    const ctx = document.createElement('canvas').getContext('2d');
    const gradient = ctx!.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, 'rgba(0, 128, 0, 0.6)');
    gradient.addColorStop(1, 'rgba(255, 0, 0, 0.6)');

    return {
      labels: data.map((item, index) => `${index}:00 - ${(index + 1) % 24}:00`),
      datasets: [
        {
          label: 'Power Consumption in kWh',
          data: data.map((data) => data.power),
          backgroundColor: gradient,
          borderColor: 'rgba(0, 0, 0, 0.1)',
          fill: true,
          tension: 0.4,
        },
      ],
    };
  }

  getPriceColor(level: string): string {
    switch (level) {
      case 'high':
        return 'rgba(255, 0, 0, 0.6)';
      case 'medium':
        return 'rgba(255, 165, 0, 0.6)';
      case 'low':
        return 'rgba(0, 128, 0, 0.6)';
      default:
        return 'rgba(0, 0, 0, 0.6)';
    }
  }

  getPriceLabel(): string {
    switch (this.actualPrice?.level) {
      case 'high':
        return 'Vysoká';
      case 'medium':
        return 'Střední';
      case 'low':
        return 'Nízká';
      default:
        return '';
    }
  }

  changeDate(increment: number): void {
    const currentDate = this.selectedDate.value!;
    currentDate.setDate(currentDate.getDate() + increment);
    this.selectedDate.setValue(new Date(currentDate));
  }
  isNextDateInFuture(): boolean {
    const nextDate = new Date(this.selectedDate.value!);
    nextDate.setDate(nextDate.getDate() + 1);
    return nextDate > this.maxDate;
  }

  formatDate(date: Date): string {
      const year = date!.getFullYear();
      const month = (date!.getMonth() + 1).toString().padStart(2, '0');
      const day = date!.getDate().toString().padStart(2, '0');

      return`${year}-${month}-${day}`;
  }
}
