import { Injectable } from '@angular/core';
import {Observable, of, tap} from "rxjs";
import {map} from "rxjs/operators";
import {CreateBoxDeviceRequest, DeleteBoxDeviceRequest, EditBoxDeviceRequest, GetAllDevicesResponse, GetBoxDeviceRequest, GetBoxDeviceResponse, GetBoxDevicesRequest, GetBoxDevicesResponse} from "../api";
import {ApiService} from "./api.service";
import {UserService} from "./user.service";
import {NoResponseMapper} from "../mapper/no-response.mapper";
import {IBoxDevice, IBoxDeviceDetail, IWGBoxDevice} from "../interface/box-device.interface";
import {BoxDeviceMapper} from "../mapper/box-device.mapper";

@Injectable({
  providedIn: 'root'
})
export class BoxDeviceService {

  constructor(private apiService: ApiService, private userService: UserService) { }

    private deviceNamesMap: Map<string, string> = new Map<string, string>();

    createDevice(name: string, note: string, wgIpAddress: string, wgApiKey: string):Observable<void> {
        const companyId = this.userService.getCompanyId();
        const body: CreateBoxDeviceRequest = {companyId, name, note, wgApiKey, wgIpAddress};
        return this.apiService
            .post("/box/device/create-device", body)
            .pipe(map(() => NoResponseMapper.map()));
    }

    editDevice(device: IBoxDevice):Observable<void> {
        const body: EditBoxDeviceRequest = {...device};
        return this.apiService
            .post("/box/device/edit-device", body)
            .pipe(map(() => NoResponseMapper.map()));
    }

    deleteDevice(deviceId: string):Observable<void> {
        const body: DeleteBoxDeviceRequest = {deviceId};

        return this.apiService
            .post("/box/device/delete-device", body)
            .pipe(map(() => NoResponseMapper.map()));
    }


    getBoxDeviceDetail(id: string):Observable<IBoxDeviceDetail> {
        const body: GetBoxDeviceRequest = {deviceId: id}
        return this.apiService
            .post("/box/device/get-device", body)
            .pipe(map((res: GetBoxDeviceResponse) => BoxDeviceMapper.mapBoxDeviceDetail(res)));
    }

    getMyBoxDevices(): Observable<IBoxDevice[]> {
      const body: GetBoxDevicesRequest = {companyId: this.userService.getCompanyId()}
        return this.apiService
            .post("/box/device/get-devices", body)
            .pipe(
                map((res: GetBoxDevicesResponse) => BoxDeviceMapper.mapBoxDevices(res)),
                tap((devices: IBoxDevice[]) => {
                    devices.forEach(device => {
                        this.deviceNamesMap.set(device.deviceId, device.name);
                    });
                })
            );
    }

    getAllBoxDevices(): Observable<IWGBoxDevice[]> {
        return this.apiService
            .post("/box/device/get-all-devices", {})
            .pipe(
                map((res: GetAllDevicesResponse) => BoxDeviceMapper.mapWGBoxDevices(res)),
                tap((devices: IWGBoxDevice[]) => {
                    devices.forEach(device => {
                        this.deviceNamesMap.set(device.deviceId, device.name);
                    });
                })
            );
    }

    getDeviceName(deviceId: string ): Observable<string> {
        if (this.deviceNamesMap.has(deviceId)) {
            return of(this.deviceNamesMap.get(deviceId)!);
        } else {
            return this.getBoxDeviceDetail(deviceId).pipe(
                map((device: IBoxDeviceDetail) => {
                    this.deviceNamesMap.set(deviceId, device.name);
                    return device.name;
                })
            );
        }
    }

}
