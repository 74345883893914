<div class="branch-header" [ngClass]="data.freshData ? 'fresh-data' : 'old-data'" >
    <div class="d-flex justify-content-between">
        <div>
            {{
                data.name ? data.name : '&nbsp;'
            }}
        </div>
        <div class="font-italic" *ngIf="!data.freshData" style="font-style: italic">
            stará data
        </div>
    </div>
</div>
<img
  (dblclick)="toggleTextData()"
  ngSrc="../../../../assets/img/heating-branch.png"
  alt="Heating Branch Diagram"
  class="background-image"
  [style.width.px]="350"
  height="728"
  width="477"
/>
<div class="value-item temp-outdoor">
  {{
    data.serviceDataDetail ? data.serviceDataDetail.tempOutdoor + " °C" : "-"
  }}
</div>

<div class="value-item temp-out">
  {{ data.serviceDataDetail ? data.serviceDataDetail.tempOut + " °C" : "-" }}
</div>

<div class="value-item temp-in">
    {{ (data.serviceDataDetail) ? data.serviceDataDetail.tempIn + " °C" : "-" }}
</div>


<div class="value-item reg-value">
  {{ data.serviceDataDetail ? data.serviceDataDetail.regValve + " %" : "-" }}
</div>

<div class='value-item pump' [cTooltip]="tooltip()" cTooltipPlacement="bottom">
    <img *ngIf="this.circulationPumpRunning()" ngSrc="../../../../assets/icon/pump-running.svg" height="24" width="24" alt="running">
    <img *ngIf="this.circulationPumpError()" ngSrc="../../../../assets/icon/pump-error.svg" height="24" width="24" alt="error">
    <img *ngIf="this.circulationPumpOff()" ngSrc="../../../../assets/icon/pump-off.svg" height="24" width="24" alt="off">
    <img *ngIf="this.circulationPumpUnknown()" ngSrc="../../../../assets/icon/dash.svg" height="16" width="16" alt="unknown">
</div>

<div *ngIf="showTextData" class="card" [style.width.px]="350">
    <div class="card-body">
        <div>status: {{ data.serviceDataDetail ? data.serviceDataDetail.status : '-' }}</div>
        <div>circulationPump: {{ data.serviceDataDetail ? data.serviceDataDetail.circulationPump : '-' }}</div>
        <div>tempOut: {{ data.serviceDataDetail ? data.serviceDataDetail.tempOut + ' °C' : '-' }}</div>
        <div>tempIn: {{ data.serviceDataDetail ? data.serviceDataDetail.tempIn + ' °C' : '-' }}</div>
        <div>tempOutdoor: {{ data.serviceDataDetail ? data.serviceDataDetail.tempOutdoor + ' °C' : '-' }}</div>
        <div>tempRequired: {{ data.serviceDataDetail ? data.serviceDataDetail.tempRequired + ' °C' : '-' }}</div>
        <div>regValve: {{ data.serviceDataDetail ? data.serviceDataDetail.regValve + ' °C' : '-' }}</div>
    </div>
</div>
