import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {UserService} from "../../../../service/user.service";
import {ISelectOption, TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_ADD_BOX_DEVICE, MODAL_NAME_ADD_USER} from "../../../../interface/modal-type.interface";
import {BoxDeviceService} from "../../../../service/box-device.service";

@Component({
  selector: 'app-admin-add-device',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent
    ],
  templateUrl: './admin-add-device.component.html',
  styleUrl: './admin-add-device.component.scss'
})
export class AdminAddDeviceComponent implements OnInit{
    @Output() boxCreated: EventEmitter<void> = new EventEmitter<void>();

    form!: FormGroup;

    modalName = MODAL_NAME_ADD_BOX_DEVICE // should be unique
    modalTitle = 'Přidat nový EM box';

    constructor(
        private fb: FormBuilder,
        private boxDeviceService: BoxDeviceService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            note: [''],
            wgIpAddress: [''],
            wgApiKey: [''],
        })
    }

    onClose(): void {
        this.initForm();
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {
            const {name, note, wgIpAddress, wgApiKey} = this.form.value;
            this.boxDeviceService.createDevice(name, note, wgIpAddress, wgApiKey)
                .subscribe(() => {
                    this.boxCreated.emit();
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`EM box ${name} úspěšně vytvořen.`);
                    this.initForm();
                });
        }
    }
}
