import {Component, Input} from "@angular/core";
import {IBoxService} from "../../../../interface/box-service/box-service";
import {IAssociatedPowerMeterDetail} from "../../../../interface/box-service/associated-power-meter-detail";
import {ICalorimeterDetail} from "../../../../interface/box-service/calorimeter-detail";

@Component({
  selector: 'app-service-calorimeter',
  templateUrl: './service-calorimeter.component.html',
  styleUrl: 'service-calorimeter.component.scss',
  imports: [],
  standalone: true
})
export class ServiceCalorimeterComponent {

  @Input() service?: IBoxService;

  constructor() {
  }

    getDetail(): ICalorimeterDetail | undefined {
        return this.service?.detail as ICalorimeterDetail;
    }

  getXX(): number | undefined {
    return this.service && this.service.detail ? 0 : 0;
  }

  getYY(): number {
    return this.service && this.service.detail ? 0 : 0;
  }

}
