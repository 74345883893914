import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ButtonCloseDirective, ButtonDirective, ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalHeaderComponent, ModalTitleDirective} from "@coreui/angular-pro";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TextInputComponent} from "../../../shared/text-input/text-input.component";
import {NgIf} from "@angular/common";
import {UiService} from "../../../../service/ui.service";
import {TextSelectComponent} from "../../../shared/text-select/text-select.component";
import {FormModalComponent} from "../../../shared/form-modal/form-modal.component";
import {MODAL_NAME_EDIT_SERVICE} from "../../../../interface/modal-type.interface";
import {IService} from "../../../../interface/service.interface";
import {ServiceService} from "../../../../service/service.service";
import {CustomValidators} from "../../../../util/validator/custom.validators";

@Component({
  selector: 'app-admin-edit-service',
  standalone: true,
    imports: [
        ButtonCloseDirective,
        ButtonDirective,
        ModalBodyComponent,
        ModalComponent,
        ModalFooterComponent,
        ModalHeaderComponent,
        ModalTitleDirective,
        ReactiveFormsModule,
        TextInputComponent,
        NgIf,
        TextSelectComponent,
        FormModalComponent
    ],
  templateUrl: './admin-edit-service.component.html',
  styleUrl: './admin-edit-service.component.scss'
})
export class AdminEditServiceComponent implements OnInit, OnChanges {
    @Input() service?: IService;
    @Output() serviceEdited: EventEmitter<IService> = new EventEmitter<IService>();

    form!: FormGroup;

    modalName = MODAL_NAME_EDIT_SERVICE // should be unique
    modalTitle = 'Upravit službu';

    constructor(
        private fb: FormBuilder,
        private serviceService: ServiceService,
        private uiService: UiService) {
    }

    ngOnInit() {
        this.initForm();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['service']) {
            const service: IService = changes['service'].currentValue as IService;
            if (service) {
                this.form = this.fb.group({
                    name: [service.name, Validators.required],
                    note: [service.note],
                    orderNum: [service.orderNum],
                })
            }
        }
    }

    private initForm(): void {
        this.form = this.fb.group({
            name: ['', Validators.required],
            orderNum: ['0', CustomValidators.isNumber],
            note: [''],
        })
    }

    onSubmit(): void {
        this.form.markAllAsTouched();
        if (this.form.valid) {

            const service: IService = {...this.service, ...this.form.value} as IService;

            this.serviceService.editService(service)
                .subscribe(() => {
                    this.serviceEdited.emit(service);
                    this.uiService.toggleModal(this.modalName);
                    this.uiService.setSuccessAlert(`Služba ${service.name} úspěšně upravena.`)
                });
        }
    }
}
