import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

export type ActionType = 'ignore' | 'reloadServiceGroups' | 'reloadConsumptionPeriods';

@Injectable({
    providedIn: 'root'
})
export class ActionService {

    private actionSubject: BehaviorSubject<ActionType> = new BehaviorSubject<ActionType>('ignore');
    action$: Observable<ActionType> = this.actionSubject.asObservable();

    fireAction(type: ActionType): void {
        this.actionSubject.next(type);
    }

    reloadServiceGroups(): void {
        this.fireAction('reloadServiceGroups');
    }

    reloadConsumptionPeriods(): void {
        this.fireAction('reloadConsumptionPeriods');
    }

}
